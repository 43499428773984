import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { RootState } from "../../../store";
import {
  BRANDS,
  CAPTCHA_SITE_KEY,
  COUNTRY,
  GENDER_LIST,
  GENDER_LIST_STA,
  GENDER_LIST_US,
  GENRE_LIST,
  MONTH_LIST,
} from "../../../constants/app";
import TextInput from "../../UI/TextInput";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../utils/validatorSchema";
import {
  showLogin,
  signUpAsGuest,
  updateMembbershipPurchase,
} from "../../../store/auth/action";
import SelectDropdown from "../../UI/DropDown/SelectDropDown";
import { dateArray, monthArray, numberOnly, yearArray } from "../../../utils/helper";
import { toast } from "react-toastify";
import {
  setModal,
  toggleTicketModal,
} from "../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import HelmetTitle from "../../Helmet";

interface ISignUpProps {
  fromPage?: string;
  isFromBlog?: boolean;
}

const SignUp: FC<ISignUpProps> = ({ fromPage, isFromBlog }) => {
  const {
    countryId,
    brandId,
    cinemas,
    currentCinema,
    modalType,
    prevModal,
    nextModal,
    membershipPurchase,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    brandId: state.applicationReducer.brandId,
    cinemas: state.applicationReducer.cinemas,
    currentCinema: state.applicationReducer.currentCinema,
    modalType: state.ticketBookingReducer.modal.type,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    membershipPurchase: state.authReducer.membershipPurchase,
  }));

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [genderList, setGenderList] = useState<{ label: string; value: any }[]>(
    []
  );

  useEffect(() => {
    if ([COUNTRY.AUS, COUNTRY.NZ].includes(countryId)) {
      setGenderList(GENDER_LIST);
    } else if ([COUNTRY.STA, COUNTRY.ANG].includes(countryId)) {
      setGenderList(GENDER_LIST_STA);
    } else if ([COUNTRY.US, COUNTRY.CON, COUNTRY.AFC].includes(countryId)) {
      setGenderList(GENDER_LIST_US);
    }
  }, [countryId]);

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("signup"),
      enableReinitialize: true,
      initialValues: {
        brandId: brandId,
        countryId: countryId,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        gender: "",
        genres: [],
        password: "",
        confirmPassword: "",
        cardNumber: "",
        day: "",
        month: "",
        year: "",
        cinemaId: currentCinema?.cinemaId || "",
        cardnumber: "",
        weeklyNews: 1,
        captcha: false,
        zipCode: "",
        city: "",
        membership: isFromBlog?1:0,
      },
      onSubmit: (values) => {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          gender,
          genres,
          password,
          confirmPassword,
          cardNumber,
          day,
          month,
          year,
          cinemaId,
          weeklyNews,
          zipCode,
          city,
          membership,
        } = values;

        const payload = {
          cardNumber,
          countryId: countryId,
          dateOfBirth: `${year}-${month}-${day}`,
          email,
          firstName,
          gender,
          lastName,
          password,
          phoneNumber,
          preferredGenres: genres,
          theatreId: cinemaId,
          weeklyNews,
          zipCode,
          city,
          membership,
          isMemberPackage: fromPage == "membership" ? true : false,
          itemId:
            fromPage == "membership" &&
              membershipPurchase &&
              membershipPurchase.loyaltyPackage &&
              membershipPurchase.loyaltyPackage.id
              ? membershipPurchase.loyaltyPackage.id
              : undefined,
          clubId:
            fromPage == "membership" &&
              membershipPurchase &&
              membershipPurchase.clubId
              ? membershipPurchase.clubId
              : undefined,
        };
        dispatch(
          signUpAsGuest(
            payload,
            (response: any) => {
              if (response.statusCode === 200) {
                dispatch(showLogin(false));
                toast.success(
                  `You have successfully registered. Validation link has sent to your registered email`
                );
                if (fromPage === "membership") {
                  const ticketType: TICKET_FLOW_TYPES = modalType;
                  const next: MODAL_TYPE = nextModal;
                  const modal = TICKET_FLOW[ticketType][next];

                  dispatch(
                    setModal({
                      ...modal,
                      type: modalType,
                    })
                  );
                } else {
                  dispatch(toggleTicketModal(false));
                }
              }
            },
            (error: any) => {
              setErrorMessage(error);
            }
          )
        );
        localStorage.setItem("showSignupMsg", "false");
      },
    });

  const onCancel = () => {
    dispatch(showLogin(false));
    dispatch(toggleTicketModal(false));
  };

  const cinemaList = useMemo(() => {
    const allCinemaLocation: { label: string; value: string }[] = [];
    Object.keys(cinemas).forEach(function (key) {
      const arrList = cinemas[key];
      arrList.forEach((obj: any) => {
        allCinemaLocation.push({
          value: obj.slug,
          label:
            countryId === COUNTRY.AUS || countryId === COUNTRY.NZ
              ? obj.cinemaName + ", " + key
              : obj.cinemaName,
        });
      });
    });
    return allCinemaLocation;
  }, [cinemas]);

  const onChangeWeeklyLetter = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("weeklyNews", checked ? 1 : 0);
  };

  const onChangeJoinMembership = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("membership", checked ? 1 : 0);

  };

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  const onChangeGenre = (event: any) => {
    setFieldValue("genres", [event]);
  };

  const onInputChange = (e: any) => {
    const value = numberOnly(e);
    if (value !== undefined) {
      setFieldValue("zipCode", value);
    }
  };

  return (
    <div className="login-form form_page">
      <HelmetTitle title="Sign up" description="Sign up" />
      {countryId === COUNTRY.STA ? (
        <div className="row">
          <div className="col-12 col-md-12">
            <p>
              <strong>Please Note:</strong> Haven't purchased a membership yet?
              please click
              <a href="{{purchaseLink}}" target="_blank">
                here.
              </a>
              <br />
              Once you have purchased your membership and picked up your card
              from the cinema, please activate your online account by completing
              the following details.
            </p>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-6 col-md-6 ">
          <label className="form-label">First Name*</label>
          <div className="form-group pass_show">
            <TextInput
              value={values.firstName}
              field={"firstName"}
              handleChange={handleChange}
              touched={touched.firstName!}
              error={errors.firstName}
            />
          </div>
        </div>
        <div className="col-6 col-md-6">
          <label className="form-label">Last Name</label>
          <TextInput
            value={values.lastName}
            field={"lastName"}
            handleChange={handleChange}
            touched={touched.lastName!}
            error={errors.lastName}
          />
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Email*</label>
          <div className="form-group pass_show">
            <TextInput
              value={values.email}
              field={"email"}
              handleChange={handleChange}
              touched={touched.email!}
              error={errors.email}
            />
            <span className="hint">Ex:Johnxxxx99@gmail.com</span>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">
            {brandId === BRANDS.US ? "Contact Number" : "Phone Number"}*
          </label>
          <div className="form-group pass_show position-relative">
            <TextInput
              value={values.phoneNumber}
              field={"phoneNumber"}
              handleChange={handleChange}
              touched={touched.phoneNumber!}
              error={errors.phoneNumber}
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <label className="form-label">Gender*</label>
          <div className="form-group">
            <SelectDropdown
              field={"gender"}
              options={genderList}
              setFieldValue={setFieldValue}
              touched={touched.gender!}
              error={errors.gender}
              value={values.gender}
            />
          </div>
        </div>
        {brandId === BRANDS.US ? (
          <div className="col-12 col-md-6">
            <label className="form-label">Preferred Genre*</label>
            <div className="form-group">
              <SelectDropdown
                field={"genres"}
                options={GENRE_LIST}
                onChange={onChangeGenre}
                touched={touched.genres!}
                error={errors.genres}
                value={values.genres}
              />
            </div>
          </div>
        ) : (
          <div className="col-12 col-md-6">
            <label className="form-label">Preferred Genres*</label>
            <div className="form-group">
              <SelectDropdown
                field={"genres"}
                options={GENRE_LIST}
                setFieldValue={setFieldValue}
                touched={touched.genres!}
                error={errors.genres}
                value={values.genres}
                isMulti={true}
              />
            </div>
          </div>
        )}
        <div className="col-12 col-md-12">
          <label className="form-label">Date of Birth*</label>
          <div className="form-group">
            <div className={`row ${brandId === BRANDS.US ? 'date-of-birth-order' : ''}`}>
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"day"}
                  options={dateArray()}
                  setFieldValue={setFieldValue}
                  touched={touched.day!}
                  error={errors.day}
                  value={values.day}
                />
              </div>
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"month"}
                  options={MONTH_LIST}
                  setFieldValue={setFieldValue}
                  touched={touched.month!}
                  error={errors.month}
                  value={values.month}
                />
              </div>
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"year"}
                  options={yearArray()}
                  setFieldValue={setFieldValue}
                  touched={touched.year!}
                  error={errors.year}
                  value={values.year}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div>
            <label className="form-label">Password*</label>
            <div className="form-group pass_show">
              <TextInput
                type="password"
                value={values.password}
                field={"password"}
                handleChange={handleChange}
                touched={touched.password!}
                error={errors.password}
              />
              <span className="hint">Ex:Johnpeter@123</span>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div>
            <label className="form-label">Confirm Password*</label>
            <div className="form-group pass_show">
              <TextInput
                type="password"
                value={values.confirmPassword}
                field={"confirmPassword"}
                handleChange={handleChange}
                touched={touched.confirmPassword!}
                error={errors.confirmPassword}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12">
          <label className="form-label">
            {brandId === BRANDS.US ? "Preferred Location" : "Cinema"}*
          </label>
          <div className=" form-group">
            <SelectDropdown
              field={"cinemaId"}
              options={cinemaList}
              setFieldValue={setFieldValue}
              touched={touched.cinemaId!}
              error={errors.cinemaId}
              value={values.cinemaId}
            />
          </div>
        </div>
        {brandId === BRANDS.US ? (
          <>
            <div className="col-12 col-md-6">
              <label className="form-label">City*</label>
              <div className="form-group position-relative">
                <TextInput
                  value={values.city}
                  field={"city"}
                  handleChange={handleChange}
                  touched={touched.city!}
                  error={errors.city}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <label className="form-label">Zip Code*</label>
              <div className="form-group">
                <TextInput
                  value={values.zipCode}
                  field={"zipCode"}
                  touched={touched.zipCode!}
                  error={errors.zipCode}
                  maxLength={5}
                  onChange={onInputChange}
                />
              </div>
            </div>
          </>
        ) : null}
        {countryId === COUNTRY.STA ? (
          <div className="col-12 col-md-6">
            <div>
              <label className="form-label">
                Loyalty Card Number<span>*</span>
              </label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.cardnumber}
                  field={"cardnumber"}
                  handleChange={handleChange}
                  touched={touched.cardnumber!}
                  error={errors.cardnumber}
                />
              </div>
            </div>
          </div>
        ) : null}
        {countryId === COUNTRY.AFC ?
          <div className="col-md-12 mb-3">
            <div className="custom_checkbox p-default form-group">
              <input
                onClick={onChangeJoinMembership}
                type="checkbox"
                checked={values.membership === 1 ? true : false}
              />
              <div className="state p-primary">
                <label className="form-label mx-2">
                  Join Angelika Membership
                </label>
              </div>
            </div>
          </div> : null}
        <div className="col-md-12 mb-3">
          <div className="custom_checkbox p-default form-group">
            <input
              onClick={onChangeWeeklyLetter}
              type="checkbox"
              checked={values.weeklyNews === 1 ? true : false}
              value="1"
            />
            <div className="state p-primary">
              <label className="form-label mx-2">
                {countryId === COUNTRY.ANG
                  ? "Keep me updated with Angelika offers, special events and weekly news"
                  : countryId === COUNTRY.STA
                    ? `Keep me updated with Loyalty weekly news`
                    : brandId === BRANDS.US
                      ? `Keep me updated with movies, events, and offers`
                      : `Keep me updated with Reel Club weekly news`}
              </label>
            </div>
          </div>
        </div>
        <div className="col-12  col-md-12">
          <label className="form-label">Verification Code* </label>
          <div className="form-group">
            <ReCAPTCHA
              sitekey={`${CAPTCHA_SITE_KEY}`}
              onChange={onChangeCaptcha}
            />
            {errors.captcha && touched.captcha ? (
              <div className="error_message">
                <span>Verification Code is required</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="err-message">{errorMessage}</div>
        <div className="movie_button_wrap">
          <button
            type="button"
            className="btn black_btn m-r-20"
            onClick={() => handleSubmit()}
          >
            {countryId === COUNTRY.STA ? "Activate Membership" : "SIGN UP"}
          </button>
          <button type="button" className="btn gray_btn" onClick={onCancel}>
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

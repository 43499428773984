import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTicketModal } from "../../../../store/ticketBooking/action";
import "./kitchenClosed.scss";
import { BRANDS } from "../../../../constants/app";
import { RootState } from "../../../../store";
import moment from "moment";
import { useNavigate } from "react-router";

interface IKitchenClosedProps {
  onCloseModal: () => void;
}

const KitchenClose: FC<IKitchenClosedProps> = ({ onCloseModal }) => {
  const { brandId, modalData, currentCinema } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    modalData: state.ticketBookingReducer.modal.data,
    currentCinema: state.applicationReducer.currentCinema
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDate = new Date();
  const currentDay = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
  });

  useEffect(() => {
    document
      .getElementById("ticket-booking-modal")
      ?.classList.add("bg-modal-black");
    return () => {
      document
        .getElementById("ticket-booking-modal")
        ?.classList.remove("bg-modal-black");
    };
  }, []);

  const onClose = ()=>{
    dispatch(toggleTicketModal(false));
    navigate(`/${currentCinema.alias}`);
  }

  return (
    <div className="row food_common">
      {brandId === BRANDS.US && modalData ? (
        <div className="open-hour-section col-12 mb-5">
          <h4>
            Currently our kitchen is closed. Please refer to our operating hours
            below:
          </h4>
          <div className="opening_hours_wrapper">
            <div className="opening_hours">
              {modalData.map((ele: any) => (
                <div className="d-flex hours ">
                  <p
                    className={
                      currentDay.toLowerCase() === ele.Day.toLowerCase()
                        ? "border-left highlight day"
                        : "day"
                    }
                  >
                    {ele.Day}
                  </p>
                  {ele.OpenStatus === "1" ? (
                    <p
                      className={
                        currentDay.toLowerCase() === ele.Day.toLowerCase()
                          ? "highlight time"
                          : "time"
                      }
                    >
                      {moment(ele.OpenHour, "hh:mm").format("h:mm a")} -{" "}
                      {moment(ele.CloseHour, "hh:mm").format("h:mm a")}
                    </p>
                  ) : (
                    <p
                      className={
                        currentDay.toLowerCase() === ele.Day.toLowerCase()
                          ? "highlight time"
                          : "time"
                      }
                    >
                      CLOSED
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="col-12 mb-5">
          <h5>Our kitchen is closed for the day.</h5>
          <p>
            Please visit the counter upon arrival if you have any queries.
            <br />
            We apologise for any inconvenience.
          </p>
        </div>
      )}

      <div className="col-12">
        <button type="button" className="btn btn_ok" onClick={brandId === BRANDS.US?onClose:onCloseModal}>
          OK
        </button>
      </div>
    </div>
  );
};

export default KitchenClose;

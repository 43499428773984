import React, { FC, useEffect, useMemo, useState } from "react";
import MovieDescription from "../../../../components/TicketBooking/MovieDescription";
import { RootState } from "../../../../store";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import "./socialShare.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { encryptData } from "../../../../utils/helper";
import { pageContentApi } from "../../../../services/sideMenus";
import { ISocialShare } from "../../../../models/payment";

interface ISocialSharingProps {
  onCloseModal: (error?: string) => void;
}

const SocialSharing: FC<ISocialSharingProps> = ({ onCloseModal }) => {
  const { countryId, films, currentCinema, ticketDetails, userDetails } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      ticketDetails: state.ticketBookingReducer.ticketDetails,
      films: state.ticketBookingReducer.films,
      currentCinema: state.applicationReducer.currentCinema,
    }));

  const [facebook, setFacebook] = useState<ISocialShare | null>(null);
  const [twitter, setTwitter] = useState<ISocialShare | null>(null);
  const [email, setEmail] = useState<ISocialShare | null>(null);

  const getSocialMediaContentApi = async () => {
    const {
      data: { data, status },
    } = await pageContentApi({ countryId, flag: "socialMedia" });
    if (status && data.length > 0) {
      const fb = data.find((v: ISocialShare) => v.Plat_Form === "Facebook");
      const twit = data.find((v: ISocialShare) => v.Plat_Form === "Twitter");
      const mail = data.find((v: ISocialShare) => v.Plat_Form === "Email");
      setFacebook(fb ? fb : null);
      setTwitter(twit ? twit : null);
      setEmail(mail ? mail : null);
    }
  };

  useEffect(() => {
    if (countryId) {
      getSocialMediaContentApi();
    }
  }, [countryId]);

  const sessionTimeContent = useMemo(() => {
    return `\nMovie : ${films?.movieName}\n Time: ${
      films ? moment.parseZone(films?.showDateTime).format("hh:mm A") : ""
    } \n Date: ${
      films
        ? moment.parseZone(films?.showDateTime).format("dddd DD MMMM YYYY")
        : ""
    } \n Cinema: ${films?.cinemaName}\n\n`;
  }, [films]);

  const seatSessionContent = useMemo(() => {
    return `\nMovie : ${films?.movieName}\n Time: ${
      films ? moment.parseZone(films?.showDateTime).format("hh:mm A") : ""
    }\n Date: ${
      films
        ? moment.parseZone(films?.showDateTime).format("dddd DD MMMM YYYY")
        : ""
    }\n Cinema: ${films?.cinemaName}\n Seat(s):  ${
      ticketDetails &&
      ticketDetails.selectedSeats &&
      ticketDetails.selectedSeats.map((v: any) => v.SeatId).join(", ")
    }
    \n\n`;
  }, [films, ticketDetails]);

  const shareLink = (content: string) => {
    navigator.clipboard.writeText(content + "\n" + url);
    toast.success("Copied!");
  };

  const url = useMemo(() => {
    if (films && ticketDetails) {
      //   return `${window.location.origin}/cinemas/${currentCinema.slug}/sessions/${films.sessionId}/${films.movieId}/${ticketDetails.userSessionId}`;
      return `${window.location.origin}/${
        currentCinema.alias
      }/sessions/${films.sessionId}/${films.movieId}/${encodeURIComponent(
        encryptData(ticketDetails.userSessionId!, "socialsharing")
      )}`;
    } else {
      return "https://dev-spa.nonprod.readingcinemas.com/";
    }
  }, [films, ticketDetails]);

  return (
    <div className="social_share_wrapper">
      <div className="row seat-selection-wrapper">
        <MovieDescription enableDescription={false} />
        <div className="col-12 col-md-12 col-lg-8 p-l-10">
          <div className="whole-seats">
            <div className="welcome">{films?.movieName}</div>
            <div className="rating_wrap mb-3">
              <span className="certification_icon">
                <div className="icon">
                  <img
                    src={
                      films?.ratingIcon
                        ? `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${films?.ratingIcon}${IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT}`
                        : URLS.ERROR_IMAGE
                    }
                    alt={films?.ratingName}
                  />
                </div>
              </span>
            </div>
            <div className="section">
              <div className="header_text"> Time </div>
              <div className="sub_text">
                {" "}
                {films
                  ? moment.parseZone(films?.showDateTime).format("hh:mm A")
                  : ""}
              </div>
            </div>

            <div className="section">
              <div className="header_text"> Date </div>
              <div className="sub_text">
                {" "}
                {films
                  ? moment
                      .parseZone(films?.showDateTime)
                      .format("dddd DD MMMM YYYY")
                  : ""}
              </div>
            </div>

            <div className="section">
              <div className="header_text"> Cinema </div>
              <div className="sub_text">{films?.cinemaName}</div>
            </div>

            {ticketDetails &&
            ticketDetails.selectedSeats &&
            ticketDetails.selectedSeats.length > 0 ? (
              <div className="section">
                <div className="header_text"> Seats </div>
                <div className="sub_text">
                  {ticketDetails.selectedSeats
                    .map((v: any) => v.SeatId)
                    .join(", ")}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="footer_wrapper">
        <div className="session_text"> Share my session time only </div>
        <div className="footer_section">
          <div className="row_section">
            <FacebookShareButton
              url={url}
              quote={
                facebook?.Session_Sharing_Description!.replace(/<[^>]*>/g, "") +
                sessionTimeContent
              }
              className="social_icon"
            >
              <div className="row_section">
                <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"facebook_icon.svg"}/>
                <div className="sub_text"> Share on Facebook </div>
              </div>
            </FacebookShareButton>
          </div>
          <div className="row_section">
            <TwitterShareButton
              url={url}
              title={
                twitter?.Session_Sharing_Description!.replace(/<[^>]*>/g, "") +
                sessionTimeContent
              }
              className="social_icon"
            >
              <div className="row_section">
                <img style={{marginRight: 6,width: 18}} src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"twitter-logo-new.svg"} />
                <div className="sub_text"> Share on Twitter </div>
              </div>
            </TwitterShareButton>
          </div>
          <div className="row_section">
            <EmailShareButton
              url={url}
              subject={email?.Session_Sharing_title}
              body={
                email?.Session_Seat_Sharing_Description!.replace(
                  /<[^>]*>/g,
                  ""
                ) + sessionTimeContent
              }
              className="social_icon"
            >
              <div className="row_section">
                <img className="mail-image" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"envelope-black.svg"} />
                <div className="sub_text"> Share via Email</div>
              </div>
            </EmailShareButton>
          </div>
          <div
            className="row_section"
            onClick={() => shareLink(sessionTimeContent)}
          >
            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"copy_icon.svg"} />
            <div className="sub_text"> Copy Link </div>
          </div>
        </div>

        <div className="session_text"> Share my session & seats </div>
        <div className="footer_section">
          <div className="row_section">
            <FacebookShareButton
              url={url}
              quote={
                facebook?.Session_Seat_Sharing_Description!.replace(
                  /<[^>]*>/g,
                  ""
                ) + seatSessionContent
              }
              className="social_icon"
            >
              <div className="row_section">
                <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"facebook_icon.svg"} />
                <div className="sub_text"> Share on Facebook </div>
              </div>
            </FacebookShareButton>
          </div>
          <div className="row_section">
            <TwitterShareButton
              url={url}
              title={
                twitter?.Session_Seat_Sharing_Description!.replace(
                  /<[^>]*>/g,
                  ""
                ) + seatSessionContent
              }
              className="social_icon"
            >
              <div className="row_section">
                <img style={{marginRight: 6,width: 18}} src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"twitter-logo-new.svg"} />
                <div className="sub_text"> Share on Twitter </div>
              </div>
            </TwitterShareButton>
          </div>
          <div className="row_section">
            <EmailShareButton
              url={url}
              subject={email?.Session_Seat_Sharing_title}
              body={
                email?.Session_Seat_Sharing_Description!.replace(/<[^>]*>/g, "") + seatSessionContent
              }
              className="social_icon"
            >
              <div className="row_section">
                <img className="mail-image" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"envelope-black.svg"} />
                <div className="sub_text"> Share via Email</div>
              </div>
            </EmailShareButton>
          </div>
          <div
            className="row_section"
            onClick={() => shareLink(seatSessionContent)}
          >
            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"copy_icon.svg"} />
            <div className="sub_text"> Copy Link </div>
          </div>
        </div>
      </div>
      <div className="movie_footer_btn_el col-md-12 ml-auto">
        <div className="movie_button_wrap">
          <button
            type="button"
            className="btn gray_btn"
            onClick={() => onCloseModal()}
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};

export default SocialSharing;

import { takeLatest, call, put } from 'redux-saga/effects';
import { ACTIONS } from './type';
import { IAction } from '../../models/common';
import { getAdvanceTicketApi, getComingSoonApi, getComingTicketApi, getNowShowingApi, getSignatureProgramApi, getTitanMovieApi } from '../../services/films';
import { getNowShowingSuccess, getComingSoonSuccess, getNowShowingError, getComingSoonError, getSignatureProgrammError, getSignatureProgramSuccess, getTitanMovieSuccess, getTitanMovieError, getAdvanceTicketSuccess, getAdvanceTicketError } from './action';
import { formatAdvanceTicket, formatComingSoon, formatComingSoonAdv, formatScreenType, formatSortSessionType } from '../../utils/formatter';
import { BRANDS } from '../../constants/app';



function* getNowShowing(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getNowShowingApi, payload);
    if (status) {
        yield put(getNowShowingSuccess(payload.brandId === BRANDS.US ? formatSortSessionType(response.data.data) : formatScreenType(response.data.data)))
    } else {
        yield put(getNowShowingError(error))
    }
}

function* getComingSoon(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getComingSoonApi, payload);

    if (status && response.length > 0) {
        const formatedData = formatComingSoonAdv(response[0].data.data, response[1]?.data?.data ? response[1].data.data : []);
        yield put(getComingSoonSuccess(formatedData))
    } else {
        yield put(getComingSoonError(error))
    }
}

function* getComingSoonTicket(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getComingTicketApi, payload);

    if (status && response) {
        const formatedData = formatComingSoon(response.data.data);
        yield put(getComingSoonSuccess(formatedData))
    } else {
        yield put(getComingSoonError(error))
    }
}

function* getAdvanceTicket(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getAdvanceTicketApi, payload);

    if (status && response) {
        const formatedData = formatAdvanceTicket(response?.data?.data ? response.data.data : []);
        yield put(getAdvanceTicketSuccess(formatedData))
    } else {
        yield put(getAdvanceTicketError(error))
    }
}

function* getSignatureProgram(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getSignatureProgramApi, payload);
    if (status) {
        yield put(getSignatureProgramSuccess(formatSortSessionType(response.data.data)))
    } else {
        yield put(getSignatureProgrammError(error))
    }
}

function* getTitanMovie(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getTitanMovieApi, payload);
    if (status) {
        yield put(getTitanMovieSuccess(formatSortSessionType(response.data.data)))
    } else {
        yield put(getTitanMovieError(error))
    }
}

export function* filmsWatcher() {
    yield takeLatest(ACTIONS.GET_NOW_SHOWING_WATCHER, getNowShowing);
    yield takeLatest(ACTIONS.GET_COMING_SOON_WATCHER, getComingSoon);
    yield takeLatest(ACTIONS.GET_COMING_SOON_TICKET_WATCHER, getComingSoonTicket);
    yield takeLatest(ACTIONS.GET_ADVANCE_TICKET_WATCHER, getAdvanceTicket);
    yield takeLatest(ACTIONS.GET_SIGNATURE_PROGRAM_WATCHER, getSignatureProgram);
    yield takeLatest(ACTIONS.GET_TITAN_MOVIES_WATCHER, getTitanMovie);
}
import React, { FC, useState } from 'react';
import './joinMembership.scss';
import Modal from "../../../UI/Modal";
import { IMAGE_URL_CONFIG, URLS } from '../../../../constants/url';
import TextInput from '../../../UI/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useFormik } from 'formik';
import { createValidationSchema } from '../../../../utils/validatorSchema';
import moment from 'moment';
import SelectDropdown from '../../../UI/DropDown/SelectDropDown';
import { dateArray, yearArray } from '../../../../utils/helper';
import { MONTH_LIST } from '../../../../constants/app';
import { createMembership } from '../../../../services/auth';
import { toast } from 'react-toastify';
import { memberValidationWatcher, setMembershipModal, setUserDetails } from '../../../../store/auth/action';

interface IJoinMembership { }

const JoinMembership: FC<IJoinMembership> = () => {
    const {
        userDetails,
        countryId,
        brandId,
        membershipModal,
        currentCinema
    } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId,
        currentCinema: state.applicationReducer.currentCinema,
        userDetails: state.authReducer.userDetails,
        brandId: state.applicationReducer.brandId,
        membershipModal: state.authReducer.membershipModal
    }));
    const dispatch = useDispatch();

    const { handleChange, handleSubmit, values, errors, touched, setFieldValue, resetForm } =
        useFormik({
            validationSchema: createValidationSchema("joinMembership"),
            enableReinitialize: true,
            initialValues: {
                brandId: brandId,
                firstName: userDetails?.firstName || "",
                lastName: userDetails?.lastName || "",
                email: userDetails?.email || "",
                phoneNumber: userDetails?.phoneNumber
                    ? userDetails?.phoneNumber.replace(/[^+\d]+/g, "").replace("+", "")
                    : "",
                suburb: userDetails?.Suburb || "",
                city: userDetails?.Suburb || "",
                gender: userDetails?.gender || "",
                genres:
                    [parseInt((`${userDetails?.preferredGenres}`).replace(/\[|\]/g, ''))],
                cinemaId: (currentCinema && currentCinema.slug?currentCinema.slug:userDetails?.theatre_id) || "",
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
                postCode: userDetails?.zipCode || "",
                day: userDetails?.dateOfBirth
                    ? moment(userDetails.dateOfBirth, 'YYYY-MM-DD').format("DD")
                    : "",
                month: userDetails?.dateOfBirth
                    ? moment(userDetails.dateOfBirth, 'YYYY-MM-DD').format("MM")
                    : "",
                year: userDetails?.dateOfBirth
                    ? moment(userDetails.dateOfBirth, 'YYYY-MM-DD').format("YYYY")
                    : "",
                weeklyNews: userDetails?.weekly_news ? 1 : 0,
            },
            onSubmit: async (values) => {
                const updateProfilePayload: any = {
                    PreferredGenres: values.genres.join(","),
                    countryId: countryId,
                    dateOfBirth: `${values.year}-${values.month}-${values.day}`,
                    email: values.email,
                    firstName: values.firstName,
                    gender: values.gender,
                    id: userDetails?.id,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber,
                    suburb: values.suburb,
                    updatedEmail: values.email,
                    weeklyNews: values.weeklyNews,
                    zipCode: values.postCode,
                    city: values.city,
                    theatreId: values.cinemaId,
                    Id: userDetails?.id,
                };
                const { data: { statusCode, data: { message, vistaMemberId, cardNumber } } } = await createMembership(updateProfilePayload);
                if (statusCode === 200) {
                        const payload = {
                            MemberRefId: vistaMemberId,
                            UserSessionId: cardNumber,
                            cardNumber: cardNumber,
                            countryId: countryId,
                            memberId: userDetails?.id,
                            emailId: userDetails?.email,
                        };
                        dispatch(memberValidationWatcher(payload));
                        dispatch(
                            setUserDetails({
                                ...userDetails,
                                phoneNumber: updateProfilePayload.phoneNumber,
                                dateOfBirth: updateProfilePayload.dateOfBirth,
                                cardNumber: cardNumber,
                                vista_memberid: vistaMemberId,
                            })
                        );
                    toast.success(<div className='membership-success'>
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+'tick-black.svg'}/>
                    <span>
                    Thank you for joining Angelika Membership.<br/>
                    Please check your Membership ID Number and Barcode information under User Profile Tab.</span>
                </div>,{className: 'membership_toast', autoClose: 9000});
                    onCloseModal();
                } else {
                    toast.error(message);
                }
            }
        });

    const onOpenModal = () => {
        dispatch(setMembershipModal(true))
    }

    const onCloseModal = () => {
        dispatch(setMembershipModal(false));
        resetForm();
    }

    return <><div className='join-membership'>
        <div className='title'>
            Become an Angelika Member Sign Up for <span>FREE</span> NOW!
        </div>
        <button className='btn-join' onClick={onOpenModal}>
            JOIN
        </button>
    </div>
        <Modal visible={membershipModal} className="join-membership-popup" showCloseBtn={false}>
            <div className="modal fade show" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered modal-lg ">

                    <div className="modal-content">
                        <button type="button" className="close-modal" onClick={onCloseModal}>
                            <img
                                className="close_icon"
                                src={URLS.CLOSE_ICON}
                                alt="close_icon"
                                title="close_icon"
                            />
                        </button>
                        <div className="modal-body">
                            <h1 className="title">Confirm your information for Angelika Membership & Rewards</h1>
                            <p className='desc'>
                                Please confirm below information to join Angelika Membership & Rewards program.
                            </p>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <label className="form-label">Date of Birth*</label>
                                    <div className="form-group">
                                        <div
                                            className={`row date-of-birth-order`}
                                        >
                                            <div className="col-4 col-md-4">
                                                <SelectDropdown
                                                    field={"month"}
                                                    options={MONTH_LIST}
                                                    setFieldValue={setFieldValue}
                                                    touched={touched.month!}
                                                    error={errors.month}
                                                    value={values.month}
                                                />
                                            </div>
                                            <div className="col-4 col-md-4">
                                                <SelectDropdown
                                                    field={"day"}
                                                    options={dateArray().map((v) => {
                                                        v.label = Math.round(+v.label).toString();
                                                        return v;
                                                    })}
                                                    setFieldValue={setFieldValue}
                                                    touched={touched.day!}
                                                    error={errors.day}
                                                    value={values.day}
                                                />
                                            </div>
                                            <div className="col-4 col-md-4">
                                                <SelectDropdown
                                                    field={"year"}
                                                    options={yearArray()}
                                                    setFieldValue={setFieldValue}
                                                    touched={touched.year!}
                                                    error={errors.year}
                                                    value={values.year}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label">Mobile Phone*</label>
                                    <div className="form-group position-relative">
                                        <TextInput
                                            value={values.phoneNumber}
                                            field={"phoneNumber"}
                                            handleChange={handleChange}
                                            touched={touched.phoneNumber!}
                                            error={errors.phoneNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className='footer-desc'>
                                *for Birthday Popcorn Reward!<img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'popcorn-bucket.svg'} />
                            </p>
                            <div className='d-flex justify-content-center mt-3'>
                                <button className='btn black_btn btn-grey mx-3' onClick={onCloseModal}>CANCEL</button>
                                <button className='btn green_btn mx-3' onClick={() => handleSubmit()}><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+'tick-white.svg'}/>SAVE CHANGES</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default JoinMembership;
import React, { Suspense, useEffect, useState } from "react";
import AppRoutes from "./appRoutes";
import { useDispatch, useSelector } from "react-redux";
import { BRANDS, BRAND_ID, COUNTRY, DOMAINS } from "../../constants/app";
import {
  getPromoAdWatcher,
  getSettingsWatcher,
  setBrandId,
  setReinitializeScroll,
} from "../../store/application/action";
import Loader from "../../components/UI/Loader";
import { RootState } from "../../store";
import PromoAd from "../../components/PromoAd";
import Storage from "../../utils/storage";
import $ from "jquery";
import "malihu-custom-scrollbar-plugin";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
import TicketBooking from "../Common/Modals";
import "react-toastify/dist/ReactToastify.css";
import InfoPopup from "../../components/InfoPopup";
import Auth from "../Common/Modals/Auth";
import {
  memberValidationSuccess,
  memberValidationWatcher,
  setUserDetails,
} from "../../store/auth/action";
import ScrollToTop from "../../components/Routes/ScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import ContentModal from "../Common/ContentModal";
import { useNavigate } from "react-router";
import { ROUTES } from "../../constants/path";
import SuspenseLoader from "../../components/UI/CustomLoader/suspenseLoader";
require("jquery-mousewheel");

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { countryId, reinitializeScroll, brandId, settings, currentCinema } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      reinitializeScroll: state.applicationReducer.reinitializeScroll,
      brandId: state.applicationReducer.brandId,
      settings: state.applicationReducer.settings,
      currentCinema: state.applicationReducer.currentCinema,
    }));

  const [showPromo, setShowPromo] = useState<boolean>(false);

  const loadAppleJs = () => {
    const script = document.createElement("script");
    script.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
    document.body.appendChild(script);
  };

  useEffect(() => {
    const url = window.location.href;
    let countryId = COUNTRY.US;
    const domain = window.location.origin;
    let favIcon = "favicon_us.ico";
    let themeColor = "#DA2128";
    let hoverBorder = "#DA2128";
    let divider = "#DA2128";
    let fontLight = "PlusJakartaSans-Light";
    let fontRegular = "PlusJakartaSans-Regular";
    let fontMedium = "PlusJakartaSans-Medium";
    let fontSemiBold = "PlusJakartaSans-SemiBold";
    let fontBold = "PlusJakartaSans-Bold";
    let fontExtraBold = "PlusJakartaSans-ExtraBold";
    if (url.includes(DOMAINS.AUS)) {
      countryId = COUNTRY.AUS;
      fontLight = "Gotham-Book";
      fontRegular = "Gotham-Book";
      fontMedium = "Gotham-Medium";
      fontSemiBold = "Gotham-Medium";
      fontBold = "Gotham-Bold'";
      fontExtraBold = "Gotham-Black";
      loadAppleJs();
    } else if (url.includes(DOMAINS.NZ)) {
      countryId = COUNTRY.NZ;
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/";
      document.body.appendChild(script);
    } else if (url.includes(DOMAINS.ANG)) {
      countryId = COUNTRY.ANG;
      loadAppleJs();
      favIcon = "angelika.ico";
    } else if (url.includes(DOMAINS.STA)) {
      countryId = COUNTRY.STA;
      loadAppleJs();
    } else if (url.includes(DOMAINS.US)) {
      countryId = COUNTRY.US;
      favIcon = "favicon_us.ico";
      themeColor = "#DA2128";
      hoverBorder = "#DA2128";
      divider = "#DA2128";
      fontLight = "PlusJakartaSans-Light";
      fontRegular = "PlusJakartaSans-Regular";
      fontMedium = "PlusJakartaSans-Medium";
      fontSemiBold = "PlusJakartaSans-SemiBold";
      fontBold = "PlusJakartaSans-Bold";
      fontExtraBold = "PlusJakartaSans-ExtraBold";
      loadAppleJs();
    } else if (url.includes(DOMAINS.AFC)) {
      countryId = COUNTRY.AFC;
      favIcon = "favicon_afc.ico";
      themeColor = "#FF007E";
      hoverBorder = "#0099FF";
      divider = "#000000";
      fontLight = "FuturaPT-Medium";
      fontRegular = "FuturaPT-Medium";
      fontMedium = "FuturaPT-Medium";
      fontSemiBold = "FuturaPT-Medium";
      fontBold = "FuturaPT-Medium";
      fontExtraBold = "FuturaPT-Medium";
      loadAppleJs();
    } else if (url.includes(DOMAINS.CON)) {
      countryId = COUNTRY.CON;
      favIcon = "con/favicon.ico?v=1";
      themeColor = "#04BFBF";
      hoverBorder = "#952DD4";
      divider = "#000000";
      fontLight = "FuturaPT-Medium";
      fontRegular = "FuturaPT-Medium";
      fontMedium = "FuturaPT-Medium";
      fontSemiBold = "FuturaPT-Medium";
      fontBold = "FuturaPT-Medium";
      fontExtraBold = "FuturaPT-Medium";
      loadAppleJs();
    }

    document.documentElement.style.setProperty("--is-brandTheme", themeColor);
    document.documentElement.style.setProperty("--is-hoverBorder", hoverBorder);
    document.documentElement.style.setProperty("--is-divider", divider);
    document.documentElement.style.setProperty("--is-fontLight", fontLight);
    document.documentElement.style.setProperty("--is-fontRegular", fontRegular);
    document.documentElement.style.setProperty("--is-fontMedium", fontMedium);
    document.documentElement.style.setProperty(
      "--is-fontSemiBold",
      fontSemiBold
    );
    document.documentElement.style.setProperty("--is-fontBold", fontBold);
    document.documentElement.style.setProperty(
      "--is-fontExtraBold",
      fontExtraBold
    );

    const linkElement = document.querySelector("link[rel=icon]");
    if (linkElement) {
      linkElement.setAttribute("href", `${domain}/${favIcon}`);
    }
    dispatch(setBrandId(BRAND_ID[countryId]));
    dispatch(getSettingsWatcher({ countryId }));
  }, []);

  useEffect(() => {
    if (settings && currentCinema && settings.enableUndermaintenance === 1) {
      navigate(`${currentCinema?.alias}/${ROUTES.PAGE_NOT_FOUND}`);
    }
  }, [settings, currentCinema]);
  useEffect(() => {
    if (reinitializeScroll) {
      ($(".mCustomScrollbar") as any).mCustomScrollbar({
        theme: "dark",
        axis: "x",
        scrollInertia: 500,
        autoHideScrollbar: true,
      });
      dispatch(setReinitializeScroll(false));
    }
  }, [reinitializeScroll]);

  useEffect(() => {
    if (countryId) {
      //checking promoAdd
      const checkStatus = Storage.getItem("LOCAL", "promoad");
      if (
        (!checkStatus || checkStatus != new Date().toDateString()) &&
        brandId === BRANDS.AU
      ) {
        dispatch(getPromoAdWatcher({ countryId: countryId }));
        setShowPromo(true);
        Storage.setItem("LOCAL", "promoad", new Date().toDateString());
      }

      //Checking User info
      const userDeatils = Storage.getItem("LOCAL", "userDetails");
      if (userDeatils && Object.values(userDeatils).length > 0) {
        dispatch(setUserDetails(userDeatils));

        const memberDetail = Storage.getItem("LOCAL", "memberDetail");
        if (memberDetail && Object.values(memberDetail).length > 0) {
          dispatch(memberValidationSuccess(memberDetail));
        } else {
          const payload = {
            MemberRefId: userDeatils?.vista_memberid,
            UserSessionId: userDeatils?.result?.cardNumber,
            cardNumber: userDeatils?.result?.cardNumber,
            countryId: countryId,
          };
          dispatch(memberValidationWatcher(payload));
        }
      }
    }
  }, [countryId]);

  return (
    <>
      <Suspense fallback={<SuspenseLoader/>}>
      <AppRoutes />
      </Suspense>
      <ScrollToTop />
      <Loader />
      {showPromo ? <PromoAd /> : null}
      <TicketBooking />
      <InfoPopup />
      <Auth />
      <ContentModal />
      <ToastContainer
        position={brandId === BRANDS.US ? "top-center" : "top-right"}
        toastClassName={
          brandId === BRANDS.US ? "custom-toast-us" : "custom-toast"
        }
        hideProgressBar={true}
        limit={1}
        newestOnTop={true}
        closeButton={brandId === BRANDS.US ? false : true}
      />
  </>
  );
};

export default App;

import React, { FC, useEffect, useState } from "react";
import "./eatAndDrink.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import { eatAndDrinkApi } from "../../../../services/films";
import { IEatAndDrink } from "../../../../models/foodbeverage";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { decode } from "html-entities";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { COUNTRY } from "../../../../constants/app";

interface IEatAndDrinkProps {}

const EatAndDrink: FC<IEatAndDrinkProps> = () => {
  const { countryId, currentCinema, homeContent } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
    homeContent: state.applicationReducer.homeContent
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eatDrink, setEatDrink] = useState<IEatAndDrink[]>([]);

  useEffect(() => {
    if (Array.isArray(homeContent.eatAndDrink) && homeContent.eatAndDrink.length > 0) {
      const [value] = homeContent.eatAndDrink;
      const { Tiles } = value;
      setEatDrink(Tiles);
    }else{
      setEatDrink([]);
    }
  }, [homeContent]);

  const orderNow = (v: IEatAndDrink) => {
    navigate(
      `/${currentCinema?.alias}/${ROUTES.EAT_AND_DRINK}${
        v.LinkedToTab ? `?tab=${v.LinkedToTab}` : ""
      }`
    );
  };
  const download = (v: IEatAndDrink) => {
    window
      .open(
        `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${v.DownloadFile}.${v.DownloadFileExtension}`,
        "_blank"
      )
      ?.focus();
  };

  return (
    <section className={`eat-drinks-section ${countryId === COUNTRY.CON?'consolidated':countryId === COUNTRY.AFC?'angelika-film-center':''}`}>
      {eatDrink.length > 0 ? (
        <>
          <div className="header">
            <div>&nbsp;</div>
            <h2 className="heading">EAT & DRINK</h2>
          </div>
          <div className="eat-drinks-content">
            <div className="d-flex w-100 justify-content-center">
              <div className="container-fluid">
                <div className="card__wrap--outer">
                  {eatDrink.map((v, index: number) => (
                    <div className="card__wrap--inner">
                      <div className="card">
                        <img
                          src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.MainImage}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`}
                        />

                        <div className="card__item flexible">
                          <div className="card-head">
                            <h3 className="header">
                              <span>{decode(v.Title)}</span>
                            </h3>
                            {v.Description ? (
                              <HtmlRender
                                {...{ className: "description" }}
                                content={v.Description}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="card__footer">
                          {v.DownloadMenu === "1" && v.DownloadFile ? (
                            <a
                              className="btn btn-border-danger-new mx-2"
                              onClick={() => download(v)}
                              target="_blank"
                            >
                              DOWNLOAD MENU
                            </a>
                          ) : null}
                          {v.OrderNow === "1" ? (
                            <a
                              className="btn btn-border-danger-new mx-2"
                              onClick={() => orderNow(v)}
                            >
                              ORDER NOW
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default EatAndDrink;

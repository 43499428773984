import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import { walletPassApi } from "../../../../services/auth";
import { ISavedCards } from "../../../../models/auth";
import {
  getSavedCardWatcher,
  getSavedGiftCardsWatcher,
  showManageCardModal,
  showManageGiftCardModal,
  showUnsubscribeMembershipModal,
  showUpdateProfileModal,
} from "../../../../store/auth/action";
import UpdateProfile from "../UpdateProfile";
import ManageCards from "../ManageCards";
import { toast } from "react-toastify";
import UpgradeGoldBtn from "../../../Auth/Membership/UpgradeGoldBtn";
import { ALLOWED_MEMBER_VALIDATE, AUTH_MENU, BRANDS, COUNTRY, WALLET_PASS_KEY } from "../../../../constants/app";
import ManageGiftCards from "../ManageGiftCards";
import UnsubscribeRewards from "../UnsubscribeRewards";
import { Link } from "react-router-dom";
import { openSliderAction, setContentModal, toggleContentModal } from "../../../../store/application/action";
import { encryptData } from "../../../../utils/helper";

interface IMyAccountProps { }

const MyAccount: FC<IMyAccountProps> = () => {
  const { countryId, userDetails, memberDetail, savedCards, isLoggedIn, currentCinema, brandId } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
      savedCards: state.authReducer.savedCards,
      isLoggedIn: state.authReducer.isLoggedIn,
      currentCinema: state.applicationReducer.currentCinema,
      brandId: state.applicationReducer.brandId
    }));

  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    if (userDetails && isLoggedIn) {
      const saveCardData = {
        countryId: `${countryId}`,
        emailId: userDetails?.email,
        memberRefId: userDetails?.result?.memberRefId,
      };
      dispatch(getSavedCardWatcher(saveCardData));
      dispatch(getSavedGiftCardsWatcher(saveCardData))
    }
  }, [userDetails && isLoggedIn]);

  const onClickEdit = () => {
    dispatch(showUpdateProfileModal(true));
  };

  const onClickManageCards = () => {
    dispatch(showManageCardModal(true));
  };

  const onClickManageGiftCards = () => {
    dispatch(showManageGiftCardModal(true));
  };

  const onClickUnsubscribe = () => {
    dispatch(showUnsubscribeMembershipModal(true));
  };


  const onCloseSideBar = () => {
    dispatch(openSliderAction({ open: false, type: "account" }));
  };

  const openTerms = () => {
    dispatch(
      setContentModal({
        type: "Etix Terms",
      })
    );
    dispatch(toggleContentModal(true));
  };


  const isMember = useMemo(() => {
    return memberDetail && memberDetail?.LoyaltyMember && memberDetail?.LoyaltyMember?.CardNumber ? true : false
  }, [memberDetail])

  const hasRestriction = useMemo(() => {
    return ((ALLOWED_MEMBER_VALIDATE.includes(currentCinema?.slug) && brandId === BRANDS.US) || (brandId === BRANDS.AU)) ? false : true
  }, [currentCinema])

  const googleWalletUrl = useMemo(()=>{
    const data = encryptData(
        JSON.stringify({
          id: userDetails?.result?.memberRefId!,
          emailId: userDetails?.email!,
          type: API_REQUEST_TYPE.LOYALTY,
          wallet: "google",
          countryId: countryId,
          device:'web'
        }),
      WALLET_PASS_KEY
    );
    return `${URLS.API_BASE_URL}/applewallet/getpass?id=${encodeURIComponent(data)}`;
  },[userDetails])


  const appleWalletUrl = useMemo(()=>{
    const data = encryptData(
        JSON.stringify({
          id: userDetails?.result?.memberRefId!,
          emailId: userDetails?.email!,
          type: API_REQUEST_TYPE.LOYALTY,
          wallet: "apple",
          countryId: countryId,
          device:'web'
        }),
      WALLET_PASS_KEY
    );
    return `${URLS.API_BASE_URL}/applewallet/getpass?id=${encodeURIComponent(data)}`;
  },[userDetails])


  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="account-details-card ">
            <div className="row">
              <div className="col-12 col-md-12 section_divided">
                <div className="title">
                  ACCOUNT INFORMATION
                  <span className="float-right edit" onClick={onClickEdit}>
                    EDIT <span className="chevron_right"></span>
                  </span>
                </div>
                <div className="user-name">
                  {userDetails?.firstName ? userDetails?.firstName.toLocaleUpperCase() : ''} {userDetails?.lastName ? userDetails?.lastName.toLocaleUpperCase() : ''}
                </div>
                <div className="user-email">{userDetails?.email}</div>
                <div className="user-email">
                  {userDetails?.phoneNumber}
                  <div>
                    <span className="float-right"></span>
                    {memberDetail?.LoyaltyMember?.City},&nbsp;
                    {memberDetail?.LoyaltyMember?.Suburb ? (
                      <span className="float-right">
                        {memberDetail?.LoyaltyMember?.Suburb},
                      </span>
                    ) : null}
                    {userDetails?.zipCode ? (
                      <span className="float-right">
                        {userDetails?.zipCode}
                      </span>
                    ) : null}
                  </div>
                </div>
                {AUTH_MENU.wallet.enable.includes(countryId) && isMember && !hasRestriction?
                  <div className="member_wallet_wrapper">
                    <p>Add membership card to:</p>
                    <div className="member_wallet">
                      <div className="g_pay">
                        <a href={googleWalletUrl} target="_blank"> <img
                          src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'google_wallet.svg'}
                        /></a>
                      </div>
                      <div className="g_pay">
                        <a href={appleWalletUrl} target="_blank"><img
                          src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'apple_wallet.svg'}
                        /></a>
                      </div>
                    </div>
                  </div> : null}
              </div>
              <div className="col-12 col-md-12">
                <div className="payment-detail">
                  DEFAULT PAYMENT METHOD
                  <span
                    className="float-right edit"
                    onClick={onClickManageCards}
                  >
                    EDIT <span className="chevron_right"></span>
                  </span>
                </div>
                {savedCards.map((cards: ISavedCards) => (
                  <div>
                    {cards?.defaultCard === 1 && cards?.cardNumber ? (
                      <div>
                        <div className="user-name">{cards?.cardName}</div>
                        <div className="user-name">
                          **** **** **** **** {(cards?.cardNumber).slice(-3)}
                        </div>
                        <div className="user-name">
                          {cards?.expiryDate.slice(0, 2)}/
                          {cards?.expiryDate.slice(-2)}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="col-12 col-md-12 mt-3">
                <div className="payment-detail">
                  MANAGE GIFT CARD
                  <span
                    className="float-right edit"
                    onClick={onClickManageGiftCards}
                  >
                    EDIT <span className="chevron_right"></span>
                  </span>
                </div>
              </div>
              {countryId === COUNTRY.AFC ? <div className="col-12 col-md-12 mt-3">
                <div className="membership-detail cursor-pointer" onClick={() => setOpenDropdown(!openDropdown)}>
                  MANAGE MY MEMBERSHIP
                  <span
                    className="float-right edit"
                  >
                    <span className={`chevron_right ${openDropdown ? "arrow_up" : ""}`}></span>
                  </span>
                </div>
                {openDropdown ?
                  <div className="submenu-item">
                    <Link className="faqs" to={URLS.ANGELIKA_BLOG_FAQ} target="_blank">
                      <div>FAQ
                        <span
                          className="float-right edit"
                        >
                          <span className="chevron_right"></span>
                        </span>
                      </div>
                    </Link>
                    <Link className="terms" to={URLS.ANGELIKA_BLOG_TERMS} target="_blank">Membership Terms & Conditions
                      <span
                        className="float-right edit"
                      >
                        <span className="chevron_right"></span>
                      </span>
                    </Link>
                    {isMember ? <div className="cursor-pointer" onClick={() => dispatch(showUnsubscribeMembershipModal(true))}>Unsubscribe Membership
                      <span
                        className="float-right edit"
                      >
                        <span className="chevron_right"></span>
                      </span>
                    </div> : null}
                  </div> : null
                }
              </div> : null}
              {isLoggedIn &&
                memberDetail &&
                memberDetail?.LoyaltyMember &&
                memberDetail?.LoyaltyMember?.MemberLevelId !== 4 && AUTH_MENU.loyaltyDetails.enable.includes(countryId) ? (
                <div>
                  <div className="d-flex justify-content-center mt-4">
                    <UpgradeGoldBtn fromPage="sideMenu" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <UpdateProfile /> */}
      <ManageCards />
      <ManageGiftCards />
      <UnsubscribeRewards />
    </>
  );
};

export default MyAccount;

import { AnyAction } from "@reduxjs/toolkit";
import { IFilms, ACTIONS } from './type';


const initialState: IFilms = {
    nowShowing: [],
    comingSoon: [],
    advanceTicket: [],
    signatureProgram: [],
    titanMovies: [],
    quickFilters: {
        location: null,
        movie: null,
        format: null,
        session: null,
        date: null
    },
    searchFilter: '',
    nowshowingLoader: false,
    nowshowingFilterData: {
        format: [],
        session: []
    },
    loaderNowshowing: false,
    loaderComingsoon: false,
    loaderAdvanceticket: false,
};

export default function filmsReducer(state = initialState, action: AnyAction) {

    switch (action.type) {
        case ACTIONS.SET_NOW_SHOWING_LOADER:
            return {
                ...state,
                nowshowingLoader: action.payload
            }
        case ACTIONS.GET_NOW_SHOWING_SUCCESS:
            return {
                ...state,
                nowShowing: action.payload,
            };
        case ACTIONS.GET_NOW_SHOWING_FAILURE:
            return {
                ...state,
                nowShowing: [],
            };
        case ACTIONS.GET_NOW_SHOWING_FILTER_DATA_SUCCESS:
            return {
                ...state,
                nowshowingFilterData: action.payload
            };
        case ACTIONS.GET_NOW_SHOWING_FILTER_DATA_FAILURE:
            return {
                ...state,
                nowshowingFilterData: {
                    format: [],
                    session: []
                }
            };
        case ACTIONS.GET_COMING_SOON_SUCCESS:
            return {
                ...state,
                comingSoon: action.payload,
            };
        case ACTIONS.GET_COMING_SOON_FAILURE:
            return {
                ...state,
                comingSoon: [],
            };
        case ACTIONS.GET_ADVANCE_TICKET_SUCCESS:
            return {
                ...state,
                advanceTicket: action.payload,
            };
        case ACTIONS.GET_ADVANCE_TICKET_FAILURE:
            return {
                ...state,
                advanceTicket: [],
            };
        case ACTIONS.SET_QUICK_FILTER:
            return {
                ...state,
                quickFilters: action.payload
            };
        case ACTIONS.GET_SIGNATURE_PROGRAM_SUCCESS:
            return {
                ...state,
                signatureProgram: action.payload,
            };
        case ACTIONS.GET_SIGNATURE_PROGRAM_FAILURE:
            return {
                ...state,
                signatureProgram: [],
            };
        case ACTIONS.GET_TITAN_MOVIES_SUCCESS:
            return {
                ...state,
                titanMovies: action.payload,
            };
        case ACTIONS.GET_TITAN_MOVIES_FAILURE:
            return {
                ...state,
                titanMovies: [],
            };
        case ACTIONS.SEARCH_MOVIE:
            return {
                ...state,
                searchFilter: action.payload
            }
        case ACTIONS.SET_LOADER_NOWSHOWING:
            return {
                ...state,
                loaderNowshowing: action.payload
            }
        case ACTIONS.SET_LOADER_COMINGSOON:
            return {
                ...state,
                loaderComingsoon: action.payload
            }
        case ACTIONS.SET_LOADER_ADVANCETICKET:
            return {
                ...state,
                loaderAdvanceticket: action.payload
            }

        default:
            return state
    }

}
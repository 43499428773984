import { COUNTRY, GIFT_CARD_DELIVERY_FEE } from "../constants/app";
import { IMAGE_URL_CONFIG } from "../constants/url";
import { GIFT_CARD_CATEGORY_TYPE, GIFT_CARD_RESTRICTION_TYPE, GIFT_CARD_TYPE, GiftType, PROMO_TYPE } from "../models/giftcard";
import { ICartData, IGiftCardPromo } from "../store/giftshop/type";

export const calTotal = (cartData: ICartData[], countryId: COUNTRY) => {
  let fee: number = 0;

  let total = cartData.reduce((sum: number, item: ICartData) => sum + item.itemTotal, 0);
  let phyCards = cartData
    .filter((item: ICartData) => item.giftcardType != GiftType.ecard);
  let phyTotal = phyCards.filter(
    (v, i, a) => a.findIndex((t: ICartData) => ((t.address1+t.address2+t.city+t.state+t.postCode).replace(/ /g,'').toLowerCase() === (v.address1+v.address2+v.city+v.state+v.postCode).replace(/ /g,'').toLowerCase())) === i
  );

  if (countryId === COUNTRY.US || countryId === COUNTRY.AFC || countryId === COUNTRY.CON) {
    let totalFee = phyTotal.reduce((total:number,v:ICartData)=>{
       return Number(v.shippingDetails?.shippingFee) + total;
    },0) ;
    let deliveryFee = +totalFee;
    let deliveryQty = phyTotal.length;
    let finalAmount = (total + totalFee);
    return { deliveryFee, deliveryQty, total: finalAmount,deliveryCharge:0, shippingAddress: phyTotal.map((v:ICartData)=>({
      address1:v.address1,
      address2:v.address2,
      city:v.city,
      state:v.state,
      postCode:v.postCode,
      shippingDetails: v.shippingDetails
    }))}
  } else {
    if (countryId === COUNTRY.NZ) {
      fee = GIFT_CARD_DELIVERY_FEE.NZ;
    } else if (countryId === COUNTRY.ANG) {
      fee = GIFT_CARD_DELIVERY_FEE.ANG;
    } else if (countryId === COUNTRY.STA) {
      fee = GIFT_CARD_DELIVERY_FEE.STA;
    } else if (countryId === COUNTRY.AUS) {
      fee = GIFT_CARD_DELIVERY_FEE.AU;
    } 
    let totalFee = phyTotal.length * fee!;
    let deliveryFee = +totalFee;
    let deliveryQty = phyTotal.length;
    let finalAmount = (total + totalFee);
    return { deliveryFee, deliveryQty, total: finalAmount, deliveryCharge: fee, shippingAddress:[] }
  }

}


export const findGiftPromo = (giftPromo: [], amount: number, currentTab: string): IGiftCardPromo | undefined => {

  return giftPromo.find((v: any) => {
    return (
      (v.minimumSpendFlag === 1 &&
        amount >= v.minimumSpendAmount &&
        GIFT_CARD_RESTRICTION_TYPE[v.cardTypeRestriction] === currentTab) ||
      (!v.minimumSpendFlag && GIFT_CARD_RESTRICTION_TYPE[v.cardTypeRestriction] === currentTab) ||
      (!v.minimumSpendFlag && !v.cardTypeRestriction)
    );
  });
}

export const formatPromo = (value: any, index: number, promo: IGiftCardPromo) => {
  return {
    ...value,
    promoType: PROMO_TYPE.SEPARATE,
    promo: promo,
    parentIndex: index,
    quantity: 1,
    giftamount: 0,
    price: 0,
    tax: 0,
    itemTotal: 0,
    minimumVariablePriceInCents: 0,
    maximumVariablePriceInCents: 0,
    description: promo?.name,
  };
};

export const formatEGiftCard = (data: any) => {
  return data.map((v: any) => {
    return {
      ...v,
      IsVariablePriceItem: v.type === 2 ? true : false,
      MaximumVariablePriceInCents: "NaN",
      MinimumVariablePriceInCents: v.type === 2 ? v.minimum_amount : "NaN",
      description: v.name,
      image: v.image,
      shortImage: v.image,
      tax: "0.00"
    }
  })
}
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { FC, useEffect, useState } from "react";
import moment from "moment";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import TextInput from "../../../../components/UI/TextInput";
import SelectDropDown from "../../../../components/UI/DropDown/SelectDropDown";
import SelectDropdown from "../../../../components/UI/DropDown/SelectDropDown";
import {
  vipCinemaTypeApi,
  vipCinemasFormApi,
} from "../../../../services/sideMenus";
import "./vipcinemas.scss";
import CalendarPicker from "../../../../components/UI/Calendar";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY, TimeOfDayList } from "../../../../constants/app";
import { toast } from "react-toastify";

interface IVipExperienceProps {
  onCloseModal: () => void;
}

const VipExperienceForm: FC<IVipExperienceProps> = ({ onCloseModal }) => {
  const { countryId, currentCinema, cinemas } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      cinemas: state.applicationReducer.cinemas,
    })
  );

  const [cinemaList, setCinemaList] = useState([]);
  const [movies, setMovies] = useState([]);
  const [card, setCard] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [cardType, setCardType] = useState([]);
  const [days, setDays] = useState<any>([]);
  const [dateDisable, setDateDisable] = useState<boolean>(true);

  useEffect(() => {
    if (cinemas) {
      let tempCinemas: any = [];
      Object.keys(cinemas).forEach(function (key) {
        const arrList = cinemas[key];
        arrList.forEach((item: any) => {
          tempCinemas.push({
            label: `${item.name}`,
            value: `${item.slug}`,
          });
        });
      });
      setCinemaList(tempCinemas);
    }
  }, []);

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("vipBooking"),
      initialValues: {
        cinema: "",
        movie: "",
        comments: "",
        companyName: "",
        contactNumber: "",
        email: "",
        name: "",
        timeOfDay: "",
        movieTitle: "",
        date: "",
        captcha: "",
      },
      onSubmit: async (values) => {
        if (card && Object.keys(card).length > 0 && !errorMessage) {
          const preparedData = {
            cinema: values.cinema,
            comments: values.comments,
            companyName: values.companyName,
            contactNumber: values.contactNumber,
            countryId: countryId,
            date: values.date,
            email: values.email,
            experience: card.type_name,
            movie:
              values.movie === "Request a movie"
                ? values.movieTitle
                : values.movie,
            name: values.name,
            otherMovie: values.movieTitle ? values.movieTitle : "",
            timeOfDay: values.timeOfDay,
          };
          const { data } = await vipCinemasFormApi(preparedData);
          if (data) {
            toast.success("Booking details submitted successfully.");
            onCloseModal();
          } else {
            toast.error(
              "Could not connect to services at the moment. Please try again. Thanks."
            );
          }
        }
      },
    });

  const onChangeCinema = async (event: any) => {
    setMovies([]);
    setCardType([]);
    const {
      data: { data, errorType },
    } = await vipCinemaTypeApi(event);

    if (Object.keys(data.vipTypes).length !== 0) {
      if (data.vipTypes.movies && data.vipTypes.movies.length > 0) {
        let tempMovie: any = [];
        data.vipTypes.movies.forEach((item: any) => {
          tempMovie.push({
            label: `${item.movie_name}`,
            value: `${item.movie_name}`,
          });
        });
        tempMovie.unshift({
          label: "Request a movie",
          value: "Request a movie",
        });
        setMovies(tempMovie);
      } else {
        let tempMovie: any = [];
        tempMovie.push({
          label: "Request a movie",
          value: "Request a movie",
        });
        setMovies(tempMovie);
      }
      const daysArr = data.vipTypes?.days.split(",");

      const totalDaysNo = [0, 1, 2, 3, 4, 5, 6];
      const days = totalDaysNo.filter((el) => {
        return !daysArr.includes(el.toString());
      });

      setDays(days);
      setCardType(data.vipTypes.types);
      setErrorMessage("");
      setDateDisable(false);
    } else {
      setDays([0, 1, 2, 3, 4, 5, 6]);
      setErrorMessage(
        "VIP Cinema experience not available for the selected cinema"
      );
      setDateDisable(true);
    }
  };

  const handleCard = (movie: any) => {
    setCard(movie);
  };

  const onChangeMovie = () => {
    setFieldValue("movieTitle", "");
  };

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  return (
    <div className="cinema-hire-wrapper">
      <div className="modal-header">
        <div className="row">
          <div className="col-md-12 mt-4">
            <h2 className="title">Book Now</h2>
            <div className="col-md-12">
              <p>
                Please complete all the fields below to request your VIP Cinema
                Experience. A member of our team will be in touch to confirm
                your booking. If the film you are looking for is not listed,
                please select *request a movie and we can advise if it's
                possible to bring it in for your group.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="form_page">
          <div className="row">
            <div className="col-6 col-md-6">
              <label className="form-label">Cinema*</label>
              <div className="form-group pass_show">
                <SelectDropDown
                  field={"cinema"}
                  options={cinemaList}
                  setFieldValue={setFieldValue}
                  touched={touched.cinema!}
                  error={errors.cinema}
                  value={values.cinema}
                  onChange={onChangeCinema}
                />
              </div>
              {errorMessage ? (
                <div className="error_message">
                  VIP Cinema experience not available for the selected cinema
                </div>
              ) : null}
            </div>
            <div className="col-6 col-md-6">
              <label className="form-label">Movie*</label>
              <div className="form-group pass_show">
                <SelectDropdown
                  field={"movie"}
                  options={movies}
                  setFieldValue={setFieldValue}
                  touched={touched.movie!}
                  error={errors.movie}
                  value={values.movie}
                  onChange={onChangeMovie}
                />
              </div>
            </div>
            {values.movie === "Request a movie" ? (
              <div className="col-6 col-md-6 ">
                <label className="form-label">Movie Title*</label>
                <div className="form-group pass_show">
                  <TextInput
                    value={values.movieTitle}
                    field={"movieTitle"}
                    handleChange={handleChange}
                    touched={touched.movieTitle!}
                    error={errors.movieTitle}
                  />
                </div>
              </div>
            ) : null}

            <div className="category_wrap">
              <ul>
                {cardType.map((movie: any) => (
                  <li onClick={() => handleCard(movie)}>
                    <div
                      className={`category_list ${
                        movie.id == card?.id ? "active" : ""
                      }`}
                    >
                      <h6>{movie.type_name}</h6>
                      <p>{movie.description}</p>
                      <div className="value_wrap">
                        <div>
                          <span>Total</span>
                          <h3>${movie.amount}</h3>
                        </div>
                        <p>Up to {movie.no_of_people} People</p>
                      </div>
                    </div>
                    <div
                      className={`cat_footer ${
                        movie.id == card?.id ? "active" : ""
                      }`}
                    >
                      {movie.id == card?.id
                        ? "Selected"
                        : "Choose the experience"}
                    </div>
                  </li>
                ))}
              </ul>
              {cardType.length > 0 && (!card || !Object.keys(card).length) ? (
                <div className="error_message col-md-12 text-center">
                  <span>Please Choose the experience</span>
                </div>
              ) : null}
            </div>

            <div className="col-6 col-md-6 ">
              <label className="form-label">Name*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.name}
                  field={"name"}
                  handleChange={handleChange}
                  touched={touched.name!}
                  error={errors.name}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Company Name</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.companyName}
                  field={"companyName"}
                  handleChange={handleChange}
                  touched={touched.companyName!}
                  error={errors.companyName}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Contact Number</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.contactNumber}
                  field={"contactNumber"}
                  handleChange={handleChange}
                  touched={touched.contactNumber!}
                  error={errors.contactNumber}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Email*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.email}
                  field={"email"}
                  handleChange={handleChange}
                  touched={touched.email!}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Date*</label>
              <div className="form-group form-group-date-field">
                <CalendarPicker
                  minDate={new Date(moment().add(7, "days").format())}
                  tileDisabled={({ date }: any) =>
                    days.includes(date.getDay()) || dateDisable
                  }
                  field={"date"}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  touched={touched.date!}
                  error={errors.date}
                />
              </div>
            </div>
            <div className="col-6 col-md-6">
              <label className="form-label">Time of Day</label>
              <div className="form-group">
                <SelectDropdown
                  field={"timeOfDay"}
                  options={TimeOfDayList}
                  value={values.timeOfDay}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  touched={touched.timeOfDay!}
                  error={errors.timeOfDay}
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <label className="form-label">Comments: </label>
              <div className="form-group">
                <textarea
                  title="Enter your Comments"
                  name="comments"
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  aria-label="Comments"
                />
              </div>
            </div>
            <div className="col-12  col-md-12">
              <label className="form-label">Verification Code* </label>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${CAPTCHA_SITE_KEY}`}
                  onChange={onChangeCaptcha}
                />
                {touched.captcha && errors.captcha ? (
                  <div className="error_message">{`${errors?.captcha}`}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="button_wrap text-center div">
            <button
              type="button"
              className="btn gray_btn back-btn mx-1"
              onClick={onCloseModal}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn black_btn mx-2"
              onClick={() => handleSubmit()}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VipExperienceForm;

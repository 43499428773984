import React, { FC, useEffect, useState } from "react";
import SelectDropdown from "../../DropDown/SelectDropDown";
import "./swapfilter.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { quickFilterApi } from "../../../../services/films";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { IDropDown } from "../../../../models/common";
import moment from "moment";
import { clearFoodItemsAction, setTicketLessFb } from "../../../../store/foodAndBeverage/action";
import { getTicketBookingDetails, toggleTicketModal } from "../../../../store/ticketBooking/action";

interface ISwapProps {}

const SwapFilter: FC<ISwapProps> = () => {
  const { userDetails,isLoggedIn,currentCinema, countryId, flims } = useSelector(
    (state: RootState) => ({
      userDetails: state.authReducer.userDetails,
      isLoggedIn: state.authReducer.isLoggedIn,
      currentCinema: state.applicationReducer.currentCinema,
      countryId: state.applicationReducer.countryId,
      flims: state.ticketBookingReducer.films,
    })
  );

  const [movies, setMovies] = useState<IDropDown[]>([]);
  const [types, setTypes] = useState<IDropDown[]>([]);
  const [dates, setDates] = useState<IDropDown[]>([]);
  const [times, setTimes] = useState<IDropDown[]>([]);
  const [filter, setFilter] = useState<any>({
    movie: null,
    type: null,
    date: null,
    sessionId: null,
  });

  const dispatch = useDispatch();

  const getMovies = async (payload: any) => {
    const {
      data: { statusCode, data },
    } = await quickFilterApi({
      cinemaId: currentCinema?.slug,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      countryId,
    });

    if (statusCode === 200 && Array.isArray(data)) {
      setMovies(data.map((v: any) => ({ label: v.name, value: v.id })));
    }
  };

  const getFormat = async (payload: any) => {
    const {
      data: { statusCode, data },
    } = await quickFilterApi(payload);

    if (statusCode === 200 && Array.isArray(data)) {
      setTypes(data.map((v: any) => ({ label: v.name, value: v.name })));
    }
  };

  const getDate = async (payload: any) => {
    const {
      data: { statusCode, data },
    } = await quickFilterApi(payload);

    if (statusCode === 200 && Array.isArray(data)) {
      setDates(
        data
          .filter((v, i) => {
            return (
              data.findIndex(
                (s) =>
                  moment(s.name).format("YYYY-MM-DD") ===
                  moment(v.name).format("YYYY-MM-DD")
              ) === i &&
              moment(moment(v.name).format("YYYY-MM-DD")) >=
                moment(moment().format("YYYY-MM-DD"))
            );
          })
          .map((v: any) => ({
            label: moment(v.name).format("DD MMMM YYYY"),
            value: `${moment(v.name).format("YYYY-MM-DD")}`,
          }))
      );
    }
  };

  const getTime = async (payload: any) => {
    const {
      data: { statusCode, data },
    } = await quickFilterApi(payload);

    if (statusCode === 200 && Array.isArray(data)) {
      setTimes(
        data.map((v: any) => ({
          label: moment.parseZone(v.name).format("hh:mm A"),
          value: v.id,
        }))
      );
    }
  };

  useEffect(() => {
    if (flims) {
      setFilter({
        movie: flims.movieId,
        format: flims.sessionAttributes,
        date: moment.parseZone(flims.showDateTime).format("YYYY-MM-DD"),
        sessionId: flims.sessionId,
      });
      getMovies({
        cinemaId: currentCinema?.slug,
        status: API_REQUEST_TYPE.GET_FILM_DETAILS,
        countryId,
      });
      getFormat({
        cinemaId: currentCinema?.slug,
        selectedMovie: flims.movieId,
        status: API_REQUEST_TYPE.GET_FILM_DETAILS,
        countryId,
      });
      getDate({
        cinemaId: currentCinema?.slug,
        selectedMovie: flims.movieId,
        selectedAttribute: flims.sessionAttributes,
        status: API_REQUEST_TYPE.GET_FILM_DETAILS,
        countryId,
      });
      getTime({
        cinemaId: currentCinema?.slug,
        selectedMovie: flims.movieId,
        selectedAttribute: flims.sessionAttributes,
        date: moment.parseZone(flims.showDateTime).format("YYYY-MM-DD"),
        status: API_REQUEST_TYPE.GET_FILM_DETAILS,
        countryId,
      });
    }
  }, [flims]);

  const onChangeMovies = async (movie: string) => {
    setFilter({ ...filter, movie, format: null,
      date: null,
      sessionId: null });
    setTypes([]);
    setDates([]);
    setTimes([]);
    getFormat({
      cinemaId: currentCinema?.slug,
      selectedMovie: movie,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      countryId,
    });
  };

  const onChangeType = async (format: string) => {
    setFilter({ ...filter, format,
      date: null,
      sessionId: null, });
    setDates([]);
    setTimes([]);
    getDate({
      cinemaId: currentCinema?.slug,
      selectedMovie: filter.movie,
      selectedAttribute: format,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      countryId,
    });
  };

  const onChangeDate = async (date: string) => {
    setTimes([]);
    setFilter({ ...filter, 
      date,
      sessionId: null });
    getTime({
      cinemaId: currentCinema?.slug,
      selectedMovie: filter.movie,
      selectedAttribute: filter.format,
      selectedDate: date,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      countryId,
    });
  };

  const onChangeTime = (sessionId: string) => {
    setFilter({...filter, sessionId})
    dispatch(setTicketLessFb(false));
    dispatch(clearFoodItemsAction());
    dispatch(
      getTicketBookingDetails({
        cinemaId: currentCinema.slug,
        sessionId: sessionId,
        reservedSeating: "",
        screenType: "",
        countryId: countryId,
        covidFlag: currentCinema.covidFlag,
      })
    );
    // dispatch(toggleTicketModal(true));
  };

  return (
    <div className="seat-swap-filter">
      <h3 className="header">Want to swap seats?</h3>
      <p className="sub-heading">Confirm your new movie session:</p>
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <SelectDropdown
            field={"movies"}
            options={movies}
            value={filter.movie}
            onChange={onChangeMovies}
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <SelectDropdown
            field={"type"}
            options={types}
            value={filter.format}
            onChange={onChangeType}
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <SelectDropdown
            field={"dates"}
            options={dates}
            value={filter.date}
            onChange={onChangeDate}
          />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <SelectDropdown
            field={"times"}
            options={times}
            value={filter.sessionId}
            onChange={onChangeTime}
          />
        </div>
      </div>
    </div>
  );
};

export default SwapFilter;

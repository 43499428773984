import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import Modal from "../../../../UI/Modal";
import { getSavedCardWatcher, getSavedGiftCardsWatcher } from "../../../../../store/auth/action";
import { GiftCardDetail } from "../../../../../models/common";
import {
  getMakeDefaultCardApi,
  removedCard,
  removedGiftCardApi,
} from "../../../../../services/auth";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";

interface IListProps {
  setSelectedCard: React.Dispatch<React.SetStateAction<any>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setTabType: React.Dispatch<React.SetStateAction<string>>;
}

const List: FC<IListProps> = ({ setSelectedCard, setIsEdit, setTabType }) => {
  const { countryId, userDetails, savedGiftCards } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      savedGiftCards: state.authReducer.savedGiftCards
    })
  );

  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState<GiftCardDetail[]>([]);
  const [deleteCards, setDeleteCards] = useState<GiftCardDetail | null>();
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const getGiftCards = () => {
    dispatch(
      getSavedGiftCardsWatcher({
        memberRefId: userDetails?.result?.memberRefId!,
        countryId: countryId,
        emailId: userDetails?.email!,
      })
    );
  }

  useEffect(() => {
    getGiftCards();
  }, []);

  useEffect(() => {
    if (savedGiftCards) {
      setCardDetails(savedGiftCards);
    }
  }, [savedGiftCards]);


  const confirmationDeleteCard = (data: GiftCardDetail) => {
    setOpenPopup(true);
    setDeleteCards(data);
  };

  const deleteCard = async () => {
    await removedGiftCardApi({
      memberRefId: userDetails?.result?.memberRefId!,
      cardNumber: deleteCards?.GiftcardNumber!,
      countryId: `${countryId}`,
      emailId: userDetails?.email!,
    }).then((res: any) => {
      if (res?.data?.data && res?.data?.data.status && res?.data?.data.status === "true") {
        toast.success("Gift Card removed successfully");
        setOpenPopup(false);
        setDeleteCards(null);
        getGiftCards();
      }
    });
  };

  const cancelConfirmation = () => {
    setOpenPopup(false);
    setDeleteCards(null);
  };

  const onEdit = (card: any) => {
    setSelectedCard(card);
    setIsEdit(true);
    setTabType("add");
  };

  return (
    <div className="row tab-content ">
      <div className="col-md-12">
        {cardDetails && cardDetails.length > 0 ? (
          <div>
            <div className="cardlist_wrap">
              <ul>
                {cardDetails &&
                  cardDetails.map((card: any) => (
                    <li>
                      <div className="card_header">
                        <h4>Card ending in {card.NumberLastDigits}</h4>
                      </div>
                      <div className="card_details_wrap">
                        <div>
                          <div className="d-flex justify-content-between align-items-center py-3">
                            <div className="d-none d-md-block">
                              <div>Card Number</div>
                              <div>{card.GiftcardNumber}</div>
                            </div>
                            <div className="d-none d-md-block">
                              <div>Balance </div>
                              <div>${card.balance}</div>
                            </div>
                            <div className="d-block d-md-none">
                              <div>Card Number</div>
                              <div>{card.GiftcardNumber}</div>
                              <div className="mt-2">Balance </div>
                              <div>${card.balance}</div>
                            </div>
                            <div>
                              <QRCode style={{ height: "auto", width: "100px" }} value={card.GiftcardNumber} />
                            </div>
                          </div>
                          <div className="action_wrap justify-content-center mb-2">
                            <button
                              type="button"
                              className="set_default_btn"
                              onClick={() => onEdit(card)}
                            >
                              Edit
                            </button>
                            <button
                              className="del_btn"
                              type="button"
                              onClick={() => {
                                confirmationDeleteCard(card);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ) : (
          <div className="m-5 text-center">Gift Cards not yet added</div>
        )}
      </div>
      <Modal visible={openPopup} className="manage-cards-popup" showCloseBtn={false}>
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-body p-4">
                <div className="conformation_wrap">
                  <h4>Are you sure want to remove</h4>
                  <div className="conformation_action">
                    <button
                      className="del_btn"
                      type="button"
                      onClick={deleteCard}
                    >
                      Yes remove it
                    </button>
                    <button
                      className=""
                      type="button"
                      onClick={cancelConfirmation}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default List;

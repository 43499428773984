import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Modal from "../../../UI/Modal";
import {
  memberValidationSuccess,
  setUserDetails,
  showPhysicalCardModal,
} from "../../../../store/auth/action";
import {
  lostCardApi,
  memberValidationApi,
} from "../../../../services/auth";
import "./physicalCard.scss";
import { API_REQUEST_TYPE, URLS } from "../../../../constants/url";
import TextInput from "../../../UI/TextInput";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { toast } from "react-toastify";

interface IPhysicalCardProps {}

const PhysicalCard: FC<IPhysicalCardProps> = () => {
  const { userDetails, memberDetail, openPhysicalCardModal, countryId } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      memberDetail: state.authReducer.memberDetail,
      userDetails: state.authReducer.userDetails,
      openPhysicalCardModal: state.authReducer.openPhysicalCardModal,
    }));

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const onCloseModal = () => {
    dispatch(showPhysicalCardModal(false));
  };

  const { handleSubmit, values, errors, touched, resetForm,setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("lostCard"),
      initialValues: {
        lostCardNumber: "",
      },
      onSubmit: async(values) => {
        setErrorMessage("");
        const payload = {
          countryId: countryId,
          memberId: userDetails?.vista_memberid
            ? userDetails.vista_memberid
            : "",
          newCardNumber: values.lostCardNumber,
          requestType: API_REQUEST_TYPE.REPLACE_CARD,
          userSessionId: userDetails?.cardNumber ? userDetails.cardNumber : "",
        };

        const {
          data
        } = await lostCardApi(payload);
        if (data.data.Result !== 0) {
          setErrorMessage(data.data.ErrorDescription);
          return;
        }

        /* Member validation */
        const {
          response: {
            data: { data: memberdetails, statusCode: memberStatus },
          },
        } = (await memberValidationApi({
          countryId: countryId,
          MemberRefId: userDetails?.vista_memberid?userDetails.vista_memberid:data.data.member.MemberId,
          cardNumber: userDetails?.cardNumber?userDetails.cardNumber:data.data.member.CardNumber,
          UserSessionId: userDetails?.cardNumber?userDetails.cardNumber:data.data.member.CardNumber
        })) as any;

        dispatch(memberValidationSuccess(memberdetails));

        dispatch(
          setUserDetails({
            ...userDetails,
            email: memberdetails.LoyaltyMember.Email,
            firstName: memberdetails.LoyaltyMember.FirstName,
            lastName: memberdetails.LoyaltyMember.LastName,
            cardNumber: memberdetails.LoyaltyMember.CardNumber,
            vista_memberid: memberdetails.LoyaltyMember.MemberId,
          })
        );
        dispatch(showPhysicalCardModal(false));
          resetForm();
        toast.success("Card number updated successfuly!!!");
      },
    });

  return (
    <Modal visible={openPhysicalCardModal} className="physicalcard-popup" showCloseBtn={false}>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-md ">
          <div className="modal-content">
            <button
              type="button"
              className="close-modal"
              onClick={onCloseModal}
            >
              <img
                className="close_icon"
                src={URLS.CLOSE_ICON}
                alt="close_icon"
                title="close_icon"
              />
            </button>
            <div className="modal-header">
              <div className="title title_append reel_head">
                If you would like a physical Reel Club card, pick one up at your
                next visit to the cinema and enter the card number here.
              </div>
            </div>
            <div className="modal-body">
              <div>
                <div className="row">
                  <div className="col-md-12 is-invalid">
                    <label className="ml-2 hint">Current Card Number</label>
                    <div className="form-group pass_show">
                      <label className="form-label ml-2">
                        {" "}
                        {memberDetail?.LoyaltyMember?.CardNumber
                          ? memberDetail?.LoyaltyMember.CardNumber
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 is-invalid">
                    <label className="form-label">New Card Number</label>
                    <div className="form-group pass_show">
                      <TextInput
                        value={values.lostCardNumber}
                        field={"lostCardNumber"}
                        handleChange={handleChange}
                        touched={touched.lostCardNumber!}
                        error={errors.lostCardNumber}
                      />
                    </div>
                  </div>
                  {errorMessage ? (
                    <div className="err-message">{errorMessage}</div>
                  ) : null}
                  <div className="col-md-12">
                    <div className="button_wrap text-center div">
                      <button type="button" className="btn black_btn mx-1" onClick={()=>handleSubmit()}>
                        SUBMIT
                      </button>
                      <button
                        type="button"
                        className="btn gray_btn back-btn mx-2"
                        onClick={onCloseModal}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PhysicalCard;

import React, { FC, useEffect, useMemo } from "react";
import "./confirmation.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import Barcode from "react-barcode";
import { COUNTRY } from "../../../../../constants/app";
import { generateMailApi } from "../../../../../services/payment";
import { toast } from "react-toastify";
import { memberValidationWatcher } from "../../../../../store/auth/action";

interface IMilitaryConfirmationProps {
  onCloseModal: () => void;
}

const MembershipConfirmation: FC<IMilitaryConfirmationProps> = ({
  onCloseModal,
}) => {
  const {
    data,
    countryId,
    membershipPurchase,
    userDetails,
    ticketDetails,
    memberDetail,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    data: state.ticketBookingReducer.modal.data,
    membershipPurchase: state.authReducer.membershipPurchase,
    userDetails: state.authReducer.userDetails,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    memberDetail: state.authReducer.memberDetail,
  }));

  const dispatch = useDispatch();

  const sendTicketConfirmation = async () => {
    const payload = {
      id: userDetails?.UserSessionId! || membershipPurchase?.userSessionId!,
      countryId: countryId,
      templateName: "Loyalty Membership Confirmation",
      memberPackageType: membershipPurchase?.membershipType
    };
    const { status } = (await generateMailApi(payload)) as any;
    if (status) {
      toast.success("Confirmation sent to your e-mail successfully.");
    } else {
      toast.error(
        "Could not connect to services at the moment. Confirmation mail will be sent to your e-mail shortly."
      );
    }
  };

  useEffect(() => {
    sendTicketConfirmation();
    const payload = {
      MemberRefId: userDetails?.vista_memberid,
      UserSessionId: userDetails?.result?.cardNumber,
      cardNumber: userDetails?.result?.cardNumber,
      countryId: countryId
    };
    dispatch(
      memberValidationWatcher(
        payload,
        (res: any) => {},
        (err: any) => {}
      )
    );
  }, []);

  const total = useMemo(() => {
    return membershipPurchase &&
      membershipPurchase.loyaltyPackage &&
      membershipPurchase.loyaltyPackage.priceInCents
      ? membershipPurchase.loyaltyPackage.priceInCents / 100
      : 0;
  }, [membershipPurchase]);

  return (
    <div className="member-confirmation-wrapper">
      <div className="col-12">
        <div className="px-4">
        {membershipPurchase?.membershipType === 'renewal'? <p className="main-header">
            Thank you for renewing your membership to{" "}
            {membershipPurchase?.loyaltyPackage?.extendedDescription}!
          </p>
         :membershipPurchase?.membershipType === 'upgrade'? <p className="main-header">
            Thank you for choosing to{" "}
            {membershipPurchase?.loyaltyPackage?.extendedDescription}!
          </p>: <p className="main-header">
            Thank you for choosing to be an{" "}
            {membershipPurchase?.loyaltyPackage?.extendedDescription}!
          </p>}
          <div>
            <div className="ticket_detail_wrap d-flex justify-content-between align-items-center">
              <div className="ticket_detail">
                <div className="ticket_number">
                  <p className="label">PURCHASE REFERENCE</p>
                  <p className="number">{ticketDetails?.bookingId}</p>
                </div>
              </div>

              <div className="bar_code">
                <Barcode
                  width={2}
                  height={80}
                  displayValue={false}
                  value={
                    ticketDetails?.bookingId ? ticketDetails?.bookingId : ""
                  }
                />
                <div className="d-flex justify-content-center f-14">
                  {userDetails?.cardNumber ||
                    memberDetail?.LoyaltyMember?.CardNumber}
                </div>
              </div>
            </div>
            <div className="user-details">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="total">TOTAL PURCHASE</div>
                  <div className="total-amount">${total.toFixed(2)}</div>
                  {membershipPurchase &&
                  membershipPurchase.loyaltyPackage &&
                  membershipPurchase.loyaltyPackage?.description ? (
                    <p className="total">
                      1 x {membershipPurchase.loyaltyPackage?.description}: $
                      {(
                        membershipPurchase.loyaltyPackage.priceInCents / 100
                      ).toFixed(2)}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {membershipPurchase?.membershipType !== 'renewal' && membershipPurchase?.membershipType !== 'upgrade'?<div className="content">
          <p className="header">
            An activation link has been sent to: {userDetails?.email}
          </p>
          <p className="body">
            Activate your membership to start earning rewards!
          </p>
        </div>: null}

        <div className={`movie_footer_btn_el col-md-12 ml-auto px-5`}>
          <div className="movie_button_wrap justify-content-end">
            <button
              type="button"
              className="btn black_btn"
              onClick={onCloseModal}
            >
              RETURN TO HOME PAGE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipConfirmation;

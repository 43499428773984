import { FC } from "react";
import "./angelikaMembership.scss";
import { COUNTRY_IMAGE_CONFIG, URLS } from "../../../../constants/url";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IAangelikaMembershipProps {}

const AngelikaMembership: FC<IAangelikaMembershipProps> = () => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));

  return (
    <div className="afc_membership">
      <div
        className="afc_header"
        style={{
          backgroundImage: `url(${COUNTRY_IMAGE_CONFIG[countryId]}afc_bg.svg)`,
        }}
      >
        <img
          src={COUNTRY_IMAGE_CONFIG[countryId] + "logo_angelika_membership.svg"}
        />
      </div>
      <h2 className="d-block d-md-none mobile-heading">
        A Curated Rewards Program Made for Film Lovers
      </h2>
      <div className="afc_body">
        <div className="col-md-7 afc_content">
          <h2 className="d-none d-md-block">
            A Curated Rewards Program Made for Film Lovers
          </h2>
          <p>
            Join and receive <span className="free-access">FREE ACCESS</span> to
            Member’s Only Savings, Exclusive Experiences, and Extras curated for
            you.
          </p>
          <p className="earn">
            Earn 1 Point for every $1 you spend on our Website, Mobile App, or
            in Theaters!
          </p>
        </div>
        <div className="col-md-4">
          <img
            className="audiance_img"
            src={COUNTRY_IMAGE_CONFIG[countryId] + "afc_audience.svg"}
            alt=""
          />
        </div>
      </div>
      <div className="afc_body2">
        <h3>BECOME A MEMBER - SIGN UP FOR FREE NOW!</h3>
        <div >
        <a className="learn-more" href={URLS.ANGELIKA_FILM_MEMBERSHIP} target="_blank"><span>LEARN MORE</span></a>
        </div>
      </div>
    </div>
  );
};

export default AngelikaMembership;

import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../components/UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { URLS } from "../../../../constants/url";
import Member from "../../../../components/Auth/Member";
import SignUp from "../../../../components/Auth/SignUp";
import "./auth.scss";
import { showLogin } from "../../../../store/auth/action";
import { BRANDS, COUNTRY } from "../../../../constants/app";
import SignupBanner from "../../../../components/Auth/Membership/SignupBanner";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { useSearchParams } from "react-router-dom";

interface IAuthProps {}

const Auth: FC<IAuthProps> = () => {
  const { countryId, openModal, isLoggedIn, brandId, currentCinema } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
      openModal: state.authReducer.openModal,
      isLoggedIn: state.authReducer.isLoggedIn,
      currentCinema: state.applicationReducer.currentCinema,
    }));

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState("sign-in");
  const [showBanner, setShowBanner] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [isFromBlog, setIsFromBlog] = useState(false);
;
  useEffect(() => {
    const showSignupMsg = localStorage.getItem("showSignupMsg");
    if (showSignupMsg === null) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [openModal]);

  useEffect(() => {
    if (brandId === BRANDS.AU) {
      setTab("sign-in");
      setShowBanner(true);
    }
  }, [openModal, brandId]);

  useEffect(()=>{
    if(searchParams && searchParams.get('origin') === "angelikablog"){
      setTab("sign-up");
      setIsFromBlog(true);
    }
  },[searchParams])

  useEffect(() => {
    if (
      brandId === BRANDS.US &&
      location &&
      location.pathname &&
      location.pathname.includes("account/registration")
    ) {
      setTab("sign-up");
      setShowBanner(false);
    }
  }, [location, brandId]);

  const onCloseModal = () => {
    dispatch(showLogin(false));
    setShowBanner(true);
    if (
      brandId === BRANDS.US &&
      currentCinema?.slug &&
      location &&
      location.pathname &&
      location.pathname.includes("account/registration")
    ) {
      navigate(`${currentCinema?.alias}`);
    }
  };

  const changeTab = (type: string) => {
    setTab(type);
    setShowBanner(true);
  };

  const onSignup = () => {
    dispatch(showLogin(true));
    setShowBanner(false);
  };

  return (
    <Modal
      className="auth-modal"
      visible={openModal && !isLoggedIn}
      showCloseBtn={false}
    >
      <div className={`modal fade show ${countryId === COUNTRY.CON?'consolidated':countryId === COUNTRY.AFC?'angelika-film-center':''}`} style={{ display: "block" }}>
        <div
          className={`modal-dialog modal-dialog-centered ${
            tab === "sign-up" ? "modal-lg" : "modal-md"
          }`}
        >
          <div className="modal-content">
            <button
              type="button"
              className="close-modal"
              onClick={onCloseModal}
            >
              <img
                className="close_icon"
                src={URLS.CLOSE_ICON}
                alt="close_icon"
                title="close_icon"
              />
            </button>
            <div className="modal-header">
              <div className="modal-title">
                {tab === "sign-up" ? "User Sign Up" : "User Login"}
              </div>
            </div>
            <div className="modal-body">
              <div className="col-12 auth-wrapper">
                <div className="d-flex">
                  <div
                    onClick={() => changeTab("sign-in")}
                    className={`login-tab ${tab === "sign-in" ? "active" : ""}`}
                  >
                    {brandId === BRANDS.US ? "LOG IN" : "Sign-in"}
                  </div>
                  <div
                    onClick={() => changeTab("sign-up")}
                    className={`login-tab ${tab === "sign-up" ? "active" : ""}`}
                  >
                    {brandId === BRANDS.US ? "SIGN UP" : "Sign-up"}
                  </div>
                </div>
                <div>
                  <div className="my-4">
                    {tab === "sign-in" ? <Member fromPage="account" /> : null}
                  </div>
                  <div className="my-4">
                    {tab === "sign-up" ? (
                      showBanner && brandId !== BRANDS.US ? (
                        <SignupBanner
                          onSignup={onSignup}
                          onCancel={onCloseModal}
                        />
                      ) : (
                        <SignUp isFromBlog={isFromBlog} />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Auth;

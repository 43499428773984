import React, { FC, useEffect, useRef, useState } from "react";
import Slick from "react-slick";
import "./slider.scss";
import HtmlRender from "../HtmlRender";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { BRANDS, COUNTRY } from "../../../constants/app";
import { setNowShowingLoader, setQuickFilter } from "../../../store/films/action";

interface ISliderProps {
  data?: Array<any>;
  arrows?: boolean;
  fade?: boolean;
  isDotsNeeded?: boolean;
  className?: string;
}

const Slider: FC<ISliderProps> = ({
  data,
  arrows = true,
  fade = true,
  isDotsNeeded = false,
  ...props
}) => {
  const slideRef = useRef<any>();
  let settings = {
    className: "",
    centerMode: true,
    centerPadding: "0px",
    dots: isDotsNeeded,
    lazyload: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    // fade: fade,
    slidesToScroll: 1,
    initialSlide: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    // adaptiveHeight: true,
    arrows: arrows,
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };

  const { currentCinema, brandId, countryId, quickFilters } = useSelector((state: RootState) => ({
    currentCinema: state.applicationReducer.currentCinema,
    brandId: state.applicationReducer.brandId,
    countryId: state.applicationReducer.countryId,
    quickFilters: state.filmsReducer.quickFilters
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (slideRef) {
      slideRef.current?.slickGoTo(0);
    }
  }, [data]);

  const openLink = (slides: any) => {
    dispatch(setNowShowingLoader(false));
    dispatch(setQuickFilter({
      ...quickFilters,
      tempDate: quickFilters.date,
      format: null,
      movie: null,
      session: null,
      date: null,
    }))
    if (!slides.bannerlink) {
      return;
    }

    if (brandId === BRANDS.AU) {
      if (slides.bannerlink.includes("https://")) {
        window.open(slides.bannerlink, "_self");
      } else {
        navigate(slides.bannerlink);
      }
    } else {
      if (slides.urlLink) {
        if (slides.urlLink === "external") {
          window.open(slides.bannerlink, "_blank");
        } else {
          navigate(
            `/${currentCinema?.alias}/${slides.bannerlink.replace("//", "/")}`
          );
        }
      } else if (slides.bannerlink.includes("https://")) {
        window.open(slides.bannerlink, "_blank");
      } else {
        navigate(`/${currentCinema?.alias}/${slides.bannerlink}`);
      }
    }
  };

  const openButtonLink = (link: string)=>{
    dispatch(setNowShowingLoader(false));
    dispatch(setQuickFilter({
      ...quickFilters,
      tempDate: quickFilters.date,
      format: null,
      movie: null,
      session: null,
      date: null,
    }))
  if (link.includes("https://")) {
    window.open(link, "_blank");
  } else {
    navigate(`/${currentCinema?.alias}${link}`);
  }
  }

  return (
    <div className={`banner_wrap ${!isDotsNeeded?'no-arrow':''} ${countryId === COUNTRY.CON?'consolidated':countryId === COUNTRY.AFC?'angelika-flim-center':''}`}>
      <Slick {...settings} {...props} ref={slideRef}>
        { data?.map((slide, index) => {
          return (
            <div key={index} className={`banner-image ${slide?.textPosition}`}>
              {slide?.showBannerInfo === 0 ? (
                <a key={index} onClick={() => openLink(slide)}>
                  {slide?.imageUrl ? (
                    <img
                      className="d-none d-md-block"
                      src={slide.imageUrl}
                      alt={slide.title}
                      title={slide.title}
                    />
                  ) : null}
                  {slide?.mobileImageUrl ? (
                    <img
                      className="d-block d-md-none"
                      src={slide.mobileImageUrl}
                      alt={slide.title}
                      title={slide.title}
                    />
                  ) : null}
                </a>
              ) : (
                <>
                  {slide?.imageUrl ? (
                    <img
                      className="d-none d-md-block"
                      src={slide.imageUrl}
                      alt={slide.title}
                      title={slide.title}
                    />
                  ) : null}
                  {slide?.mobileImageUrl ? (
                    <img
                      className="d-block d-md-none"
                      src={slide.mobileImageUrl}
                      alt={slide.title}
                      title={slide.title}
                    />
                  ) : null}
                </>
              )}

              {slide?.showBannerInfo === 1 && brandId === BRANDS.AU ? (
                <div className="content_wrap">
                  <div className="col_left">
                    {slide.ratingIcon ? (
                      <div className="rating_icon">
                        <img
                          src={slide.ratingIcon}
                          alt={slide.ratingName}
                          title={slide.ratingName}
                        />
                      </div>
                    ) : null}
                    {slide.title ? (
                      <div
                        className={`title ${
                          slide.textColor === "black" ? "text_black" : ""
                        }`}
                      >
                        {slide.title}
                      </div>
                    ) : null}
                    {slide.description ? (
                      <div
                        className={`description ${
                          slide.textColor === "black" ? "text_black" : ""
                        }`}
                      >
                        <HtmlRender content={slide.description} />
                      </div>
                    ) : null}
                  </div>
                  <div className="col_right">
                    {slide.buttonText ? (
                      <div className="banner-btn">
                        <a href={slide.buttonLink} className="white_btn">
                          {slide.buttonText}
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : brandId === BRANDS.US &&
                ((slide?.buttonText && slide?.buttonLink) ||
                  slide?.description ||
                  slide?.title) ? (
                 
                <div className="content_wrap">
                
                  <div className="left">
                    {slide.title ? (
                      <div
                        className="title"
                      >
                        {slide.title}
                      </div>
                    ) : null}

                    {slide.description ? (
                      <div
                        className="description"
                      >
                        <HtmlRender content={slide.description} />
                      </div>
                    ) : null}
                  </div>
                  <div className="right">
                    {slide.buttonText ? (
                      <div className="banner-btn">
                        <a className="tkt-btn" onClick={()=>openButtonLink(slide.buttonLink)}>
                          {slide.buttonText}
                        </a>
                      </div>
                    ) : null}
                  </div>
                  </div>
                  
                
              ) : null}
            </div>
          );
        })}
      </Slick>
    </div>
  );
};

export default Slider;

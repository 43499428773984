import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ratingIndexApi } from "../../../../services/sideMenus";
import "./rating.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { IRatingIndex } from "../../../../models/sidemenu";
import { BRANDS, BRAND_ID, COUNTRY } from "../../../../constants/app";
import HelmetTitle from "../../../../components/Helmet";

interface IRatingIndexProps {}

const RatingIndex: FC<IRatingIndexProps> = () => {
  const { countryId, brandId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    brandId: state.applicationReducer.brandId
  }));

  const [rating, setRating] = useState<IRatingIndex[]>([]);

  useEffect(() => {
    if (countryId) {
      const getRating = async () => {
        const { data } = await ratingIndexApi(countryId);
        if (data && data.length > 0) {
          setRating(data);
        }
      };
      getRating();
    }
  }, [countryId]);

  return (
    <div className="privacy-policy">
      <HelmetTitle title={brandId === BRANDS.US?'MPAA Ratings':countryId === COUNTRY.ANG?'Australian Film Classifications':'Ratings Index'} description={brandId === BRANDS.US?'MPAA Ratings':countryId === COUNTRY.ANG?'Australian Film Classifications':'Ratings Index'}/>
      <div className="modal-header">
        <div className="title title_append">{brandId === BRANDS.US?'MPAA Ratings':countryId === COUNTRY.ANG?'Australian Film Classifications':'Ratings Index'}</div>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="rating_content_wrap">
              <div className="rating_list">
                {rating.map((content) => (
                  <div className="media">
                    <div className="rating_icon">
                      <img
                        className="align-self-start"
                        alt="Image"
                        src={content.image}
                      />
                    </div>
                    <div className="media-body ">
                      <h3 className="title">{content.code}</h3>
                      <HtmlRender content={content.description} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingIndex;

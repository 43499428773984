import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import TextInput from "../../../../components/UI/TextInput";
import SelectDropDown from "../../../../components/UI/DropDown/SelectDropDown";
import { CAPTCHA_SITE_KEY, COUNTRY, TimeOfDayList } from "../../../../constants/app";
import ReCAPTCHA from "react-google-recaptcha";
import Calendar from "../../../../components/UI/Calendar";
import { cinemaHireApi } from "../../../../services/sideMenus";
import { toast } from "react-toastify";
import moment from "moment";
import "./cinemaHireUs.scss";
import "react-calendar/dist/Calendar.css";
import HelmetTitle from "../../../../components/Helmet";

interface ICinemaHireProps {
  onCloseModal: () => void;
}

const CinemaHire: React.FC<ICinemaHireProps> = ({ onCloseModal }) => {
  const { countryId, currentCinema, cinemas } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      cinemas: state.applicationReducer.cinemas,
    })
  );

  const [cinemaList, setCinemaList] = useState([]);

  useEffect(() => {
    if (cinemas) {
      let tempCinemas: any = [];
      Object.keys(cinemas).forEach(function (key) {
        const arrList = cinemas[key];
        arrList.forEach((item: any) => {
          tempCinemas.push({
            label: `${item.name}`,
            value: `${item.name}`,
          });
        });
      });
      setCinemaList(tempCinemas);
    }
  }, [cinemas]);

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("cinemaHireUs"),
      initialValues: {
        name: "",
        email: "",
        contactNumber: "",
        cinema: "",
        eventName: "",
        eventDescription: "",
        date: "",
        timeOfDay: "",
        av: "",
        attendees: "",
        comments: "",
        captcha: "",
      },
      onSubmit: async (values) => {
        const payload = {
          name: values.name,
          email: values.email,
          contactNumber: values.contactNumber,
          eventName: values.eventName,
          eventDescription: values.eventDescription,
          date: values.date,
          timeOfDay: values.timeOfDay,
          bookingDate: moment(values.date, "MM-DD-YYYY").format("YYYY-MM-DD"),
          av: values.av,
          attendees: values.attendees,
          cinema: values.cinema,
          comments: values.comments,
          countryId: countryId,
          cinemaId: currentCinema?.slug
          // companyName: ""
        };

        const { data, status } = await cinemaHireApi(payload);
        if (status === 200) {
          toast.success("Details submitted successfully.");
          onCloseModal();
        } else {
          toast.error(
            "Could not connect to services at the moment. Please try again. Thanks."
          );
        }
      },
    });
  return (
    <div className={`cinema-hire-wrapper ${
      countryId === COUNTRY.CON
        ? "consolidated"
        : countryId === COUNTRY.AFC
        ? "angelika-flim-center"
        : ""
    }`} >
         <HelmetTitle title={countryId === COUNTRY.CON?'Private Theatre Rental':'Private Theater Rental'} description={countryId === COUNTRY.CON?'Private Theatre Rental':'Private Theater Rental'}/>
      <div className="modal-header">
        <div className="row">
          <div className="col-md-12 mt-4">
            <h3 className="title">{countryId === COUNTRY.CON?'Private Theatre Rental':'Private Theater Rental'}</h3>
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="form_page">
          <div className="row">
            <div className="col-6 col-md-6 ">
              <label className="form-label">Name*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.name}
                  field={"name"}
                  handleChange={handleChange}
                  touched={touched.name!}
                  error={errors.name}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Email address*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.email}
                  field={"email"}
                  handleChange={handleChange}
                  touched={touched.email!}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Phone number</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.contactNumber}
                  field={"contactNumber"}
                  handleChange={handleChange}
                  touched={touched.contactNumber!}
                  error={errors.contactNumber}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Cinema*</label>
              <SelectDropDown
                field={"cinema"}
                options={cinemaList}
                setFieldValue={setFieldValue}
                touched={touched.cinema!}
                error={errors.cinema}
                value={values.cinema}
              />
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Name of Event*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.eventName}
                  field={"eventName"}
                  handleChange={handleChange}
                  touched={touched.eventName!}
                  error={errors.eventName}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="form-label">Date*</label>
                  <div className=" form-group form-group-date-field">
                    <Calendar
                      format={'MM-DD-YYYY'}
                      minDate={new Date()}
                      field={"date"}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      touched={touched.date!}
                      error={errors.date}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Time of Day*</label>
                  <div className="form-group">
                    <SelectDropDown
                      tabIndex="9"
                      options={TimeOfDayList}
                      value={values.timeOfDay}
                      setFieldValue={setFieldValue}
                      onChange={handleChange}
                      field={"timeOfDay"}
                      touched={touched.timeOfDay!}
                      error={errors.timeOfDay}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <label className="form-label">Event Description*</label>
              <div className="form-group pass_show">
                <textarea
                  name="eventDescription"
                  id="eventDescription"
                  value={values.eventDescription}
                  onChange={handleChange}
                  aria-label="Event Description"
                />
                {touched.eventDescription && errors.eventDescription ? (
                  <div className="error_message">
                    Event Description is required
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">
                A/V Needs(Blu-ray, DVD, DCP, etc.)
              </label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.av}
                  field={"av"}
                  handleChange={handleChange}
                  touched={touched.av!}
                  error={errors.av}
                />
              </div>
            </div>
     
            <div className="col-6 col-md-6 ">
              <label className="form-label">
                Approximate number of attendees*
              </label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.attendees}
                  field={"attendees"}
                  handleChange={handleChange}
                  touched={touched.attendees!}
                  error={errors.attendees}
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <label className="form-label">Comments: </label>
              <div className="form-group">
                <textarea
                  title="Enter your Comments"
                  name="comments"
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  aria-label="Comments"
                />
              </div>
            </div>
            <div className="col-12  col-md-12">
              <label className="form-label">Verification Code* </label>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${CAPTCHA_SITE_KEY}`}
                  onChange={onChangeCaptcha}
                />
                {touched.captcha && errors.captcha ? (
                  <div className="error_message">{`${errors?.captcha}`}</div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              <div className="button_wrap text-center div">
                <button
                  type="button"
                  className="btn gray_btn back-btn mx-1"
                  onClick={onCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn black_btn mx-2"
                  onClick={() => handleSubmit()}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CinemaHire;

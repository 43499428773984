import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import "./footer.scss";
import {
  COUNTRY_IMAGE_CONFIG,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../constants/url";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/path";
import { shuffleArray } from "../../../utils/helper";
import { IMovieData } from "../../../models/cinema";
import { IFooterMenu } from "../../../models/sidemenu";
import { decode } from "html-entities";
import {
  COUNTRY,
  GOOGLE_MAP_LINK,
  LOCATION_MAPPER,
} from "../../../constants/app";
import { setQuickFilter } from "../../../store/films/action";
import { brandsApi } from "../../../services/sideMenus";
import { getComingTicketApi } from "../../../services/films";
import { formatComingSoon } from "../../../utils/formatter";

interface IFooterProps {}

const Footer: FC<IFooterProps> = () => {
  const { countryId, menu, currentCinema, quickFilters, comingSoonData } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      menu: state.applicationReducer.menu,
      currentCinema: state.applicationReducer.currentCinema,
      quickFilters: state.filmsReducer.quickFilters,
      comingSoonData: state.filmsReducer.comingSoon,
    }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [comingSoon, setComingSoon] = useState<any>();
  const [showLinks, setShowLinks] = useState<any>({});

  const getComingSoon = async () => {
    const { status, response } = (await getComingTicketApi({
      countryId: countryId,
      cinemaId: currentCinema?.cinemaId,
      flag: "",
      requestType: "",
    })) as any;

    if (status && response) {
      const formatedData = formatComingSoon(response.data.data);
      setComingSoon(formatedData);
    }
  };

  useEffect(() => {
    if (
      currentCinema &&
      comingSoonData.length === 0 &&
      pathname.split("/").length > 2 &&
      ![
        ROUTES.NOW_PLAYING,
        ROUTES.ADVANCE_TICKETS,
        ROUTES.COMING_SOON,
      ].includes(pathname.split("/").pop() || "")
    ) {
      getComingSoon();
    } else {
      setComingSoon(
        comingSoonData.filter((v: IMovieData) => v.showdates?.length === 0)
      );
    }
  }, [currentCinema, comingSoonData]);

  const quickLinks = useMemo(() => {
    return menu.footer
      .filter((v: IFooterMenu) => v.FooterTitle === "Quick Links")
      .sort((a: IFooterMenu, b: IFooterMenu) => a.orderBy - b.orderBy);
  }, [menu]);

  const joinUs = useMemo(() => {
    return menu.footer
      .filter((v: IFooterMenu) => v.FooterTitle === "Join Us")
      .sort((a: IFooterMenu, b: IFooterMenu) => a.orderBy - b.orderBy);
  }, [menu]);

  const getInTouch = useMemo(() => {
    return menu.footer
      .filter((v: IFooterMenu) => v.FooterTitle === "Get In Touch")
      .sort((a: IFooterMenu, b: IFooterMenu) => a.orderBy - b.orderBy);
  }, [menu]);

  const SocialMedia = () => {
    return (
      <ul className="footer-social-buttons footer-columns">
        {currentCinema?.facebook_url ? (
          <a href={currentCinema?.facebook_url} target="_blank">
            <img
              onMouseOver={(e) =>
                (e.currentTarget.src =
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "facebook_white.svg")
              }
              onMouseOut={(e) =>
                (e.currentTarget.src =
                  COUNTRY_IMAGE_CONFIG[countryId] + "facebook_red.svg")
              }
              src={COUNTRY_IMAGE_CONFIG[countryId] + "facebook_red.svg"}
            />
          </a>
        ) : null}
        {currentCinema?.instagramUrl ? (
          <a href={currentCinema?.instagramUrl} target="_blank">
            <img
              onMouseOver={(e) =>
                (e.currentTarget.src =
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "Instagram_white.svg")
              }
              onMouseOut={(e) =>
                (e.currentTarget.src =
                  COUNTRY_IMAGE_CONFIG[countryId] + "Instagram_red.svg")
              }
              src={COUNTRY_IMAGE_CONFIG[countryId] + "Instagram_red.svg"}
            />
          </a>
        ) : null}
        {currentCinema?.twitter_url ? (
          <a
            href={currentCinema?.twitter_url}
            target="_blank"
            // style={{ margin: "0 14px" }}
            className="twitter"
          >
            <img
              onMouseOver={(e) =>
                (e.currentTarget.src =
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                  "twitter-logo-new-white.svg")
              }
              onMouseOut={(e) =>
                (e.currentTarget.src =
                  COUNTRY_IMAGE_CONFIG[countryId] + "twitter-logo-new-red.svg")
              }
              src={COUNTRY_IMAGE_CONFIG[countryId] + "twitter-logo-new-red.svg"}
            />
          </a>
        ) : null}
        {currentCinema?.tiktok ? (
          <a href={currentCinema?.tiktok} target="_blank">
            <img
              onMouseOver={(e) =>
                (e.currentTarget.src =
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "tiktok_white.svg")
              }
              onMouseOut={(e) =>
                (e.currentTarget.src =
                  COUNTRY_IMAGE_CONFIG[countryId] + "tiktok_red.svg")
              }
              src={COUNTRY_IMAGE_CONFIG[countryId] + "tiktok_red.svg"}
            />
          </a>
        ) : null}
        {currentCinema?.youtubeUrl && currentCinema?.youtubeUrl.trim() ? (
          <a
            className="youtube"
            href={currentCinema?.youtubeUrl}
            target="_blank"
          >
            <img
              onMouseOver={(e) =>
                (e.currentTarget.src =
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "youtube_white.svg")
              }
              onMouseOut={(e) =>
                (e.currentTarget.src =
                  COUNTRY_IMAGE_CONFIG[countryId] + "youtube_red.svg")
              }
              src={COUNTRY_IMAGE_CONFIG[countryId] + "youtube_red.svg"}
            />
          </a>
        ) : null}
        {currentCinema?.email && countryId !== COUNTRY.CON ? (
          <a
            className="email"
            href={`mailto:${currentCinema?.email}`}
            target="_blank"
          >
            <img
              onMouseOver={(e) =>
                (e.currentTarget.src =
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "mail_white.svg")
              }
              onMouseOut={(e) =>
                (e.currentTarget.src =
                  COUNTRY_IMAGE_CONFIG[countryId] + "mail_red.svg")
              }
              src={COUNTRY_IMAGE_CONFIG[countryId] + "mail_red.svg"}
            />
          </a>
        ) : null}
      </ul>
    );
  };

  const Address = () => {
    return (
      <div
        className={`d-flex d-lg-block flex-column address_wrappper_${countryId}`}
      >
        <h3 className="heading border-bottom-red">THEATER INFO</h3>
        <div className="py-2">
          <a
            className={`text-uppercase pointer-default ${
              countryId === COUNTRY.CON || countryId === COUNTRY.AFC
                ? "f-18"
                : "f-16"
            }`}
          >
            {currentCinema?.cinemaName}
          </a>
        </div>
        <p className="d-flex py-2">
          {countryId ? (
            <img
              src={COUNTRY_IMAGE_CONFIG[countryId] + "location.svg"}
              loading="lazy"
            />
          ) : null}
          {countryId &&
          currentCinema &&
          GOOGLE_MAP_LINK[countryId][currentCinema?.alias] ? (
            <a
              href={GOOGLE_MAP_LINK[countryId][currentCinema?.alias]}
              target="_blank"
            >
              <div
                className={`mx-2 ${
                  countryId === COUNTRY.CON || countryId === COUNTRY.AFC
                    ? "f-16 bold-450"
                    : "f-14"
                }`}
              >
                {currentCinema?.address}
                <br />
                {currentCinema?.city}, {currentCinema?.state}{" "}
                {currentCinema?.zip}
              </div>
            </a>
          ) : null}
        </p>
        <p
          className={`py-2 ${
            countryId === COUNTRY.CON || countryId === COUNTRY.AFC
              ? "f-16 bold-450"
              : "f-14"
          }`}
        >
          {countryId ? (
            <img
              src={COUNTRY_IMAGE_CONFIG[countryId] + "telephone_icon.svg"}
              loading="lazy"
            />
          ) : null}
          <span className="mx-2"><a href={`tel:${currentCinema?.phone}`}>{currentCinema?.phone}</a></span>
        </p>
      </div>
    );
  };

  const mapUrl = useMemo(() => {
    return `https://www.google.com/maps/embed/v1/place?q=${encodeURI(
      `${countryId === COUNTRY.US ? "Reading Cinemas at" : ""} ${
        currentCinema?.alias === "mosaic" ? "Angelika Film Center Café at Mosaic" : currentCinema?.cinemaName
      }`
    )}&key=AIzaSyAmTNNNrbU_UvGbRZwSmSnGxZHNkTcijlk&zoom=14`;
  }, [currentCinema]);

  const resetFilter = () => {
    dispatch(
      setQuickFilter({
        ...quickFilters,
        format: null,
        movie: null,
        session: null,
        date: null,
      })
    );
  };

  const MobileView = () => {
    return (
      <div
        className={`horizontal-footer-section ${
          countryId === COUNTRY.CON || countryId === COUNTRY.AFC
            ? "font-xl"
            : ""
        }`}
      >
        <div
          className="footer-columns footer-columns-large w-50 pr-2"
          id="footer-middle-section"
        >
          <div>
            <Address />
          </div>
          {/* <div className="map">VIEW GOOGLE MAP</div> */}
          <div className="map-section-mobile my-3">
            <iframe
              loading="lazy"
              className="map-area"
              referrerPolicy="no-referrer-when-downgrade"
              src={mapUrl}
            ></iframe>
          </div>
        </div>
        <div
          className="footer-columns footer-columns-large"
          id="footer-middle-section"
        >
          <h3 className="heading d-flex justify-content-start">FOLLOW US</h3>
          <SocialMedia />
          <h3 className="heading d-flex justify-content-start">GET IN TOUCH</h3>
          <ul
            className="footer-column-menu d-flex align-items-start flex-column"
            role="menu"
          >
            {getInTouch.map((v: IFooterMenu, index: number) => (
              <li
                key={index}
                className="footer-column-menu-item"
                role="menuitem"
              >
                {v.Url.includes("http") ? (
                  <a href={v.Url} target="_blank">
                    {decode(v.LinkTitle)}
                  </a>
                ) : (
                  <Link to={`/${currentCinema?.alias}${v.Url}`}>
                    {decode(v.LinkTitle)}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          {/* <div className="readings-icon">
            <a>
              <img
                src={
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                  "readingCinemas_us_logo_white.svg"
                }
              />
            </a>
          </div> */}
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div
        className={`horizontal-footer-section ${
          countryId === COUNTRY.CON || countryId === COUNTRY.AFC
            ? "font-xl"
            : ""
        }`}
      >
        <div
          className="footer-columns footer-a footer-columns-large d-none d-lg-block p-r-2"
          id="footer-middle-section"
        >
          <h3 className="heading">Quick Links</h3>
          <ul className="footer-column-menu" role="menu">
            {quickLinks.map((v: IFooterMenu, index: number) => (
              <li
                key={index}
                className="footer-column-menu-item"
                role="menuitem"
              >
                {v.Url.includes("http") ? (
                  <a href={v.Url} target="_blank">
                    {decode(v.LinkTitle)}
                  </a>
                ) : (
                  <Link to={`/${currentCinema?.alias}${v.Url}`}>
                    {decode(v.LinkTitle).toLowerCase() === "titan luxe" ? (
                      <img
                        style={{ width: 150 }}
                        src={
                          IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                          "titan_luxe_us.png"
                        }
                      />
                    ) : (
                      decode(v.LinkTitle)
                    )}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="footer-columns footer-b footer-columns-large d-none d-lg-block p-r-2"
          id="footer-middle-section"
        >
          <h3 className="heading">JOIN US</h3>
          <ul className="footer-column-menu" role="menu">
            {joinUs.map((v: IFooterMenu, index: number) => (
              <li
                key={index}
                className="footer-column-menu-item"
                role="menuitem"
              >
                {v.Url.includes("http") ? (
                  <a href={v.Url} target="_blank">
                    {decode(v.LinkTitle)}
                  </a>
                ) : (
                  <Link to={`/${currentCinema?.alias}${v.Url}`}>
                    {decode(v.LinkTitle)}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <h3 className="heading">GET IN TOUCH</h3>
          <ul className="footer-column-menu">
            {getInTouch.map((v: IFooterMenu, index: number) => (
              <li
                key={index}
                className="footer-column-menu-item"
                role="menuitem"
              >
                {v.Url.includes("http") ? (
                  <a href={v.Url} target="_blank">
                    {decode(v.LinkTitle)}
                  </a>
                ) : (
                  <Link to={`/${currentCinema?.alias}${v.Url}`}>
                    {decode(v.LinkTitle)}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="footer-columns footer-c footer-columns-large p-r-2"
          id="footer-middle-section"
        >
          {comingSoon && comingSoon.length > 0 ? (
            <>
              <h3 className="heading d-none d-lg-block coming-soon">
                COMING SOON
              </h3>
              <ul className="footer-column-menu d-none d-lg-block" role="menu">
                {shuffleArray(comingSoon).map((v: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className="footer-column-menu-item"
                      role="menuitem"
                      onClick={resetFilter}
                    >
                      <Link
                        to={`/${
                          LOCATION_MAPPER[countryId][currentCinema?.slug]
                            ? LOCATION_MAPPER[countryId][currentCinema?.slug]
                                .alias
                            : currentCinema?.slug
                        }/movies/details/${v.movieSlug}`}
                      >
                        {v.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null}
          <h3 className="heading">FOLLOW US</h3>
          <SocialMedia />

          {/* <div className="readings-icon">
            <a href="#">
              <img
                src={
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                  "readingCinemas_us_logo_white.svg"
                }
              />
            </a>
          </div> */}
        </div>
        <div
          className="footer-columns footer-d footer-columns-large p-r-2"
          id="footer-middle-section"
        >
          {/* <h3 className="heading d-none d-lg-block">FIND US NEAR YOU</h3> */}
          <Address />
          <div className="my-3">
            <iframe
              loading="lazy"
              className="map-area"
              referrerPolicy="no-referrer-when-downgrade"
              src={mapUrl}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (countryId) {
      const getPolicy = async () => {
        brandsApi({ countryId: countryId }).then(({ data }: any) => {
          if (data && data.length > 0) {
            const datas = data[0];

            if (datas && datas.data) {
              setShowLinks(
                datas.data.reduce((acc: any, v: any) => {
                  acc[v.title] = true;
                  return acc;
                }, {})
              );
            }
          }
        });
      };
      getPolicy();
    }
  }, [countryId]);

  return (
    <footer className="footer-us">
      <div className="d-block d-lg-none">
        <MobileView />
      </div>
      <div className="d-none d-lg-block">
        <DesktopView />
      </div>
      <div className="divider">
        <hr />
      </div>
      <section className="entertainment-brands">
        <h3>OUR OTHER ENTERTAINMENT BRANDS</h3>
        <div className="entertainment-icons">
          {countryId !== COUNTRY.AFC ? (
            <a href={URLS.ANGELIKA_FLIM_CENTER_US} target="_blank">
              <img
                className="angelikaFlimCenter"
                src={
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                  "angelika_stacked_white.svg"
                }
              />
            </a>
          ) : null}
          {countryId !== COUNTRY.US ? (
            <a href={URLS.READING_CINEMAS_US} target="_blank">
              <img
                className="readingCinemas"
                src={
                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                  "reading_cinemas_logo_footer.svg"
                }
              />
            </a>
          ) : null}
          {countryId !== COUNTRY.CON ? (
            <a href={URLS.CONSOLIDATED_THEATERS_US} target="_blank">
              <img
                className="consolidated"
                src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "logo_ctr_white.svg"}
              />
            </a>
          ) : null}
          <a href={URLS.ANGELIKA_ANY_WHERE_US} target="_blank">
            <img
              className="angelikaAnywhere"
              src={
                IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                "angelika_anywhere_stacked_white.svg"
              }
            />
          </a>
          <a href={URLS.LIBERTY_THEATRE_US} target="_blank">
            <img
              className="libertyTheatre"
              src={
                IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                "liberty_theatres_logo_new.svg"
              }
            />
          </a>
        </div>
      </section>
      <div className="divider">
        <hr />
      </div>
      <section className="footer-bottom">
        <div className="terms">
          <p className="d-none d-lg-block">
            <Link to={`/${currentCinema?.alias}/${ROUTES.RATING}`}>
              MPAA Ratings
            </Link>
          </p>
          {showLinks["Terms of Use"] ? (
            <p>
              <Link to={`/${currentCinema?.alias}/${ROUTES.TERMS}`}>
                Terms of Use
              </Link>
            </p>
          ) : null}
          {showLinks["Privacy Policy"] ? (
            <p>
              <Link to={`/${currentCinema?.alias}/${ROUTES.PRIVACY}`}>
                Privacy Policy
              </Link>
            </p>
          ) : null}
          {showLinks["Your US State Privacy Rights"] ? (
            <p>
              <Link
                to={`/${currentCinema?.alias}/${ROUTES.PRIVACY}/${ROUTES.PRIVACY_POLICY_US_STATE}`}
              >
                Your US State Privacy Rights
              </Link>
            </p>
          ) : null}
          {showLinks["Children's Online Privacy Policy"] ? (
            <p className="d-none d-lg-block">
              <Link
                to={`/${currentCinema?.alias}/${ROUTES.PRIVACY}/${ROUTES.PRIVACY_POLICY_CHILDREN}`}
              >
                Children's Online Privacy Policy
              </Link>
            </p>
          ) : null}
          {showLinks["Interest-Based Ads"] ? (
            <p>
              <Link
                to={`/${currentCinema?.alias}/${ROUTES.PRIVACY}/${ROUTES.INVESTORS_BASED_ADS}`}
              >
                Interest-Based Ads
              </Link>
            </p>
          ) : null}
          <p className="d-block d-lg-none">
            <Link to={URLS.INVESTOR} target="_blank">
              Investors
            </Link>
          </p>
          {showLinks["Do Not Sell or Share My Personal Information"] ? (
            <p>
              <Link
                to={`/${currentCinema?.alias}/${ROUTES.PRIVACY}/${ROUTES.PERSONAL_INFORMATION}`}
              >
                Do Not Sell or Share My Personal Information
              </Link>
            </p>
          ) : null}
        </div>
        <div className="copyright">
          <p>Reading International, Inc.</p>
          <p className="d-none d-lg-block">
            <Link to={URLS.INVESTOR} target="_blank">
              Investors
            </Link>
          </p>
          {countryId === COUNTRY.CON ? (
            <p>
              Copyright ©2023 Consolidated Theaers IP, LLC All rights reserved
            </p>
          ) : countryId === COUNTRY.AFC ? (
            <p>
              Copyright ©2024 Angelika Film Center IP, LLC All rights reserved
            </p>
          ) : (
            <p>Copyright ©2023 Reading IP, LLC All rights reserved</p>
          )}
        </div>
      </section>
    </footer>
  );
};

export default Footer;

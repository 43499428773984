import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { COUNTRY, COUNTRY_CODE, CURRENCY } from "../../../../constants/app";
import { concessionPriceCalculation } from "../../../../utils/tickets";
import { URLS } from "../../../../constants/url";
import {
  fatzebraApplePaySessionApi,
  verifyFatzebraPaymentTokenApi,
} from "../../../../services/payment";
import { PAYMENT_TYPE } from "../../../../models/payment";
import { calTotal } from "../../../../utils/giftshop";

interface IApplePayProps {
  processPayment: any;
  isValid: any;
  onError: any;
  checkValid: () => void;
  page: string;
}

const ApplePay: FC<IApplePayProps> = ({
  isValid,
  processPayment,
  onError,
  checkValid,
  page,
}) => {
  const {
    countryId,
    orderCartData,
    ticketDetails,
    userDetails,
    cart,
    orderResponse,
    memberDetail,
    membershipPurchase,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId as COUNTRY,
    orderCartData: state.foodBeverageReducer.orderCartData,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    userDetails: state.authReducer.userDetails,
    cart: state.giftCardReducer.cart,
    orderResponse: state.giftCardReducer.orderResponse,
    memberDetail: state.authReducer.memberDetail,
    membershipPurchase: state.authReducer.membershipPurchase,
  }));

  const [showButton, setShowButton] = useState(false);

  const concessionTotal = useMemo(() => {
    return concessionPriceCalculation(orderCartData);
  }, [orderCartData]);

  const total = useMemo(() => {
    if (page === "ticketing") {
      return (
        (ticketDetails.total ? ticketDetails.total : 0) + concessionTotal
      ).toFixed(2);
    } else if (page === "giftcard") {
      return calTotal(cart, countryId).total.toFixed(2);
    } else if (
      membershipPurchase &&
      membershipPurchase.loyaltyPackage &&
      page === "membership"
    ) {
      return (membershipPurchase.loyaltyPackage.priceInCents / 100).toFixed(2);
    } else {
      return 0;
    }
  }, [countryId, ticketDetails, concessionTotal, cart, membershipPurchase]);


  useEffect(() => {
    setShowButton(isValid);
  }, [isValid]);

  const onApplePayClick = () => {
    const request = {
      countryCode: COUNTRY_CODE[countryId],
      currencyCode: CURRENCY[countryId],
      supportedNetworks: ["visa", "masterCard"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label:
          countryId === COUNTRY.STA
            ? "State Cinema"
            : countryId === COUNTRY.ANG
            ? "Angelika Film Centre"
            : "Reading Cinemas",
        type: "final",
        amount: total,
      },
    };
    const session = new (window as any).ApplePaySession(3, request);

    session.onvalidatemerchant = async (event: any) => {
      await fatzebraApplePaySessionApi({
        domain_name: window.location.host,
        validationURL: event.validationURL,
      })
        .then((merchantSession: any) => {
          session.completeMerchantValidation(merchantSession);
        })
        .catch((error: any) => console.error(error));
    };

    session.onpaymentauthorized = (event: any) => {
      const result = {
        status: (window as any).ApplePaySession.STATUS_SUCCESS,
      };
      session.completePayment(result);
      const payload = {
        countryId: countryId,
        amount: +total * 100,
        reference: page === "ticketing"
        ? `invoice${ticketDetails?.userSessionId!}`
        : page === "giftcard"
        ? `invoice${orderResponse?.userSessionId!}`
        : page === "membership"
        ? (userDetails?.UserSessionId?`invoice${userDetails?.UserSessionId}`:`invoice${membershipPurchase?.userSessionId}`)
        : "",
        wallet: {
          type: "APPLEPAYWEB",
          token: event.payment.token,
        },
        email: userDetails?.email || "",
      };

      verifyFatzebraPaymentTokenApi(payload)
        .then((res) => {
          if (res.data.status === true) {
            let [Year, Month, Date] = res.data.data.card_expiry.split("-");
            let cardDetails = {
              expirymonth: Month + "",
              expiryyear: Year + "",
              cardNumber: res.data.data.card_number.substr(
                res.data.data.card_number.length - 4
              ),
              expiryDate: Month + "/" + Year.substring(2, Year.length),
              cardHolderName: "",
              cvv: "",
            };
            processPayment(
              {
                cardDetails: cardDetails,
                paymentIntentId: res.data.data.transaction_id,
              },
              PAYMENT_TYPE.GOOGLE_PAY
            );
          } else {
            onError();
          }
        })
        .catch(() => {
          onError();
        });
    };

    session.oncancel = (event: any) => {};

    session.begin();
  };

  return !showButton ? (
    <button
      type="button"
      className="btn black_btn"
      style={{ borderRadius: 5 }}
      onClick={checkValid}
    >
      <img
        style={{ width: 67, marginTop: 2 }}
        src={URLS.APPLE_PAY_ICON}
        alt=""
      />
    </button>
  ) : (
    <div
      className="apple-pay-button apple-pay-button-black ml-2"
      onClick={onApplePayClick}
    ></div>
  );
};

export default ApplePay;

import { call, put, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "./type";
import { AnyAction } from "@reduxjs/toolkit";
import { forgotPasswordApi, getSavedCardApi, getSavedGiftcardApi, memberValidationApi, signInApi, signUpApi } from "../../services/auth";
import { forgotPasswordError, forgotPasswordSuccess, getSavedCardError, getSavedCardSuccess, getSavedGiftCardsError, getSavedGiftCardsSuccess, memberValidationError, memberValidationSuccess, signInError, signInSuccess, signUpError, signUpSuccess } from "./action";
import { IAction } from "../../models/common";

function* signInAsGuest(action: AnyAction) {
    const { payload, resolve, reject } = action;

    const { status, response, error } = yield call(signUpApi, payload);
    if (status && response) {
        const { data } = response;
        if (data.data && (data.data["message"] || data.data["err"])) {
            yield put(signUpError(error));
            return reject(
                data.data["message"] ? data.data["message"] : data.data["err"]
            );
        }
        if (data.statusCode != 200) {
            if (data.statusCode == 403) {
                reject(
                    "There appears to have been a problem connecting Payment Gateway. Please try again later."
                );
            }
            reject("Error occured, please try again later");
            yield put(
                signUpError({ error: "Error occured, please try again later" })
            );
            return;
        }

        resolve(data);
        yield put(signUpSuccess(data));
    } else {
        yield put(signUpError(error));
    }
}


function* signIn(action: any) {
    const {
        payload, resolve, reject
    } = action;

    const { status, response, error } = yield call(signInApi, payload);

    if (status && response) {
        const { data: { data } } = response;
        if (data?.errorMessage) {
            if (data?.errorMessage.name) {
                reject(data?.errorMessage.name)
                yield put(signInError(data?.errorMessage.name));
            } else {
                reject(data?.errorMessage)
                yield put(signInError(data?.errorMessage));
            }
        } else {
            resolve(response.data.data)
            yield put(signInSuccess(response.data.data));
        }
    } else {
        yield put(signInError(error));
    }
}

function* memberValidation(action: any) {
    const {
        payload, resolve, reject
    } = action;
    const { status, response, error } = yield call(memberValidationApi, payload);

    if (status && response && response.data.data) {
        if (resolve) {
            resolve(response.data?.data);
        }
        yield put(memberValidationSuccess(response.data?.data));
    } else {
        if (reject) {
            reject(error)
        }
        yield put(memberValidationError('Server Error'));
    }
}

function* forgotPassword(action: any) {
    const { payload, resolve, reject } = action;
    const { status, response, error } = yield call(forgotPasswordApi, payload);

    if (status && response) {
        const { data: { data: { message, status: apiStatus, errorMessage } } } = response;

        if (apiStatus) {
            resolve(message);
            yield put(forgotPasswordSuccess(message));
        } else {
            reject(errorMessage);
            yield put(forgotPasswordError(errorMessage));
        }
    } else {
        yield put(forgotPasswordError(error));
    }
}

function* getSavedcardDetails(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getSavedCardApi, payload);
    if (status) {
        const { data: { data: { result, status }, statusCode } } = response;
       
        if (statusCode === 200 && status) {
            yield put(getSavedCardSuccess(result))
        }
    } else {
        yield put(getSavedCardError(error))
    }
}

function* getSavedGiftCard(action: IAction) {
    const { payload } = action;
    const { status, response, error } = yield call(getSavedGiftcardApi, payload);
    if (status) {
        const { data: { data: { result, status }, statusCode } } = response;
        if (statusCode === 200 && status) {
            yield put(getSavedGiftCardsSuccess(result))
        }
    } else {
        yield put(getSavedGiftCardsError(error))
    }
}


export function* authWatcher() {
    yield takeLatest(ACTIONS.SIGN_UP_AS_GUEST, signInAsGuest)
    yield takeLatest(ACTIONS.SIGN_IN_WATCHER, signIn);
    yield takeLatest(ACTIONS.MEMBER_VALIDATION_WATCHER, memberValidation);
    yield takeLatest(ACTIONS.FORGOT_PASSWORD_WATCHER, forgotPassword);
    yield takeLatest(ACTIONS.GET_SAVED_CARDS_WATCHER, getSavedcardDetails);
    yield takeLatest(ACTIONS.GET_SAVED_GIFT_CARDS_WATCHER, getSavedGiftCard);
}
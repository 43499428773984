import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    useMemo,
    useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./styles.scss";
import { concessionPriceCalculation } from "../../../../utils/tickets";
import CreditCard from "../CreditCard";
import { ILoyaltyPoints } from "../../../../models/payment";

interface IPointPayProps {
    fromPage?: string;
    loyaltyPoints: ILoyaltyPoints
}

interface IPointPayRef {
    submit: () => any;
}

const PayWithPoints = forwardRef(
    (props: IPointPayProps, ref: React.Ref<IPointPayRef>) => {
        const {
            countryId,
            ticketDetails,
            orderCartData,
            userDetails,
            isLoggedIn,
            memberDetail,
        } = useSelector((state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            ticketDetails: state.ticketBookingReducer.ticketDetails,
            orderCartData: state.foodBeverageReducer.orderCartData,
            userDetails: state.authReducer.userDetails,
            isLoggedIn: state.authReducer.isLoggedIn,
            memberDetail: state.authReducer.memberDetail,
        }));


        const concessionTotal = useMemo(() => {
            return concessionPriceCalculation(orderCartData);
        }, [orderCartData]);

        const total = useMemo(() => {
            return (
                (ticketDetails?.total ? ticketDetails.total : 0) + concessionTotal
            ).toFixed(2);
        }, [ticketDetails, concessionTotal]);

        const points = useMemo(() => {
            return props.loyaltyPoints && props.loyaltyPoints.pointsRemaining ? props.loyaltyPoints.pointsRemaining : 0
        }, [props.loyaltyPoints])

        const pointAmount = useMemo(() => {
            return props.loyaltyPoints && props.loyaltyPoints.pointsRemainingInCents ? (props.loyaltyPoints.pointsRemainingInCents / 100) : 0
        }, [props.loyaltyPoints])

        const balanceAmount = useMemo(() => {
            return total > pointAmount ? total - pointAmount : 0
        }, [total, pointAmount])


        const dispatch = useDispatch();
        const [cardAmount, setCardAmount] = useState(0);
        const [enableCreditCard, setEnableCreditCard] = useState(false);
        const cardRef = useRef<IPointPayRef>(null);

        useImperativeHandle(ref, () => ({
            submit: () => {
                if (enableCreditCard) {
                    const cardDetails: any = cardRef?.current?.submit();
                    if (!cardDetails) {
                        return null;
                    }
                    const { card, action, saveCard } = cardDetails;
                    return {
                        cardDetails: card,
                        action,
                        saveCard,
                        enableCreditCard: enableCreditCard,
                        balance: cardAmount,
                        total: total,
                        payableAmount: props.loyaltyPoints.pointsRemainingInCents/100
                    };
                } else {
                    return {
                        enableCreditCard: enableCreditCard,
                        total: total,
                        payableAmount: total
                    }
                }
            },
        }));


        useEffect(() => {
            if (balanceAmount > 0) {
                setEnableCreditCard(true);
                setCardAmount(balanceAmount);
            }
            else {
                setEnableCreditCard(false);
                setCardAmount(0);
            }
        }, [total, balanceAmount]);


        return (
            <div className="pay-with-points-wrapper">
                <div className="rewards">
                    <h5>Pay with Angelika Membership Points</h5>
                    <div className="pay-points">
                        <div className="points-value">
                        POINTS AVAILABLE
                        <span>{points}</span>
                        </div>
                        <div className="rewards-value">
                        Reward
                        <span>${pointAmount.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
                {balanceAmount > 0 ? <div className="info_message">
                    <span> Balance Payment Amount: $
                        {balanceAmount.toFixed(2)}
                    </span>
                </div> : null}
                {enableCreditCard ? (
                    <div>
                        <CreditCard ref={cardRef} fromPage={props.fromPage} />
                    </div>
                ) : null}
            </div>
        );
    }
);

export default PayWithPoints;

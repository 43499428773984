import React,{ FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ROUTES } from "../../../constants/path";
import { URLS } from "../../../constants/url";
import { COUNTRY } from "../../../constants/app";
import { Link } from "react-router-dom";
import "./navbar.scss";

interface INavbarProps {}

const Navbar: FC<INavbarProps> = () => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));

  const [config, setConfig] = useState<{
    show: boolean;
    class?: string;
    routing?: string;
    desktopImg?: string;
    mobileImg?: string;
    altText?: string;
  }>({
    show: false,
  });

  useEffect(() => {
    switch (countryId) {
      case COUNTRY.STA:
        setConfig({
          show: true,
          class: "sta_bg",
          routing: `/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`,
          desktopImg: URLS.STATE_NAV_LOGO_DESKTOP,
          mobileImg: URLS.STATE_NAV_LOGO_DESKTOP,
          altText: "State Cinema",
        });
        break;
      case COUNTRY.ANG:
        setConfig({
          show: true,
          class: "ang_bg",
          routing: `/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`,
          desktopImg: URLS.ANGELIKA_NAV_LOGO_DESKTOP,
          mobileImg: URLS.ANGELIKA_NAV_LOGO_MOBILE,
          altText: "Angelika",
        });
        break;
      default:
        setConfig({
          show: false,
        });
        break;
    }
  }, [countryId]);

  return config.show ? (
    <div className={`ang_heading ${config.class}`}>
      <div className="img-bg">
        <div className="img-bg">
          <Link to={`${config.routing}`}>
            <img
              src={config.desktopImg}
              alt={config.altText}
              className="desk-img"
            />
            <img
              src={config.mobileImg}
              alt={config.altText}
              className="mob-img"
            />
          </Link>
        </div>
      </div>
    </div>
  ) : null;
};

export default Navbar;

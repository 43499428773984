import React, { FC, useEffect, useRef, useState } from "react";
import Select from "react-select";

import "./location.scss";
import { BRANDS, COUNTRY } from "../../../../constants/app";
import {
  COUNTRY_IMAGE_CONFIG,
  IMAGE_URL_CONFIG,
} from "../../../../constants/url";
import {
  formatAngelikaFilmCinemas,
  formatConsolidatedCinemas,
} from "../../../../utils/formatter";
import { Link } from "react-router-dom";

interface ILocationDropDownProps {
  value?: any;
  options: Array<{ label: string; value: any }>;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: string) => void;
}

const LocationDropDown: FC<ILocationDropDownProps | any> = ({
  value,
  options,
  placeholder,
  disabled = false,
  onChange,
  fromPage = "",
  ...props
}) => {
  const eleRef = useRef<any>();
  const [dropDownOption, setDropDownOption] = useState(options);

  useEffect(() => {
    if (options) {
      if (
        props.countryId &&
        props.countryId === COUNTRY.CON &&
        fromPage !== "quickFilter"
      ) {
        setDropDownOption(formatConsolidatedCinemas(options));
      } else if (
        props.countryId &&
        props.countryId === COUNTRY.AFC &&
        fromPage !== "quickFilter"
      ) {
        setDropDownOption(formatAngelikaFilmCinemas(options));
      } else {
        setDropDownOption(options);
      }
    }
  }, [options, props.countryId, fromPage]);

  const onSelect = (data: any) => {
    onChange && onChange(data.value);
    eleRef.current?.setValue(data);
  };

  const CustomOption: FC<any> = ({ data }) => {
    return (
      <div
        onClick={() => onSelect(data)}
        className={`${
          data.isParent
            ? "parent-label"
            : value && value.value === data.value
            ? "dropdown-item favourite_add"
            : "dropdown-item"
        }`}
      >
        {props.brandId === BRANDS.US && !props.hideIcon ? (
          <img
            className="mx-1"
            src={COUNTRY_IMAGE_CONFIG[props.countryId] + "location.svg"}
          />
        ) : null}
        <Link to={`/${data.alias}`}>
          <span className={props.brandId === BRANDS.US ? "mx-1" : ""}>
            {data.label}
          </span>
        </Link>
        {props.brandId === BRANDS.US ? (
          <p className={"tagline mx-1"}>{data.tagLine}</p>
        ) : null}
      </div>
    );
  };

  const CustomOptionCon: FC<any> = ({ data }) => {
    return fromPage && fromPage === "quickFilter" ? (
      <div
        onClick={() => onSelect(data)}
        className={`${
          data.isParent
            ? "parent-label"
            : value && value.value === data.value
            ? "dropdown-item favourite_add"
            : "dropdown-item"
        }`}
      >
        {props.brandId === BRANDS.US && !props.hideIcon ? (
          <img
            className="mx-1"
            src={COUNTRY_IMAGE_CONFIG[props.countryId] + "location.svg"}
          />
        ) : null}
        <span className={props.brandId === BRANDS.US ? "mx-1" : ""}>
          {data.displayName}
        </span>
      </div>
    ) : (
      <center className="location-section">
        <div>
          <img
            className="map"
            src={
              COUNTRY_IMAGE_CONFIG[props.countryId] +
              `map_${(data[0]?.stateCode).toLowerCase().replace(" ", "_")}.svg`
            }
          />
        </div>
        <div className="d-flex">
          <img
            className="mx-1"
            src={COUNTRY_IMAGE_CONFIG[props.countryId] + "location.svg"}
          />
          <span className="header">{data[0]?.stateCode}</span>
        </div>
        <div className="display-name">
          {data &&
            Array.isArray(data) &&
            data.map((v: any) => (
              <div
                onClick={() => onSelect(v)}
                className="dropdown-item dropdown-item1 map-div"
              >
                <Link to={`/${v.alias}`}>
                  <span
                    className={`subname ${
                      v.value === value.value ? "active" : ""
                    }`}
                  >
                    {v.displayName}
                  </span>
                </Link>
              </div>
            ))}
        </div>
      </center>
    );
  };

  const CustomOptionAfc: FC<any> = ({ data }) => {
    return fromPage !== "quickFilter" &&
      data.stateCode &&
      data.stateCode === "NY" &&
      data.subcity &&
      data.subcity.length > 0 ? (
      <div className="location-wrapper-afc">
        <div className="root">
          <img
            className="mx-1"
            src={COUNTRY_IMAGE_CONFIG[props.countryId] + "location.svg"}
          />
          <span className="mx-1"> {data.subcity[0].city}</span>
        </div>
        <ol className="wtree">
          {data.subcity.map((v: any) => (
            <li onClick={() => onSelect(v)}>
              <div className="d-flex">
                <img
                  className="mx-1"
                  src={COUNTRY_IMAGE_CONFIG[props.countryId] + "location.svg"}
                />
                <span
                  className={`header ${
                    v.value === value.value ? "active" : ""
                  }`}
                >
                  <Link to={`/${v.alias}`}><span className="mx-1"> {v.label}</span></Link>
                </span>
              </div>
            </li>
          ))}
        </ol>
      </div>
    ) : (
      <div
        onClick={() => onSelect(data)}
        className={`${
          data.isParent
            ? "parent-label"
            : value && value.value === data.value
            ? "dropdown-item favourite_add"
            : "dropdown-item"
        }`}
      >
        {props.brandId === BRANDS.US && !props.hideIcon ? (
          <img
            className="mx-1"
            src={COUNTRY_IMAGE_CONFIG[props.countryId] + "location.svg"}
          />
        ) : null}
         <Link to={`/${data.alias}`}><span className="mx-1">{data.label}</span></Link>
      </div>
    );
  };

  return (
    <Select
      ref={eleRef}
      value={value}
      isSearchable={false}
      placeholder={placeholder}
      className="location-dropdown"
      classNamePrefix="location"
      options={dropDownOption}
      // menuIsOpen={true}
      components={{
        Option:
          props.countryId && props.countryId === COUNTRY.CON
            ? CustomOptionCon
            : props.countryId && props.countryId === COUNTRY.AFC
            ? CustomOptionAfc
            : CustomOption,
      }}
      {...props}
    />
  );
};

export default LocationDropDown;

import axios from 'axios';
import { IFilmPayload, IMovieDetailPayload, IPrintSessionPayload, ITicketDetailPayload } from '../../models/cinema';
import { ENDPOINT } from '../endpoint';
import { ICancelTicket, ITicketDetail, ITicketVoucherPayload } from '../../models/tickets';
import { API_REQUEST_TYPE } from '../../constants/url';
import { generateUsersessionId } from '../../utils/tickets';
import { memberValidationApi } from '../auth';
import { RootState, store } from '../../store';
import { ALLOWED_MEMBER_VALIDATE, BRANDS } from '../../constants/app';

export const getNowShowingApi = (payload: IFilmPayload) => {
    return axios.get(`${ENDPOINT.FILMS}`, { params: payload }).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const getComingSoonApi = (payload: IFilmPayload) => {
    const comingSoonApi = axios.get(`${ENDPOINT.FILMS}`, {
        params: {
            status: "comingSoon",
            countryId: payload.countryId,
            flag: payload.cinemaId,
            requestType: payload.requestType
        }
    });
    const advanceTicketApi = axios.get(`${ENDPOINT.FILMS}`, {
        params: {
            cinemaId: payload.cinemaId,
            status: "advanceTickets",
            countryId: payload.countryId,
            flag: payload.flag
        }
    })
    return Promise.all([comingSoonApi, advanceTicketApi]).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const getComingTicketApi = (payload: IFilmPayload) => {
    return axios.get(`${ENDPOINT.FILMS}`, {
        params: {
            status: "comingSoon",
            countryId: payload.countryId,
            flag: payload.cinemaId,
            requestType: payload.requestType,
            movieSlug: payload?.movieSlug
        }
    }).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const getAdvanceTicketApi = (payload: IFilmPayload) => {
    return axios.get(`${ENDPOINT.FILMS}`, {
        params: {
            cinemaId: payload.cinemaId,
            status: "advanceTickets",
            countryId: payload.countryId,
            flag: payload.flag
        }
    }).then((response) => ({ status: true, response })).catch(error => ({ status: false, error }));
}

export const printSessionApi = (payload: IPrintSessionPayload) => {
    return axios.get(`${ENDPOINT.FILMS}`, { params: payload })
}

export const getMovieDetailApi = (payload: IMovieDetailPayload) => {
    const { movieGroupId, countryId, cinemaId, sort } = payload;
    return axios.get(`${ENDPOINT.FILMS}/${movieGroupId}`, { params: { countryId, cinemaId, sort } });
}

export const getTicketDetailApi = async (payload: ITicketDetailPayload) => {
    const films = await axios.get(`${ENDPOINT.FILMS}`, {
        params: {
            countryId: payload.countryId,
            cinemaId: payload.cinemaId,
            sessionId: payload.sessionId,
            sort: true,
            status: API_REQUEST_TYPE.GET_SESSION_DATA,
        }
    })

    const ticketingTypeLength = await axios.post(`${ENDPOINT.TICKET_TYPES}`, {
        cinemaId: payload.cinemaId,
        sessionId: payload.sessionId,
        requestType: API_REQUEST_TYPE.TICKET_TYPES_LENGTH,
        countryId: payload.countryId,
    }, {
        params: {
            countryId: payload.countryId,
            cinemaId: payload.cinemaId,
            sessionId: payload.sessionId,
            requestType: API_REQUEST_TYPE.TICKET_TYPES_LENGTH,
        }
    });

    const userSessionId = generateUsersessionId(payload.cinemaId);
    const state: RootState = store.getState();
    const isLoggedIn = state.authReducer.isLoggedIn;
    const brandId = state.applicationReducer.brandId;
    const userDetails = state.authReducer.userDetails;

    let memberResponse = null;
    if (state.authReducer.isLoggedIn && (brandId === BRANDS.AU ||
        (brandId === BRANDS.US &&
            ALLOWED_MEMBER_VALIDATE.includes(payload.cinemaId)))) {
        memberResponse = await memberValidationApi({
            MemberRefId: state.authReducer.userDetails?.vista_memberid,
            UserSessionId: userSessionId,
            cardNumber: state.authReducer.userDetails?.cardNumber,
            countryId: payload.countryId,
        }) as any;
    }

    const ticketType = await axios.post(`${ENDPOINT.TICKET_TYPES}`, {
        cinemaId: payload.cinemaId,
        sessionId: payload.sessionId,
        reservedSeating: films && films.data && films.data.length > 0 ? films.data[0].reservedSeating : payload.reservedSeating,
        requestType: API_REQUEST_TYPE.SEAT_PLAN,
        covidFlag: payload.covidFlag,
        countryId: payload.countryId,
        screenType: films && films.data && films.data.length > 0 ? films.data[0].sessionAttributes : payload.screenType,
        sharedUserSessionId: payload.sharedUserSessionId,
        memberId: isLoggedIn ? (brandId === BRANDS.US && userDetails?.isMilitary ? userDetails?.id : '') : '',
        memberEmail: isLoggedIn ? userDetails?.email : '',
        isMember: memberResponse && memberResponse.status && memberResponse.response && memberResponse.response.data && memberResponse.response.data.data && memberResponse.response.data.data.LoyaltySessionToken ? true : false
    })

    const {data:[cinemaDetails]} = await axios.get(`${ENDPOINT.GET_CINEMAS}`, { params: { countryId: payload.countryId, slug: payload.cinemaId } })

    try {
        return { response: { films, ticketingTypeLength, ticketType, userSessionId, memberResponse, serviceChargeConfig:cinemaDetails?cinemaDetails.serviceCharge:null  } }
    } catch (e) {
        return { error: e }
    }

}

export const getVoucherTicketsApi = (payload: ITicketVoucherPayload) => {
    return axios.post(`${ENDPOINT.ADD_TICKETS}`, payload);
}

export const cancelTicketsApi = (payload: ICancelTicket) => {
    return axios.post(`${ENDPOINT.CANCEL_TICKET}`, payload).then(response => ({ status: true, response })).catch(error => ({ status: false, error }));
}

export const addTicketApi = (payload: any) => {
    return axios.post(`${ENDPOINT.ADD_TICKETS}`, payload);
}

export const quickFilterApi = (payload: any) => {
    return axios.get(ENDPOINT.FILMS, { params: payload });
}

export const getSignatureProgramsApi = (payload: any) => {
    return axios.get(ENDPOINT.FILMS, { params: payload });
}

export const getTitanApi = (payload: any) => {
    return axios.get(ENDPOINT.FILMS, { params: payload });
}

export const eatAndDrinkApi = (payload: any) => {
    return axios.post(ENDPOINT.EAT_AND_DRINK, payload);
}

export const specialValueApi = (payload: any) => {
    return axios.get(ENDPOINT.SPECIAL_VALUE, { params: payload });
}

export const plfApi = (payload: any) => {
    return axios.get(ENDPOINT.FILMS, { params: payload });
}

export const getSignatureProgramApi = (payload: any) => {
    return axios.get(`${ENDPOINT.FILMS}`, { params: payload }).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const getTitanMovieApi = (payload: any) => {
    return axios.get(`${ENDPOINT.FILMS}`, { params: payload }).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const getMovieDetailsApi = (countryId: any, cinemaId: any) => {
    return axios.get(`${ENDPOINT.FILMS}`, { params: { countryId, cinemaId, status: 'getMovies' } });
}

export const getMovieDetailSearchApi = (movieGroupId: string, countryId: any, cinemaId: any) => {
    return axios.get(`${ENDPOINT.FILMS}/${movieGroupId}/`, { params: { countryId, cinemaId, sort: true } });
}

export const searchApi = (payload: any) => {
    return axios.get(`${ENDPOINT.SEARCH}`, { params: payload });
}

export const getTicketSalesDataApi = (payload: any) => {
    return axios.post(`${ENDPOINT.TICKET_SALES}`, payload);
}

export const getContentApi = (payload: any) => {
    return axios.get(`${ENDPOINT.FILMS}`, { params: payload })
}
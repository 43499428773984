import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Modal from "../../../UI/Modal";
import { showManageCardModal, showManageGiftCardModal } from "../../../../store/auth/action";
import { URLS } from "../../../../constants/url";
import "./manageCard.scss";
import List from "./List";
import AddCard from "./Add";
import { GiftCardDetail } from "../../../../models/common";

interface IManageGiftCardsProps {}

const ManageGiftCards: FC<IManageGiftCardsProps> = () => {
  const { openManageGiftCardModal } = useSelector((state: RootState) => ({
    openManageGiftCardModal: state.authReducer.openManageGiftCardModal,
  }));

  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState<GiftCardDetail | null>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [tabType, setTabType] = useState<string>("manage_cards");


  const onCloseModal = () => {
    setTabType("manage_cards");
    setIsEdit(false);
    dispatch(showManageGiftCardModal(false));
  };

  const onChange = (type: string) => {
    setTabType(type);
    setIsEdit(false);
    setSelectedCard(null)
  };

  const onReset = ()=>{
    setSelectedCard(null);
    setIsEdit(false)
    setTabType('manage_cards')
  }

  return (
    <Modal visible={openManageGiftCardModal} className="manage-cards-popup" showCloseBtn={false}>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content">
            <button
              type="button"
              className="close-modal"
              onClick={onCloseModal}
            >
              <img
                className="close_icon"
                src={URLS.CLOSE_ICON}
                alt="close_icon"
                title="close_icon"
              />
            </button>
            <div className="modal-header">
              <div className="title title_append">Manage Gift Cards</div>
            </div>
            <div className="modal-body">
              <div className="manage_card_icons">
                <div className="buttton-row">
                  <button
                    className={`tab-buttons credit_card ${
                      tabType === "manage_cards" ? "active" : ""
                    }`}
                    onClick={() => onChange("manage_cards")}
                  >
                    Manage Gift Card
                  </button>
                  {!isEdit?<button
                    className={`tab-buttons credit_card mx-3 ${
                      tabType === "add" ? "active" : ""
                    }`}
                    onClick={() => onChange("add")}
                  >
                    Add New Gift Card
                  </button>: null}
                </div>
                {tabType === "manage_cards" ? (
                  <List
                    setSelectedCard={setSelectedCard}
                    setIsEdit={setIsEdit}
                    setTabType={setTabType}
                  />
                ) : (
                  <AddCard
                    data={selectedCard}
                    isEdit={isEdit}
                    setTabType={setTabType}
                    onReset={onReset}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManageGiftCards;

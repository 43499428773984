import { COUNTRY } from "./app";

export const LOYALTY_BANNER: {
    [COUNTRY: string]:
    {
        header: string,
        fee: string,
        desc1: string[],
        desc2: string[],
        footer: string,
        buttonLabel: string,
        bgColor: string,
        type: string
    }[]
} = {
    [COUNTRY.ANG]: [
        {
            header: 'ANGELIKA REWARDS GOLD',
            fee: '$20 Annual Membership',
            desc1: ['20% off Tickets*', '10% off Food and Drinks', 'Collect 20 Points per dollar spent'],
            desc2: ['Redeem your points online or at the cinema', 'Access exclusive member only competitions and screenings', 'Stay up to date with our weekly e-newsletter'],
            footer: '*20% off the Adult ticket price',
            buttonLabel: 'Sign up',
            bgColor: '#d0ae76',
            type: 'gold'
        },
        {
            header: 'ANGELIKA REWARDS CLUB',
            fee: 'Free MembershipFree Membership',
            desc1: ['Collect 10 Points per dollar spent'],
            desc2: ['Redeem your points online or at the cinema', 'Access exclusive member only competitions and screenings', 'Stay up to date with our weekly e-newsletter'],
            footer: '',
            buttonLabel: 'Sign up',
            bgColor: '#ffffff',
            type: 'club'
        }
    ],
    [COUNTRY.US]: [
        {
            header: 'REWARDS GOLD',
            fee: '$20 Annual Membership',
            desc1: ['20% off Tickets*', '10% off Food and Drinks', 'Collect 20 Points per dollar spent'],
            desc2: ['Redeem your points online or at the cinema', 'Access exclusive member only competitions and screenings', 'Stay up to date with out weekly e-newsletter'],
            footer: '*20% off the Adult ticket price',
            buttonLabel: 'Sign up',
            bgColor: '#d0ae76',
            type: 'gold'
        },
        {
            header: 'REWARDS CLUB',
            fee: 'Free MembershipFree Membership',
            desc1: ['Collect 10 Points per dollar spent'],
            desc2: ['Redeem your points online or at the cinema', 'Access exclusive member only competitions and screenings', 'Stay up to date with out weekly e-newsletter'],
            footer: '',
            buttonLabel: 'Sign up',
            bgColor: '#ffffff',
            type: 'club'
        }
    ]
}

import React, { FC, useEffect } from "react";
import { encryptData } from "../../../../utils/helper";
import { useNavigate } from "react-router";
import { AES_KEY } from "../../../../constants/app";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";

interface IRefundHeaderProps { }

const RefundHeader: FC<IRefundHeaderProps> = () => {

  return null
};

export default RefundHeader;

import React, { FC, useState } from "react";
import HtmlRender from "../../UI/HtmlRender";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IMAGE_URL_CONFIG, URLS } from "../../../constants/url";

import "./movieDescription.scss";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import { setModal } from "../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../constants/ticket";
import { Tooltip } from 'react-tooltip';
import { BRANDS } from "../../../constants/app";

interface IMovieDescriptionProps {
  enableShare?: boolean;
  enableDescription?: boolean;
}

const MovieDescription: FC<IMovieDescriptionProps> = ({
  enableShare,
  enableDescription = true,
}) => {

  const { films, brandId } = useSelector((state: RootState) => ({
    films: state.ticketBookingReducer.films,
    brandId: state.applicationReducer.brandId
  }));

  const dispatch = useDispatch();
  const [readMore, setReadMore] = useState(true);

  const openContent = () => {
    setReadMore(!readMore);
  };

  const openSocialShare = ()=>{
    const modal = TICKET_FLOW[TICKET_FLOW_TYPES.SOCIAL_SHARE][MODAL_TYPE.SOCIAL_SHARE];
    dispatch(
      setModal({
        ...modal,
        type: MODAL_TYPE.SOCIAL_SHARE,
      })
    );
  }

  const errorHandler = (event:any) => {
    event.target.src = URLS.ERROR_IMAGE
  };


  return (
    <div className="col-md-4 movie_desc_wraper">
      <div className=" mobile_hide movie_description_wrap">
        <div className="movie_poster">
          <img
            src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${films?.image}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT}`}
            alt={films?.movieName}
            title={films?.movieName}
            onError={errorHandler}
          />
        </div>
        {enableDescription ? (
          <>
            <div className="rating_wrap">
              <span className="certification_icon">
                <div className="icon">
                  <img
                    src={
                      films?.ratingIcon
                        ? `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${films?.ratingIcon}${IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT}`
                        : URLS.ERROR_IMAGE
                    }
                    alt={films?.ratingName}
                    data-tooltip-id="rating-explanation" data-tooltip-html={films.ratingExplanation}
                  />
                   <Tooltip id="rating-explanation" className="tool-tip" />
                </div>
              </span>
              <div className="rating_title">{films?.ratingName}</div>
            </div>
            {readMore ? <HtmlRender
              {...{ className: `movie_description ${brandId === BRANDS.US?'us_brand':''}` }}
              content={films?.outline}
              sliceLength={100}
            /> :
              <HtmlRender
                {...{ className: `movie_description ${brandId === BRANDS.US?'us_brand':''}` }}
                content={films?.outline}
              />
            }
            <p className="readmore_btn" onClick={openContent}><u>{readMore?"Read more...":"Read less"}</u></p>
          </>
        ) : null}
        {enableShare ? (
          <div className="rating_wrap" onClick={openSocialShare}>
            <span className="certification_icon">
              <div className="icon pointer">
                <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"share_icon.svg"} />
              </div>
            </span>
            <div className="rating_title pointer" >Share My Session</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MovieDescription;

import React, {
  FC,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./showTimes.scss";
import ShowTimeFilter from "../../../../components/UI/Filters/ShowTimeFilter";
import {
  getNowShowingError,
  getNowShowingFilterSuccess,
  getNowShowingSuccess,
  getNowShowingWatcher,
  setNowShowingLoader,
  setQuickFilter,
} from "../../../../store/films/action";
import { setCinemaAction } from "../../../../store/application/action";
import {
  dateToFromNowDaily,
  findCinemaObject,
  formatSortSessionType,
} from "../../../../utils/formatter";
import AllShowTimes from "../../../../components/Session/US/AllShowTimes";
import SingleShowTime from "../../../../components/Session/US/SingleShowTime";
import {
  getContentApi,
  getNowShowingApi,
  getSignatureProgramsApi,
} from "../../../../services/films";
import { IDropDown } from "../../../../models/common";
import moment from "moment";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { IMovieData, ISignatureProgramming } from "../../../../models/cinema";
import { useLocation } from "react-router";
import { sleep } from "../../../../utils/helper";
import { ROUTES } from "../../../../constants/path";
import CustomLoader from "../../../../components/UI/CustomLoader";
import { Link } from "react-router-dom";
import { LOCATION_MAPPER } from "../../../../constants/app";

interface IShowTimesProps { }

const ShowTimes: FC<IShowTimesProps> = () => {
  const {
    countryId,
    quickFilters,
    cinemas,
    brandId,
    nowShowing,
    currentCinema,
    loader,
    homeContent,
    nowshowingFilterData,
    nowshowingLoader,
    loaderNowshowing,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    quickFilters: state.filmsReducer.quickFilters,
    cinemas: state.applicationReducer.cinemas,
    brandId: state.applicationReducer.brandId,
    nowShowing: state.filmsReducer.nowShowing,
    nowshowingFilterData: state.filmsReducer.nowshowingFilterData,
    currentCinema: state.applicationReducer.currentCinema,
    loader: state.applicationReducer.loader,
    homeContent: state.applicationReducer.homeContent,
    nowshowingLoader: state.filmsReducer.nowshowingLoader,
    loaderNowshowing: state.filmsReducer.loaderNowshowing,
  }));

  const limitLength = useMemo(() => {
    return window.innerWidth < 1024 ? 4 : window.innerWidth < 1450 ? 8 : 10;
  }, [window?.innerWidth]);

  const dispatch = useDispatch();
  const scrollRef = useRef<any>(null);
  const [type, setType] = useState<IDropDown[]>([]);
  const [session, setSession] = useState<IDropDown[]>([]);
  const [limit, setLimit] = useState<number>(limitLength);
  const [allSession, setAllSession] = useState<IMovieData[]>([]);

  const location = useLocation();

  const scroll = async () => {
    await sleep(500);
    const scroll = scrollRef?.current.offsetTop;
    window.scrollTo({
      top: scroll,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (location.pathname.includes(`/${ROUTES.NOW_PLAYING}`) && !loader) {
      scroll();
    }
  }, [location, loader]);

  const getNowShowingData = async (tempDate:string='')=>{
    const payload: any = {
      brandId,
      countryId: countryId,
      cinemaId: quickFilters.location,
      status: API_REQUEST_TYPE.GET_SHOWS,
      flag: "nowshowing",
    };
    if (quickFilters.movie) {
      payload.selectedMovie = quickFilters.movie;
    }
    if (quickFilters.format) {
      if (quickFilters.format.includes("signature-")) {
        payload.movieGroupIds = quickFilters.format.split("-").pop();
      } else {
        payload.searchAttribute = quickFilters.format;
      }
    }
    if (quickFilters.session) {
      payload.selectedSessionId = quickFilters.session;
    }
    if (quickFilters.date || tempDate) {
      payload.selectedDate = tempDate?tempDate:quickFilters.date;
    }
    getContentApi(payload).then((showResponse) => {
      const {
        data: { nowShowing },
      } = showResponse;

      if (nowShowing && nowShowing.statusCode === 200) {
        if (
          nowShowing.data &&
          nowShowing.data.movies &&
          nowShowing.data.movies.length > 0
        ) {
          dispatch(getNowShowingSuccess(nowShowing.data.movies));
          if (!quickFilters.format) {
            dispatch(getNowShowingFilterSuccess({ ...nowshowingFilterData, format: nowShowing.data.filter.format }))
          }
        } else {
          dispatch(getNowShowingError([]));
        }
      }
    });
  }

  useEffect(()=>{
    if (location.pathname.includes(`/${ROUTES.NOW_PLAYING}`)) {
      if (quickFilters.location) {
        if(quickFilters.tempDate){
          getNowShowingData(quickFilters.tempDate);
          dispatch(setQuickFilter({...quickFilters, date: quickFilters.tempDate, tempDate:null}));
        }else{
          getNowShowingData();
        }
      }
    }
  },[location])


  useEffect(() => {
    if (quickFilters.location && !nowshowingLoader && !quickFilters.tempDate) {
      getNowShowingData();
    }

    const cinema = findCinemaObject(cinemas, quickFilters.location!);
    if (cinema) {
      dispatch(setCinemaAction(cinema));
    }
  }, [quickFilters]);


  useEffect(() => {
    if (quickFilters.location && !nowshowingLoader) {
      if(quickFilters.tempDate){
        getNowShowingData(quickFilters.tempDate);
        dispatch(setQuickFilter({...quickFilters, date: quickFilters.tempDate, tempDate:null}));
      }else{
        getNowShowingData();
      }
    }
  }, []);

  useEffect(() => {
    if (nowshowingFilterData) {
      let signatureData: IDropDown[] = [];
      const signatureFormat = homeContent.signatureProgramming
        ? homeContent.signatureProgramming
        : [];
      if (signatureFormat && signatureFormat.length) {
        signatureData = signatureFormat.map((v: ISignatureProgramming) => ({
          label: v.Program_title,
          value: "signature-" + v.Films,
        }));
      }
      setType([...nowshowingFilterData.format, ...signatureData]);
      setSession(nowshowingFilterData.session);
      if (nowshowingFilterData && nowshowingFilterData.session.length > 0 && nowshowingLoader) {
        dispatch(
          setQuickFilter({
            ...quickFilters,
            location: quickFilters.location
              ? quickFilters.location
              : currentCinema.slug,
            date: quickFilters.date
              ? quickFilters.date
              : nowshowingFilterData.session[0].value,
            movie: null,
            session: null,
          })
        );
      }
    }
  }, [nowshowingFilterData, homeContent]);

  const showAllSession = useMemo(() => {
    return quickFilters.session || quickFilters.date ? false : true;
  }, [quickFilters]);

  useEffect(() => {
    if (showAllSession && nowShowing) {
      setAllSession(nowShowing.slice(0, limit));
    }
  }, [showAllSession, nowShowing]);

  const onShowMore = () => {
    if (nowShowing.length != allSession.length) {
      setAllSession(nowShowing.slice(0, limit + limitLength));
      setLimit(limit + limitLength);
    }
  };

  const onShowLess = () => {
    setAllSession(nowShowing.slice(0, limitLength));
    setLimit(limitLength);
    scroll();
  };

  const showMore = useMemo(() => {
    return (
      nowShowing &&
      nowShowing.length > limitLength &&
      nowShowing.length != allSession.length
    );
  }, [nowShowing, allSession]);

  const showLess = useMemo(() => {
    return (
      nowShowing &&
      nowShowing.length > limitLength &&
      nowShowing.length == allSession.length
    );
  }, [nowShowing, allSession]);

  const Tag: FC<{ slug: string }> = ({ slug }) => {
    if(!slug){
      return null
    }
    const name =  homeContent?.signatureProgramming?.find((v: ISignatureProgramming) =>
      v.Program_title === slug
    );

    return name ? (
      <div className="tag">
        <div>
          <Link
            to={`/${
              LOCATION_MAPPER[countryId][currentCinema.slug].alias
            }/signature-programming/${name.Website_slug}`}
          >
            {slug}
          </Link>
        </div>
      </div>
    ) : null;
  };

  return (
    <section className="showtime-section" ref={scrollRef}>
      <div
        className={`container-content ${!showAllSession ? "single-session" : ""
          }`}
      >
        <div className="showtime-content" id="showtime-content">
          <h2 className="heading">SHOWTIMES & TICKETS</h2>
          {!loaderNowshowing ? (
            <div className="content-body">
              <ShowTimeFilter session={session} type={type} />
              {showAllSession ? (
                <div className="showtime-section-thumbnail row justify-content-center">
                  <>
                    <div className="card__wrap--outer">
                      {allSession.map((session: IMovieData, index: number) => {
                        return <AllShowTimes key={index} session={session} />;
                      })}
                    </div>
                    {showMore || showLess ? (
                      <div className="show-more">
                        {showMore ? (
                          <p onClick={onShowMore}>SHOW MORE</p>
                        ) : (
                          <p onClick={onShowLess}>SHOW LESS</p>
                        )}
                      </div>
                    ) : null}
                  </>
                  {/* ) : (
                  <div className="w-100 d-flex justify-content-center">
                    <div className="no-session">No Sessions Available</div>
                  </div>
                )} */}
                </div>
              ) : (
                <div className="single-show-time-container">
                  {nowShowing && nowShowing.length > 0 ? (
                    nowShowing.map((v: IMovieData) => (
                      <SingleShowTime data={v} tag={<Tag slug={v.signaturePrograming!} />}/>
                    ))
                  ) : (
                    <div className="w-100 d-flex justify-content-center">
                      <div className="no-session">No Sessions Available</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : <CustomLoader loader={true} />}
        </div>
      </div>
    </section>
  );
};

export default ShowTimes;

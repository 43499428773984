import React,{ FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IPromoAd } from "../../models/common";
import { RootState } from "../../store";
import Modal from "../UI/Modal";
import "./promoAd.scss";
import { URLS } from "../../constants/url";

interface IPromoAdProps {}

const PromoAd: FC<IPromoAdProps> = () => {
  const { promoAd } = useSelector((state: RootState) => ({
    promoAd: state.applicationReducer.promoAd,
  }));

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (promoAd.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [promoAd]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal visible={open} className="promoad-popup" showCloseBtn={false}>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered promotion_modal">
          <div className="modal-content">
          <button type="button" className="close" onClick={onClose}>
          <img
            className="close_icon"
            src={URLS.CLOSE_ICON}
            alt="close_icon"
            title="close_icon"
          />
        </button>
            <div className="modal-body p-0">
              {promoAd && promoAd.map((promo: IPromoAd, index: number) => (
                <div key={`promo_${index}`} className="promo_img_wrap">
                  <img
                    src={promo.imageUrl}
                    alt={promo.title}
                    title={promo.title}
                  />
                  <div className="promo_btn">
                    <a href={promo.buttonLink} className="btn white_btn">
                      {promo.buttonText}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PromoAd;

import React,{ FC, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  purchaseHistoryApi
} from "../../../../services/auth";
import moment from "moment";
import { ITransactionHistory } from "../../../../models/auth";
import { COUNTRY } from "../../../../constants/app";
import Slider from "../../../UI/Slider";
import Slick from 'react-slick';
import { history } from '../../../../store/index';

interface IPurchaseHistoryProps {}

const RewardsHistory: FC<IPurchaseHistoryProps> = () => {
  
  const { countryId, memberDetail, openSlider } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    memberDetail: state.authReducer.memberDetail,
    openSlider: state.applicationReducer.openSlider,
  }));

  const [purchaseHistory, setPurchaseHistory] = useState<ITransactionHistory[]>(
    []
  );

  const getPurchaseHistory = async () => {
    setPurchaseHistory([]);
    const payload = {
      countryId: countryId,
      MemberRefId: memberDetail?.LoyaltyMember?.MemberId,
      cardNumber: memberDetail?.LoyaltyMember?.CardNumber,
      UserSessionId: memberDetail?.LoyaltyMember?.CardNumber,
    };
    const {
      status,
      data:{
        data,
        statusCode
      }
    } = await purchaseHistoryApi(payload) as any;
    if (status === 200 && statusCode === 200 && data) {
      if (
        data.result &&
        data.result.MemberTransactions &&
        data.result.MemberTransactions.length > 0
      ) {
        setPurchaseHistory(data.result.MemberTransactions);
      }
    }
  };

  useEffect(() => {
    if (memberDetail && openSlider.open) {
      getPurchaseHistory();
    }
  }, [memberDetail, openSlider]);

  let txnPoints = useMemo(() => {
    if (
      memberDetail &&
      memberDetail?.LoyaltyMember &&
      memberDetail.LoyaltyMember.BalanceList &&
      memberDetail.LoyaltyMember.BalanceList.length > 0
    ) {
      return memberDetail?.LoyaltyMember?.BalanceList.reduce(
        (total: number, v: any) => {
          total = total + Number(v.PointsRemaining);
          return total;
        },
        0
      )
    } else {
      return 0;
    }
  }, [memberDetail]);

  const slideRef = useRef<any>();

  let settings = {
    className: "",
    centerMode: false,
    centerPadding: "10px",
    dots: true,
    lazyload: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    cssEase: "linear",
    fade: false,
    slidesToScroll: 1,
    initialSlide: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    // adaptiveHeight: true,
    arrows: true,
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };

const dataArray = (arr: any[], chunkSize: number) => {
  const chunkedArray = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunkedArray.push(arr.slice(i, i + chunkSize));
  }
  return chunkedArray;
}

let rowLen = 0;

const chunkedData = dataArray(purchaseHistory, 7);

  return (
    // <div className="col-md-12">
    <div className="purchase-history-card-afc section_divided">
        <div className="title">Rewards History</div>
        <div className="table_responsive">
      {chunkedData.length > 0 ?  
      <Slick {...settings}>
      {chunkedData.map((row, index) => {
        return (
          <div key={index}>
              {row.map((item, i) => {
                let cumulativeSum = (i !== 0) ?(txnPoints-chunkedData[index][i-1]?.PointsEarned):((index !== 0 && i === 0 ? txnPoints-chunkedData[index-1][rowLen-1]?.PointsEarned : txnPoints));
                txnPoints = cumulativeSum;               
                rowLen = row.length;
                  return (
                  <div className="history_content">
                    <div className="date">
                        {moment(
                          new Date(parseFloat(item.TransactionDate.substr(6)))
                          ).format("MMM DD").toUpperCase()}
                    </div>
                    <div className="history_data">
                      <div className="data_title">
                        {item.PointsEarned !== 0 ? (item.MovieName !== null ? "TICKET PURCHASE" : "CONCESSION PURCHASE") : "REWARD REDEMPTION" }
                      </div>
                      { item.MovieName ?<span>&nbsp;({item.MovieName})</span> : null}
                      <div className="cinema-name">{item.CinemaName}</div>
                    </div>
                    <div className="points_data">
                      <div className="purchased-points">
                      {item?.PointsEarned < 0 ? <span className="minus">-</span> : <span className="plus">+</span>}{Math.abs(item.PointsEarned)+" "}
                         POINTS</div>
                      <div className="balance-points">{cumulativeSum} Points Total</div>
                    </div>
                  </div> );
              })}
          </div> );
        })}
        </Slick> : 
          <div className="no-tran">
            <p className="text-center text-danger h6">
              No transaction available
            </p>
          </div>
        }
      </div>
    </div>
  );
};

export default RewardsHistory;

import { IShows } from "../../models/cinema";
import { ILiciense } from "../../models/foodbeverage";

export interface IorderCartState {
  orderCartError: any,
  orderCartLoader: boolean,
  orderCartData: [],
}

export interface FoodBeverageItems {
  id?: any;
  description?: string;
  quantity: any;
  image?: string;
  price?: string;
  tax?: string;
  totalPrice?: string;
  delivery?: any;
  options?: any;
  variant?: any;
}

export interface IFoodBeverageState {
  foodItemsError: any;
  foodItemsLoader: boolean;
  foodItemsData: IConcessions[];
  FoodOrderPostError: any;
  FoodOrderPostLoader: boolean;
  FoodOrderPostData: IConcessions[];
  concessions: IConcession[],
  modalHeaderText: string,
  ticketLessFb: boolean,
  orderCartData: FoodBeverageItems[],
  isFutureFb: boolean,
  alcoholDob: string,
  license: null | ILiciense,
  bookedTickets: any,
  prePurchasedTicket: any
}

interface IConcessions {
  concessions: IConcession[];
  name: string;
  _comment: string;
}

interface IConcession {
  delivery: string[];
  id: string;
  image: string;
  name: string;
  options?: IOptions[];
  price: string;
  tax: string;
  variant: any;
}

interface IOptions {
  choices?: IChoices[];
  description: string;
  id: string;
  sizeItems: boolean;
  _comment: string;
}

interface IChoices {
  description: string;
  id: string;
  maximumQuantity: number;
  minimumQuantity: number;
  modifiers?: IModifiers[];
}

interface IModifiers {
  description: string;
  id: string;
  image: string;
  price: string;
  tax: string;
}

export const ACTIONS = {
  GET_ORDER_CART_WATCHER: "GET_ORDER_CART_WATCHER",
  GET_ORDER_CART_SUCCESS: "GET_ORDER_CART_SUCCESS",
  GET_ORDER_CART_FAILURE: "GET_ORDER_CART_FAILURE",
  ADD_ORDER_FOOD_ITEMS: "ADD_ORDER_FOOD_ITEMS",
  UPDATE_ORDER_FOOD_ITEMS: "UPDATE_ORDER_FOOD_ITEMS",
  REMOVE_ORDER_FOOD_ITEMS: "REMOVE_ORDER_FOOD_ITEMS",
  GET_FOOD_BEVERAGE_WATCHER: "GET_FOOD_BEVERAGE_WATCHER",
  GET_FOOD_BEVERAGE_SUCCESS: "GET_FOOD_BEVERAGE_SUCCESS",
  GET_FOOD_BEVERAGE_FAILURE: "GET_FOOD_BEVERAGE_FAILURE",
  FOOD_ORDER_POST_WATCHER: "FOOD_ORDER_POST_WATCHER",
  FOOD_ORDER_POST_SUCCESS: "FOOD_ORDER_POST_SUCCESS",
  FOOD_ORDER_POST_FAILURE: "FOOD_ORDER_POST_FAILURE",
  SET_TICKETLESS_FB: "SET_TICKETLESS_FB",
  CLEAR_FOOD_ITEMS: "CLEAR_FOOD_ITEMS",
  SETUP_MODAL_HEADER_TEXT: "SETUP_MODAL_HEADER_TEXT",
  SET_ALCOHOL_DOB: "SET_ALCOHOL_DOB",
  GET_LICIENSE: "GET_LICIENSE",
  GET_LICIENSE_SUCCESS: "GET_LICIENSE_SUCCESS",
  GET_LICENSE_SUCCESS:"GET_LICENSE_SUCCESS",
  GET_LICENSE_ERROR:"GET_LICENSE_ERROR",
  GET_BOOKED_TICKETS: "GET_BOOKED_TICKETS",
  GET_BOOKED_TICKETS_ERROR: "GET_BOOKED_TICKETS_ERROR",
  GET_BOOKED_TICKETS_SUCCESS: "GET_BOOKED_TICKETS_SUCCESS",
  PRE_PURCHASED_TICKET: "PRE_PURCHASED_TICKET",
}
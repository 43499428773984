import React, { useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { formatMetaTheaterName } from '../../utils/formatter';
import { COUNTRY, GOOGLE_SITE_VERIFICATION_KEY, SITE_URL } from '../../constants/app';

interface iHelmetTiytle {
    title?: string;
    description?: string;
    schema?: any
  }
const HelmetTitle: React.FC<iHelmetTiytle> = ({ title, description, schema=[] }) => {

  const { countryId , currentCinema} = useSelector((state: RootState)=>({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema
  }))

  const displayName = useMemo(()=>{
    if(countryId && currentCinema && currentCinema.displayName){
      return formatMetaTheaterName(countryId,currentCinema)
    }else{
      return '';
    }
  },[countryId, currentCinema])

  const canonicalUrl = useMemo(()=>{
    return SITE_URL[countryId as COUNTRY]+(window && window.location?window.location.pathname:'')
  },[title,currentCinema])

  return (
    <HelmetProvider>
      <Helmet script={schema}>
        <title>{`${title} | ${displayName}`}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="google-site-verification" content={GOOGLE_SITE_VERIFICATION_KEY[countryId as COUNTRY]} />
      </Helmet>
    </HelmetProvider>
  );
};

export default HelmetTitle;

import { ACTIONS } from './type';

export function getNowShowingWatcher(payload: any) {
    return { type: ACTIONS.GET_NOW_SHOWING_WATCHER, payload }
}

export function getNowShowingFilterSuccess(payload: any) {
    return { type: ACTIONS.GET_NOW_SHOWING_FILTER_DATA_SUCCESS, payload }
}

export function getNowShowingFilterFailure(payload: any) {
    return { type: ACTIONS.GET_NOW_SHOWING_FILTER_DATA_FAILURE, payload }
}

export function getNowShowingSuccess(payload: any) {
    return { type: ACTIONS.GET_NOW_SHOWING_SUCCESS, payload }
}

export function getNowShowingError(payload: any) {
    return { type: ACTIONS.GET_NOW_SHOWING_FAILURE, payload }
}

export function getComingSoonWatcher(payload: any) {
    return { type: ACTIONS.GET_COMING_SOON_WATCHER, payload };
}

export function getComingSoonSuccess(payload: any) {
    return { type: ACTIONS.GET_COMING_SOON_SUCCESS, payload: payload };
}

export function getComingSoonError(payload: any) {
    return { type: ACTIONS.GET_COMING_SOON_FAILURE, payload: payload };
}

export function getComingSoonTicketWatcher(payload: any) {
    return { type: ACTIONS.GET_COMING_SOON_TICKET_WATCHER, payload };
}

export function getComingSoonTicketSuccess(payload: any) {
    return { type: ACTIONS.GET_COMING_SOON_TICKET_SUCCESS, payload: payload };
}

export function getComingSoonTicketError(payload: any) {
    return { type: ACTIONS.GET_COMING_SOON_TICKET_FAILURE, payload: payload };
}

export function getAdvanceTicketWatcher(payload: any) {
    return { type: ACTIONS.GET_ADVANCE_TICKET_WATCHER, payload };
}

export function getAdvanceTicketSuccess(payload: any) {
    return { type: ACTIONS.GET_ADVANCE_TICKET_SUCCESS, payload: payload };
}

export function getAdvanceTicketError(payload: any) {
    return { type: ACTIONS.GET_ADVANCE_TICKET_FAILURE, payload: payload };
}

export function setQuickFilter(payload: any) {
    return { type: ACTIONS.SET_QUICK_FILTER, payload: payload }
}

export function getSignatureProgramWatcher(payload: any) {
    return { type: ACTIONS.GET_SIGNATURE_PROGRAM_WATCHER, payload }
}

export function getSignatureProgramSuccess(payload: any) {
    return { type: ACTIONS.GET_SIGNATURE_PROGRAM_SUCCESS, payload }
}

export function getSignatureProgrammError(payload: any) {
    return { type: ACTIONS.GET_SIGNATURE_PROGRAM_FAILURE, payload }
}

export function getTitanMovieWatcher(payload: any) {
    return { type: ACTIONS.GET_TITAN_MOVIES_WATCHER, payload }
}

export function getTitanMovieSuccess(payload: any) {
    return { type: ACTIONS.GET_TITAN_MOVIES_SUCCESS, payload }
}

export function getTitanMovieError(payload: any) {
    return { type: ACTIONS.GET_TITAN_MOVIES_FAILURE, payload }
}

export function searchMovie(payload: any){
    return { type: ACTIONS.SEARCH_MOVIE, payload }
}

export function setNowShowingLoader(payload: boolean){
    return { type: ACTIONS.SET_NOW_SHOWING_LOADER, payload }
}

export function setLoaderNowshowing(payload: boolean){
    return { type: ACTIONS.SET_LOADER_NOWSHOWING, payload }
}
export function setLoaderComingsoon(payload: boolean){
    return { type: ACTIONS.SET_LOADER_COMINGSOON, payload }
}
export function setLoaderAdvanceticket(payload: boolean){
    return { type: ACTIONS.SET_LOADER_ADVANCETICKET, payload }
}

export enum MODAL_TYPE {
    NO_OF_SEAT = "noOfSeat",
    SEAT_SELECTION = "seatSelection",
    INITIAL_FOOD_AND_BEVERAGE = "initialfoodandbeverage",
    FOOD_AND_BEVERAGE = "foodAndBeverage",
    ORDER_SUMMARY = "orderSummary",
    FUTURE_FOOD_AND_BEVERAGE = "futurefoodAndBeverage",
    SESSION = "session",
    AUTH = "auth",
    PAYMENT = "payment",
    TICKET_CONFIRMATION = "ticketConfirmation",
    TICKET_ERROR = "ticketError",
    DEFAULT = "",
    LOGIN = "login",
    PRE_PURCHASE_ORDER = "prepurchase",
    PURCHASE_LIST = "purchaselist",
    FOOD_AND_BEVERAGE_MAIN = "foodAndBeverageMain",
    FOOD_AND_BEVERAGE_FUTURE = "foodAndBeverageFuture",
    MOVIE_LISTING = "movieListing",
    SOCIAL_SHARE = "socialShare",
    REFUND = "refund",
    REFUND_CONFIRMATION = "refundConfirmation",
    GIFTSHOP_PAYMENT = "giftshopPayment",
    GIFTSHOP_CONFIRMATION = "giftshopConfirmation",
    GIFTSHOP_ERROR = "giftshopError",
    MILITARY_MEMBER_CONFIRMATION = "militaryMemberConfirmation",
    EDIT_FUTURE_GL = "editFutureGL",
    KITCHEN_CLOSED = "kitchenClosed'",
    MEMBERSHIP_CONFIRMATION = "membershipConfirmation",
    MEMBERSHIP_ERROR = "membershipError",
    MEMBERSHIP_AUTH = "membershipAuth",
    MEMBERSHIP_PAYMENT = "memebershipPayment"
}

export enum HEADER_TYPE {
    TICKET_BOOKING_HEADER = "ticketBookingHeader",
    FOOD_AND_BEVERAGE_HEADER = "foodAndBeverageHeader",
    ORDER_SUMMARY_HEADER = "orderSummaryHeader",
    AUTH_HEADER = "authHeader",
    PAYMENT_HEADER = "paymentHeader",
    TICKET_CONFIRMATION_HEADER = "ticketConfirmationHeader",
    TICKET_ERROR_HEADER = "ticketErrorHeader",
    FUTURE_FANDB_HEADER = "futuref&bheader",
    SOCIAL_SHARE = "socialShare",
    REFUND = "refund",
    REFUND_CONFIRMATION = "refundConfirmation",
    GIFTSHOP_PAYMENT_HEADER = "giftshopPaymentHeader",
    GIFTSHOP_CONFIRMATION_HEADER = 'giftshopConfirmationHeader',
    GIFTSHOP_ERROR_HEADER = "giftshopErrorHeader",
    MILITARY_MEMBER_HEADER = "militaryMemberHeader",
    KITCHEN_CLOSED_HEADER = "kitchenClosed",
    MEMBERSHIP_AUTH_HEADER = "membershipAuthHeader",
    MEMBERSHIP_PAYMENT_HEADER = "membershipPaymentHeader",
    MEMBERSHIP_CONFIRMATION_HEADER = "membershipConfirmationHeader",
    MEMBERSHIP_ERROR_HEADER = "membershipErrorHeader"
}

export enum TICKET_FLOW_TYPES {
    NO_OF_SEAT = "noOfSeat",
    SEAT_SELECTION = "seatSelection",
    MULTI_TICKETING = "multiTicketing",
    NO_OF_SEAT_FB = "noOfSeatFb",
    SEAT_SELECTION_FB = "seatSelectionFb",
    MULTI_TICKETING_FB = "multiTicketingFB",
    INITIAL_FOOD_AND_BEVERAGE = "initialfoodandbeverage",
    FOOD_AND_BEVERAGE = "foodAndBeverage",
    SOCIAL_SHARE = "socialShare",
    REFUND = "refund",
    GIFTSHOP = "giftshop",
    MILITARY_MEMBER = "militaryMember",
    KITCHEN_CLOSED = "kitchenClosed",
    MEMBERSHIP_PURCHASE = "membershipPurchase",
    MEMBERSHIP_FREE_PURCHASE = "membershipFreePurchase",
    DEFAULT = "",
}

export type ITICKET_FLOW = {
    [key in TICKET_FLOW_TYPES]: {
        [Key in MODAL_TYPE]?: {
            header: string;
            prevModal: string;
            modal: string;
            nextModal: string;
            skipToModal?: string;
            data: any;
            children?: React.ReactNode
        };
    };
};

export interface ITicketType {
    AreaCategoryCode: string;
    Description: string;
    description?: string;
    TicketTypeCode: string;
    BookingFee: number;
    Price: string;
    qty?: number;
    bookingFees?: number;
    count?: number;
    price?: string;
    ticketTypeCode?: string;
    LoyaltyRecognitionId?: string,
    isPackageTicket?: boolean;
    isPackageContent?: {
        Quantity: number
    }
}

export interface ISeatLayout {
    areaNumber: number;
    column: number;
    isAvailable: boolean;
    isBooked: boolean;
    row: number;
    seatId: string;
    SeatId?: string;
    seatType: string;
    isSelected: boolean;
    PhysicalName: string
}

export interface ITicketDetail {
    selectedSeats?: ISeatLayout[];
    ticketTypes?: ITicketType[];
    userSessionId?: string;
    sessionId?: string;
    saleId?: string;
    total?: number;
    bookingFees?: number;
    price?: number;
    bookingId?: string;
}

export interface IFormatedTicketType {
    areaCategoryCode: string;
    qty: number;
    bookingFees: number;
    description: string;
    price: number;
    ticketTypeCode: string;
    total: number;
    OptionalBarcode?: string;
    IsAvailableForLoyaltyMembersOnly?: boolean
    IsShowToNonLoyaltyMembers?: boolean,
    isPackageTicket?: boolean,
    isPackageContent?: any
}

export interface ITicketVoucher {
    description: string,
    cardNumber: string,
    type: string
}

export interface ITicketVoucherPayload {
    cardNumber: string,
    cinemaId: string,
    sessionId: string,
    requestType: string,
    countryId: string,
    userSessionId: string
}

export interface ICancelTicket {
    countryId: string,
    requestType: string,
    userSessionId: string,
}

export interface ISeatLayout {
    areaNumber: number;
    column: number;
    isAvailable: boolean;
    isBooked: boolean;
    row: number;
    seatId: string;
    SeatId?: string;
    seatType: string;
    isSelected: boolean;
}

export interface ISocialShare {
    Id: string,
    Plat_Form: string,
    Session_Seat_Sharing_Description: string,
    Session_Seat_Sharing_title: string,
    Session_Seat_Sharing_url_link: string,
    Session_Sharing_Description: string,
    Session_Sharing_title: string,
    Session_Sharing_url_link: string,
    brandId: string,
    countryId: string,
    image1: string,
    image2: string,
    image3: string,
    image4: string,
    online: number,
    uniqueKey: string
}
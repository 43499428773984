import React,{ FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../../../../../components/UI/DropDown/FoodItem";
import { URLS } from "../../../../../../../constants/url";
import { RootState } from "../../../../../../../store";
import "./cuztomizeItems.scss";
import { toast } from "react-toastify";
import { FoodBeverageItems } from "../../../../../../../store/foodAndBeverage/type";
import { addFoodItemsCart, setUpModalHeaderText, updateFoodItemsCart } from "../../../../../../../store/foodAndBeverage/action";
import { BRANDS } from "../../../../../../../constants/app";
import { concessionModifierPrice } from "../../../../../../../utils/tickets";

export interface CustomiseProps {
  item?: any;
  index?: number;
  onEditQty?: () => void;
  //onSave?: (item: object) => void;
  onSave?: any;
  onBack?: any;
  onRemove?: any;
  onEdit?: (item: object, index: number) => void;
}

const CustomizeItems: FC<CustomiseProps> = ({
  item,
  index,
  onSave,
  onBack,
  onRemove,
  onEdit,
}) => {

  const { countryId, hasGL, seat, orderCartData, brandId, ticketLessFb } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
      ticketLessFb: state.foodBeverageReducer.ticketLessFb,
      hasGL: state.ticketBookingReducer.hasGL,
      seat: state.ticketBookingReducer.ticketDetails.selectedSeats,
      orderCartData: state.foodBeverageReducer.orderCartData,
    })
  );

  const dispatch = useDispatch();    
  const [quantity, setQuantity] = useState<any>();
  const [total, setTotal] = useState<any>();
  const [deliveryInfo, setDeliveryInfo] = useState<any>();
  const [ updateState, setUpdateState] = useState(false);
  const [modIndex, setModIndex] = useState<any>();
  const [smartIndex, setSmartIndex] = useState<any>();
  const [ctItem, setCtItem] = useState<any>();

  useEffect(()=> {
    setCtItem(item);    
  }, [item])

  useEffect(()=> {    
    setModIndex(ctItem?.options.findIndex((value:any) => value._comment === "ModifierGroups"));
    setSmartIndex(ctItem?.options.findIndex((value:any) => value._comment === "smartModifierGroups"));
    setQuantity(ctItem?.quantity);
    if (hasGL && seat && seat.length > 0) {

      if (ctItem?.state) {
        setDeliveryInfo({
          RowId: ctItem.DeliveryInfo.RowIndex,
          SeatNumber:  ctItem.DeliveryInfo.SeatNumber,
          AreaNumber:  ctItem.DeliveryInfo.AreaNumber,
          ColumnIndex:  ctItem.DeliveryInfo.ColumnIndex,
          RowIndex:  ctItem.DeliveryInfo.RowIndex,
          Comment: ctItem.DeliveryInfo.Comment,
          DeliveryOption: ctItem.DeliveryInfo.DeliveryOption
        })
      }
      else {
        setDeliveryInfo({
          RowId: seat[0].RowIndex,
          SeatNumber:  seat[0].SeatId,
          AreaNumber:  seat[0].AreaNumber,
          ColumnIndex:  seat[0].ColumnIndex,
          RowIndex:  seat[0].RowIndex,
          Comment: "First Half",
          DeliveryOption: 1
        })
      }
      
    }    
  }, [ctItem])

  useEffect(()=>{
    if (smartIndex != -1 && !ctItem?.state && ctItem?.options[smartIndex]?.selected) {      
      ctItem.options[smartIndex].selected = ctItem.options[smartIndex]?.choices[0]?.modifiers;
      setCtItem(ctItem);
    }
    calculateTotalPrice(ctItem); 
  }, [smartIndex,ctItem])

  const addToOrder = () => {
    if (ctItem.options.length > 1) {
      if ((ctItem.options[modIndex].choices.length == Object.values(ctItem.options[modIndex].selected).length)) {
          handleAddToOrder(ctItem);
      }
      else {
        toast.error("Please choose the items");
      }
    }
    else {
      if (smartIndex != -1) {
        handleAddToOrder(ctItem);
      }
      else if (modIndex != -1 && (ctItem.options[modIndex].choices.length == Object.values(ctItem.options[modIndex].selected).length)) {
        handleAddToOrder(ctItem);
      }
      else {
        toast.error("Please choose the items");
      }
    }    
  }

  const handleAddToOrder = (items: FoodBeverageItems) => {
    const data = JSON.parse(JSON.stringify(items)); 
    const ctItem = {
      ...data, 
      DeliveryInfo: deliveryInfo,
      delivery: hasGL?"seat":"self",
      totalPrice: (total).toFixed(2)
    }
    if (ctItem.state) {
      const newArray = JSON.parse(JSON.stringify(orderCartData));      
      newArray[ctItem.index] = ctItem;
      dispatch(
        updateFoodItemsCart([...newArray])
      );
    } 
    else {
      dispatch(
        addFoodItemsCart({
          ...ctItem
        })
      );
    }   
    dispatch(setUpModalHeaderText( brandId === BRANDS.US && ticketLessFb? "Eat & Drink":"Add Food & Drink"));
    onBack();
  };

  const setSelecetedFoodItem = (option: any, parentIndex: number) => {        
    if (ctItem?.options[modIndex]?.selected && ctItem?.options[modIndex]?.selected[parentIndex] != 'undefined') {
      ctItem.options[modIndex].selected[parentIndex] = option;
    }
    else {
      ctItem?.options[modIndex]?.selected?.splice(parentIndex, 0, option);
    }
    setCtItem(ctItem);
    calculateTotalPrice(ctItem);
  }

  const setSelecetedIngredients = (event: any, choice: any, parentIndex: number) => {            
    if(event.target.checked) {
      
      const indx =  ctItem.options[smartIndex].selected.findIndex((ctItem: any) => ctItem.id == choice.id);   
      if(indx > -1){
        ctItem.options[smartIndex].selected[indx] = (choice);      
      }else{
        ctItem.options[smartIndex].selected.push(choice);      
      }
      ctItem.options[smartIndex].unselected = ctItem.options[smartIndex].unselected.filter((ctItem: any) => ctItem.id != choice.id);        
    }
    else {
      const indx = ctItem.options[smartIndex].unselected.findIndex((ctItem: any) => ctItem.id == choice.id);
      if(indx > -1){
        ctItem.options[smartIndex].unselected[indx] = choice;
      }else{
        ctItem.options[smartIndex].unselected.push(choice);
      }
      ctItem.options[smartIndex].selected = ctItem.options[smartIndex].selected.filter((ctItem: any) => ctItem.id != choice.id);        
    }
    setCtItem(ctItem);
    calculateTotalPrice(ctItem);
  }

  const calculateTotalPrice = (itm: any) => {
    let totalPrice = concessionModifierPrice(itm);  
    setUpdateState(!updateState);
    setTotal(itm?.quantity*totalPrice);    
  };

  const checkIngredient = (selectedItem: any) => {        
    return ctItem?.options[smartIndex]?.selected?.some(function(el: any){ return el.id === selectedItem.id});
  }

  const onImgError = (event: any) => {
    event.target.src = URLS.DEFAULT_FOOD_IMAGE;
  };

  const setDeliverTime = (choice: any) => {            
    setDeliveryInfo({...deliveryInfo,Comment:choice.description})
  }

  const setSelecetedSeat = (seat: any) => {       
    setDeliveryInfo({
      ...deliveryInfo, 
      RowId:seat.RowIndex,
      SeatNumber:seat.SeatId,
      AreaNumber:  seat.AreaNumber,
      ColumnIndex:  seat.ColumnIndex,
      RowIndex:  seat.RowIndex
    })
  }

  const onClickMinus = () => {        
    if (ctItem.quantity > 1) {
      ctItem.quantity = ctItem.quantity-1;
      setQuantity(ctItem.quantity);
      setTotal(ctItem.quantity*ctItem.price); 
    }    
  }

  const onClickPlus = () => {        
    ctItem.quantity = ctItem.quantity+1;
    setQuantity(ctItem.quantity);
    setTotal(ctItem.quantity*ctItem.price);
  }

  return (
    <>
    <div className="mobile_modal_pad food_customisation_wrap">
      <div className="row">
        <div className="col-md-6 pl-md-0">
          <div className="food_item_thumb_wrap d-flex flex-column justify-content-around">
            <div className="food_item_thumb">
              <img src={ctItem?.image} alt="Food Image" onError={onImgError} />
              <p><strong>{ctItem?.extendedDescription}</strong></p>
            </div>
            {
              hasGL == 1 ?            
              <div className="food_customisation_wrap w-100" style={{paddingLeft: "25px", paddingRight: "25px"}}>
                <div className="food_selection_wrap">
                  <label className="form-label">Food & Beverage Service</label>
                  <Dropdown 
                    placeHolder={deliveryInfo?.SeatNumber} 
                    options={seat.map((s:any)=>({...s, value: s.SeatId, description: s.SeatId}))}
                    onChange={setSelecetedSeat}
                    parentIndex="0"
                    labelText="Deliver to Seat "
                    dropdownText="Seat "
                    />
                </div>
                <div className="food_selection_wrap">
                  <label className="form-label">Deliver At</label>
                  <Dropdown 
                    placeHolder={deliveryInfo?.Comment}
                    options={[
                      {
                        "value": "First Half",
                        "description": "First Half"
                      },
                      {
                        "choice": "Second Half",
                        "description": "Second Half"
                      },
                    ]} 
                    onChange={setDeliverTime}
                    parentIndex="1"
                    labelImage={true}
                    />
                </div>
              </div>   
              : null 
            }
          </div>
        </div>
        <div className="col-12 col-md-6 pl-md-0">
          {
            ctItem?.options.map((options: any, opt: any) => (
              <div>
                {
                  (options._comment != 'smartModifierGroups') ? 
                  <div className="food_selection_wrap">              
                  {
                    options.choices.map((choices: any, i: any) => (
                      <Dropdown 
                        placeHolder={
                          options.selected[i] ? 
                          options.selected[i].description :
                          choices.description
                        } 
                        options={choices.modifiers} 
                        onChange={setSelecetedFoodItem}
                        parentIndex={i}
                        />
                    ))
                  }                    
                  </div>
                : null
                }
                {
                  (options._comment == 'smartModifierGroups') ?                 
                  <div className="modifier_groups_wrap">
                    {
                    options.choices.map((choices: any, i: any) => (
                      <>
                      <h6>Ingredients (click to remove)</h6>
                      <ul className="custom_checkbox_wrap">
                        {
                        choices.modifiers.map((choice: any, j: any) => (
                          <li>
                            <div className="checkbox_black_custom">
                              <div className="state p-primary">
                                <label className="form-label">
                                  <div>{choice.description} {choice.price > 0 ? <span className="addon_amt">+
                                      ${choice.price}</span> : null}</div>
                                </label>
                              </div>
                              <input type="checkbox" value="1" checked={checkIngredient(choice)}
                                onChange={(event) => setSelecetedIngredients(event, choice, j)} className="check_black"/>
                            </div>
                          </li>
                         ))
                        }
                      </ul>
                      </>
                     ))
                    }
                  </div>
                  : null
                }
              </div>
            ))
          }          
          <div className="amt_qantity_wrap">
            <div className="quantity_wrap">
              <label>QUANTITY</label>
              <div className="input-group">
                <div className="input-group-prepend pointer"   onClick={onClickMinus}>
                  <span 
                    className={`btn_minus ${ctItem && ctItem.quantity > 1 && 'active'}`}
                  
                    >
                    &nbsp;</span>
                </div>
                <input type="number" className="form-control" aria-label="qantity" 
                  disabled 
                  value={quantity}
                  />
                <div className="input-group-append pointer"  onClick={onClickPlus}>
                  <span 
                    className={`btn_plus ${ctItem && ctItem.quantity >= 10 && 'active'}`}
                   
                    >
                    &nbsp;</span>
                </div>
              </div>
            </div>
            <div className="total_amt_wrap">
              <label>ITEM TOTAL</label>
              <p className="total_amt">${total?.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-12 movie_footer_btn_el">
      {
        !ctItem?.state ? (      
          <div className="movie_button_wrap">
            <button type="button" className="btn gray_btn" onClick={onBack}>
              BACK
            </button>
            <button type="button" className="btn black_btn"
              onClick={addToOrder}>
              ADD TO ORDER
            </button>
          </div>
        ) : (
          <div className="movie_button_wrap">
            <button type="button" className="btn gray_btn" onClick={() => onRemove(ctItem?.index)}>
              REMOVE ORDER
            </button>
            <button type="button" className="btn black_btn"
              onClick={addToOrder}>
              SAVE ORDER
            </button>
          </div>
        )
      }
    </div>
  </>
  );
};

export default CustomizeItems;

import { FC, useEffect, useMemo } from "react";
import { COUNTRY_IMAGE_CONFIG } from "../../../../constants/url";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { movieSessAttriImages } from "../../../../utils/helper";
import './sessionAttribute.scss';

interface SessionAttributeProps {
  sessionType: string;
  fromPage?: string;
}

const SessionAttribute: FC<SessionAttributeProps> = ({
  sessionType,
  fromPage
}) => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));

  const atrribute = useMemo(() => {
   return movieSessAttriImages(sessionType)
  }, [sessionType]);

  return (
    <>
      {atrribute.image ? (
        <div className={`session-attribute-comp ${fromPage}`}>
          <div className="session_experience">
            <img
              src={COUNTRY_IMAGE_CONFIG[countryId] + atrribute?.image}
            />
          </div>
          {atrribute?.desc ? (
              <div className="category_type">{atrribute?.desc}</div>
            ) : null}
        </div>
      ) : (
        <h4>{sessionType}</h4>
      )}
    </>
  );
};

export default SessionAttribute;


import axios from 'axios';
import { ENDPOINT } from '../endpoint';

export const getGiftCardApi = (payload: { cinemaId: string, countryId: string, requestType: string, giftType: string }) => {
  return axios.post(`${ENDPOINT.CONCESSION_ITEMS}`, payload)
}
export const getCardDesignApi = (payload: { countryId: string, subCategoryId: string }) => {
  return axios.post(`${ENDPOINT.CONCESSION_GET_CARD_DESIGN}`, payload)
}
export const getCardCategoriesApi = (payload: { countryId: string }) => {
  return axios.post(`${ENDPOINT.CONCESSION_GET_CARD_CATEGORIES}`, payload)
}
export const checkGiftCardBalanceApi = (payload: { cardNumber: string, countryId: string, requestType: string }) => {
  return new Promise(async (resolve, reject) => {
    return await axios.get(`${ENDPOINT.GIFT_CARD_BALANCE_CHECK}`, {
      params: {
        cardNumber: payload.cardNumber,
        countryId: payload.countryId,
        requestType: payload.requestType,
      }
    })
      .then((response: any) => {
        const {
          data: { data },
        } = response;
        if (data.balance) {
          return resolve({
            status: true,
            balance: data.balance,
            expiry: data.expiry
          });
        } else {
          return resolve({
            status: false,
            error: data,
          });
        }
      })
      .catch((error: any) => {
        return resolve({ status: false, error });
      });
  });
};

export const giftPromoApi = (payload: any) => {
    return axios.post(`${ENDPOINT.GIFT_PROMO}`, payload);
};

export const deliveryChargeApi = (payload: any) => {
  return axios.post(`${ENDPOINT.GIFTSHOP_SHIPPING}`, payload);
};



import React, { FC, useEffect, useState } from "react";
import "./quickTickets.scss";
import LocationDropDown from "../../DropDown/Location";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setQuickFilter } from "../../../../store/films/action";
import { getSignatureProgramsApi, quickFilterApi } from "../../../../services/films";
import {
  API_REQUEST_TYPE,
  COUNTRY_IMAGE_CONFIG,
} from "../../../../constants/url";
import { IDropDown } from "../../../../models/common";
import moment from "moment";
import { setReinitializeSlider } from "../../../../store/application/action";
import { sleep } from "../../../../utils/helper";
import { useNavigate } from "react-router";
import { COUNTRY, LOCATION_MAPPER } from "../../../../constants/app";
import { ISignatureProgramming } from "../../../../models/cinema";

interface IQuickTicketProps {
  cinemas: { label: string; value: string }[];
}

const QuickTickets: FC<IQuickTicketProps> = ({ cinemas }) => {
  const { countryId, brandId, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
      currentCinema: state.applicationReducer.currentCinema,
    })
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [movies, setMovies] = useState<IDropDown[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [format, setFormat] = useState<IDropDown[]>([]);
  const [sessions, setSessions] = useState<any[]>([]);
  const [signatureData, setSignatureData] = useState<ISignatureProgramming[]>(
    []
  );
  const [filter, setFilter] = useState<any>({
    location: currentCinema?.slug,
    movie: null,
    format: null,
    session: null,
    date: null,
    movieId: null,
  });

  const delay = async () => {
    await sleep(100);
    setShowFilter(true);
  };

  const getSignatureData = async () => {
    const {
      data: { data, statusCode },
    } = await getSignatureProgramsApi({
      cinemaId: currentCinema.slug,
      status: API_REQUEST_TYPE.GET_SIGNATURE_PROGRAMMING,
      countryId: countryId,
      flag: "",
    });
    if (statusCode === 200 && Array.isArray(data)) {
      return setSignatureData(data);
    } else {
      return [];
    }
  };

  useEffect(() => {
    onChangeLocation(currentCinema?.slug);
    delay();
    getSignatureData();
  }, []);

  const onChangeLocation = async (location: string) => {
    setFilter({
      ...filter,
      location: location,
      movie: null,
      format: null,
      session: null,
      date: null,
      movieId: null,
    });
    setMovies([]);
    setFormat([]);
    setSessions([]);
    const {
      data: { statusCode, data },
    } = await quickFilterApi({
      cinemaId: location,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      countryId,
    });

    if (statusCode === 200 && Array.isArray(data)) {
      setMovies(data.map((v: any) => ({ label: v.name, value: v.id })));
    }
  };

  const onChangeMovies = async (movie: string) => {
    setFilter({
      ...filter,
      movie: movie,
      format: null,
      session: null,
      date: null,
      movieId: null,
    });
    setFormat([]);
    setSessions([]);
    const {
      data: { statusCode, data },
    } = await quickFilterApi({
      cinemaId: filter.location,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      selectedMovie: movie,
      countryId,
    });

    if (statusCode === 200 && Array.isArray(data)) {

      const tempFormat = (data.map((v: any) => ({ label: v.name, value: v.name })));
      let signature: IDropDown[] = [];
      if (signatureData && signatureData.length) {
        const movesIds = movies.map((v:IDropDown)=>v.value);
        signature = signatureData.filter((v:ISignatureProgramming)=>(v.Films).split(',').includes(movie)).map((v: ISignatureProgramming) => ({
          label: v.Program_title,
          value: 'signature-'+v.Films,
        }));
      }
      setFormat([...tempFormat,...signature ]);
    }
  };

  const onChangeFormat = async (format: string) => {
    setFilter({
      ...filter,
      format: format,
      session: null,
      date: null,
      movieId: null,
    });
    setSessions([]);
    const {
      data: { statusCode, data },
    } = await quickFilterApi({
      cinemaId: filter.location,
      selectedMovie: filter.movie,
      [format.includes('signature-')?'movieGroupIds':'searchAttribute']: format.includes('signature-')?(format).split('-').pop():format ,
      status: API_REQUEST_TYPE.GET_FILM_DETAILS,
      countryId,
    });

    if (statusCode === 200 && Array.isArray(data)) {
      setSessions(
        data
          // .filter((v, i) => {
          //   return (
          //     data.findIndex(
          //       (s) =>
          //         moment(s.name, "YYYY-MM-DD").format("YYYY-MM-DD") ===
          //         moment(v.name, "YYYY-MM-DD").format("YYYY-MM-DD")
          //     ) === i &&
          //     moment(moment(v.name, "YYYY-MM-DD").format("YYYY-MM-DD")) >=
          //       moment(moment().format("YYYY-MM-DD"))
          //   );
          // })
          .map((v: any) => ({
            label: moment.parseZone(v.name).format(`MM/DD ddd hh:mmA`),
            value: v.id,
            date: `${moment(v.name, "YYYY-MM-DD").format("YYYY-MM-DD")}`,
          }))
      );
    }
  };

  const onChangeSession = async (id: string) => {
    const session = sessions.find((v) => v.value === id);
    setFilter({
      ...filter,
      session: null,
      date: session?.date,
      movieId: id,
    });
  };

  const onClickSearch = () => {
    dispatch(setQuickFilter(filter));
    dispatch(setReinitializeSlider(true));
    if (filter.location && filter.movie && filter.movieId && filter.date) {
      navigate(
        `/${LOCATION_MAPPER[countryId][filter.location].alias}/sessions/${
          filter.movieId
        }/${filter.movie}`
      );
    }
  };

  return (
    <div className={`quick-tickets-filter ${showFilter ? "show" : ""} ${
      countryId === COUNTRY.CON
        ? "consolidated"
        : countryId === COUNTRY.AFC
        ? "angelika-flim-center"
        : ""
    }`}>
      <div className="container-wrapper">
        <img
          className="filter-img"
          src={COUNTRY_IMAGE_CONFIG[countryId] + "quick-tickets-img.svg"}
        />
        <div className="filter-wrapper">
          <div className="filter-options location ">
            <div className="filter-label">
              <div style={{ minWidth: 18 }}>
                {" "}
                <img
                  className="filter-option-icons"
                  src={
                    COUNTRY_IMAGE_CONFIG[countryId] + "location-img-icon.svg"
                  }
                ></img>
              </div>
              <div className="filter-label-heading">WHERE</div>
            </div>
            <LocationDropDown
              onChange={onChangeLocation}
              options={
                countryId === COUNTRY.CON
                  ? cinemas.filter(
                      (v: any) => v.stateCode === currentCinema?.stateCode
                    ).map((v: any) => {
                      const city = {...v};
                      city.label = city.displayName;
                      return city;
                    })
                  : cinemas
              }
              placeholder={"Select your city"}
              noOptionsMessage={() => "No City found"}
              value={cinemas.find((v) => v.value === filter.location) || null}
              brandId={brandId}
              countryId={countryId}
              hideIcon={true}
              className="location-dropdown-us"
              fromPage="quickFilter"
            />
          </div>
          <div className="headerDivider"></div>
          <div className="filter-options">
            <div className="filter-label">
              <div style={{ minWidth: 24 }}>
                {" "}
                <img
                  className="filter-option-icons"
                  src={COUNTRY_IMAGE_CONFIG[countryId] + "movie-img-icon.svg"}
                ></img>
              </div>
              <div className="filter-label-heading">WHAT</div>
            </div>
            <LocationDropDown
              onChange={onChangeMovies}
              options={movies}
              placeholder={"Select your movie"}
              noOptionsMessage={() => "No Movies available"}
              value={movies.find((v) => v.value === filter.movie) || null}
              className="location-dropdown-us"
              fromPage="quickFilter"
            />
          </div>
          <div className="headerDivider"></div>
          <div className="filter-options">
            <div className="filter-label">
              <div style={{ minWidth: 23 }}>
                {" "}
                <img
                  className="filter-option-icons"
                  src={COUNTRY_IMAGE_CONFIG[countryId] + "format-img.svg"}
                ></img>
              </div>
              <div className="filter-label-heading">FORMAT</div>
            </div>
            <LocationDropDown
              onChange={onChangeFormat}
              options={format}
              placeholder={"Select your format"}
              noOptionsMessage={() => "No Format found"}
              value={format.find((v) => v.value === filter.format) || null}
              fromPage="quickFilter"
            />
          </div>
          <div className="headerDivider"></div>
          <div className="filter-options">
            <div className="filter-label">
              <div style={{ minWidth: 25 }}>
                <img
                  className="filter-option-icons"
                  src={
                    COUNTRY_IMAGE_CONFIG[countryId] + "calendar-img-icon.svg"
                  }
                ></img>
              </div>
              <div className="filter-label-heading">WHEN</div>
            </div>
            <LocationDropDown
              onChange={onChangeSession}
              options={sessions}
              placeholder={"Select your date & time"}
              noOptionsMessage={() => "No Session found"}
              value={sessions.find((v) => v.value === filter.movieId) || null}
              className="location-dropdown-us"
              fromPage="quickFilter"
            />
          </div>
        </div>
        <img
          className="filter-img pointer"
          onClick={onClickSearch}
          src={COUNTRY_IMAGE_CONFIG[countryId] + "search-icon-img.svg"}
        />
      </div>
    </div>
  );
};

export default QuickTickets;

import { FC, useEffect, useMemo, useState } from "react";
import TextInput from "../../../../components/UI/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  BRANDS,
  CAPTCHA_SITE_KEY,
  COUNTRY,
  TimeOfDayList,
  VACCINATION_LOCATIONS,
} from "../../../../constants/app";
import SelectDropdown from "../../../../components/UI/DropDown/SelectDropDown";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { useFormik } from "formik";
import { getMovieDetailsApi } from "../../../../services/films";
import ReCAPTCHA from "react-google-recaptcha";
import "./groupSale.scss";
import { formatMovieTypeName } from "../../../../utils/formatter";
import Calendar from "../../../../components/UI/Calendar";
import moment from "moment";
import { groupBookingApi } from "../../../../services/sideMenus";
import { toast } from "react-toastify";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";
import HelmetTitle from "../../../../components/Helmet";

interface IGroupBookingsProps {
  onCloseModal: () => void;
}

const GroupBookings: FC<IGroupBookingsProps> = ({ onCloseModal }) => {
  const { countryId, currentCinema, cinemas, brandId } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
      currentCinema: state.applicationReducer.currentCinema,
      cinemas: state.applicationReducer.cinemas,
    })
  );

  const dispatch = useDispatch();
  const [cinemaLocation, setCinemaLocation] = useState<any>([]);
  const [cinemaList, setCinemaList] = useState<any>([]);
  const [cinemasList, setCinemasList] = useState<any>([]);
  const [cinemaGoldlounge, setCinemaGoldlounge] = useState<number>(0);
  const [cinemaPremium, setCinemaPremium] = useState<number>(0);
  const [cinemaStandard, setCinemaStandard] = useState<number>(0);
  const [cinemaTitanluxe, setCinemaTitanluxe] = useState<number>(0);
  const [cinemaTitanxc, setCinemaTitanxc] = useState<number>(0);
  const [cinemaType, setCinemaTypes] = useState<any>([]);
  const [cinemaCategoryErr, setCinemaCategoryErr] = useState({
    cinemaCategoryErrStdFlag: false,
    cinemaCategoryErrTitanxcFlag: false,
    cinemaCategoryErrGoldFlag: false,
    cinemaCategoryErrPremiumFlag: false,
    cinemaCategoryErrTitanluxeFlag: false,
  });
  const [movieList, setMovieList] = useState([
    { label: "Other Movie", value: "Other Movie" },
  ]);

  useEffect(() => {
    if (cinemas) {
      const allCinemaLocation: any = [];
      const location: any = [];
      const cinemasArray: any = [];
      Object.keys(cinemas).forEach(function (key) {
        cinemasArray.push(cinemas[key]);
        cinemas[key].forEach((obj: any) => {
          location.push({
            label:
              brandId === BRANDS.US
                ? obj.name
                : `${obj.name}, ${obj.stateCode} `,
            value: `${obj.slug}`,
          });
          allCinemaLocation.push({
            slug: obj.slug,
            itemName: obj.Name + ", " + key,
            standardFlag: obj.standardFlag,
            goldFlag: obj.goldFlag,
            titanxcFlag: obj.titanxcFlag,
            premiumFlag: obj.premiumFlag,
            titanluxeFlag: obj.titanluxeFlag,
            goldMinSeat: obj.goldMinSeat,
            standardMinSeat: obj.standardMinSeat,
            premiumMinSeat: obj.premiumMinSeat,
            titanLuxeMinSeat: obj.TitanLuxeMinSeat,
            titanXcMinSeat: obj.TitanXcMinSeat,
          });
        });
      });
      setCinemaLocation(allCinemaLocation);
      setCinemaList(location);
      setCinemasList(cinemasArray);
    }
  }, [cinemas]);

  const informationStatus = useMemo(() => {
    return countryId !== COUNTRY.NZ &&
      VACCINATION_LOCATIONS.includes(currentCinema?.slug)
      ? true
      : false;
  }, [countryId]);

  const onCheckTerms = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("isTermsChecked", checked);
  };

  const getCinemaStandard = (data: any) => {
    setCinemaGoldlounge(0);
    setCinemaPremium(0);
    setCinemaStandard(0);
    setCinemaTitanluxe(0);
    setCinemaTitanxc(0);
    const cinemaErr = {
      cinemaCategoryErrStdFlag: false,
      cinemaCategoryErrTitanxcFlag: false,
      cinemaCategoryErrGoldFlag: false,
      cinemaCategoryErrPremiumFlag: false,
      cinemaCategoryErrTitanluxeFlag: false,
    };
    let selectedCinema: any;
    cinemaLocation.forEach((cinema: any) => {
      if (cinema?.slug === data) {
        selectedCinema = cinema;
      }
    });
    let stdDesc;
    let stdMin;
    if (selectedCinema.standardFlag) {
      stdDesc = "Minimum " + selectedCinema?.standardMinSeat! + " people";
      stdMin = selectedCinema.standardMinSeat;
      if (selectedCinema.standardMinSeat === "") {
        selectedCinema.standardMinSeat = 0;
        stdMin = 0;
      }
    } else {
      stdDesc = "CINEMA UNAVAILABLE";
      cinemaErr.cinemaCategoryErrStdFlag = true;
    }

    let goldDesc;
    let goldMin;

    if (selectedCinema.goldFlag) {
      goldDesc = "Minimum " + selectedCinema?.goldMinSeat! + " people";
      goldMin = selectedCinema.goldMinSeat;
      if (selectedCinema.goldMinSeat === "") {
        selectedCinema.goldMinSeat = 0;
        goldMin = 0;
      }
    } else {
      goldDesc = "CINEMA UNAVAILABLE";
      cinemaErr.cinemaCategoryErrGoldFlag = true;
    }

    let xcDesc;
    let xcMin;
    if (selectedCinema.titanxcFlag) {
      xcDesc = "Minimum " + selectedCinema?.titanXcMinSeat! + " people";
      xcMin = selectedCinema.titanXcMinSeat;
      if (selectedCinema.titanXcMinSeat === "") {
        selectedCinema.titanXcMinSeat = 0;
        xcMin = 0;
      }
    } else {
      xcDesc = "CINEMA UNAVAILABLE";
      cinemaErr.cinemaCategoryErrTitanxcFlag = true;
    }

    let preDesc;
    let preMin;
    if (selectedCinema.premiumFlag) {
      preDesc = "Minimum " + selectedCinema?.premiumMinSeat! + " people";
      preMin = selectedCinema.premiumMinSeat;
      if (selectedCinema.premiumMinSeat === "") {
        selectedCinema.premiumMinSeat = 0;
        preMin = 0;
      }
    } else {
      preDesc = "CINEMA UNAVAILABLE";
      cinemaErr.cinemaCategoryErrPremiumFlag = true;
    }

    let luxeDesc;
    let luxeMin;
    if (selectedCinema.titanluxeFlag) {
      luxeDesc = "Minimumm " + selectedCinema?.titanLuxeMinSeat! + " people";
      luxeMin = selectedCinema.titanLuxeMinSeat;
      if (selectedCinema.titanLuxeMinSeat === "") {
        selectedCinema.titanLuxeMinSeat = 0;
        luxeMin = 0;
      }
    } else {
      luxeDesc = "CINEMA UNAVAILABLE";
      cinemaErr.cinemaCategoryErrTitanluxeFlag = true;
    }
    setCinemaCategoryErr({ ...cinemaErr });
    setCinemaTypes([
      {
        name: "STANDARD",
        desc: stdDesc,
        counter: 0,
        counterValue: stdMin,
        min: stdMin,
      },
      {
        name: "TITAN XC",
        desc: xcDesc,
        counter: 0,
        counterValue: xcMin,
        min: xcMin,
      },
      {
        name: "GOLD LOUNGE",
        desc: goldDesc,
        counter: 0,
        counterValue: goldMin,
        min: goldMin,
      },
      {
        name: "PREMIUM",
        desc: preDesc,
        counter: 0,
        counterValue: preMin,
        min: preMin,
      },
      {
        name: "TITAN LUXE",
        desc: luxeDesc,
        counter: 0,
        counterValue: luxeMin,
        min: luxeMin,
      },
    ]);
  };

  const increment = (list: any, name: any) => {
    if (list.counterValue < 999) {
      list.counterValue++;
      list.counter = list.counterValue;
      setCinemaCategory(list, name);
    }
  };

  const decrement = (list: any, name: any) => {
    const initialValue = list.min;
    if (list.counter > initialValue) {
      list.counterValue--;
      list.counter = list.counterValue;
    }
    setCinemaCategory(list, name);
  };

  const changeCinemaCategory = (list: any, event: any, name: any) => {
    let inputValue = Math.round(+event.target.value);
    inputValue = isNaN(inputValue) ? 0 : inputValue;
    if (inputValue < list.min) {
      list.counter = list.min;
    } else {
      list.counter = inputValue;
    }
    list.counterValue = list.counter;
    event.target.value = list.counter;
    setCinemaCategory(list, name);
  };

  const setCinemaCategory = (list: any, name: any) => {
    const value = list.counter;
    let tempCinema = cinemaType.map((v: any, i: number) => {
      if (list.name !== cinemaType[i].name) {
        v.counterValue = v.min;
        v.counter = 0;
      }
      return v;
    });
    setCinemaGoldlounge(0);
    setCinemaTitanxc(0);
    setCinemaTitanluxe(0);
    setCinemaStandard(0);
    setCinemaPremium(0);
    setCinemaTypes(tempCinema);
    if (name === "standard") {
      setCinemaStandard(value);
    } else if (name === "premium") {
      setCinemaPremium(value);
    } else if (name === "titan_luxe") {
      setCinemaTitanluxe(value);
    } else if (name === "titan_xc") {
      setCinemaTitanxc(value);
    } else if (name === "gold_lounge") {
      setCinemaGoldlounge(value);
    }
  };

  const handleBlur = (list: any, event: any, name: any) => {
    let value = Math.round(Number(event.target.value));
    value = isNaN(value) ? 0 : value;
    if (value < list.min) {
      value = list.min;
    }
    // const [inputValue, setInputValue] = useState(Math.round(Number(event.target.value)));
    event.target.value = value;
    list.counter = value;
    list.counterValue = value;

    setCinemaCategory(list, name);
  };

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  const cinemaCatErr = useMemo(() => {
    if (
      cinemaCategoryErr.cinemaCategoryErrStdFlag &&
      cinemaCategoryErr.cinemaCategoryErrTitanxcFlag &&
      cinemaCategoryErr.cinemaCategoryErrGoldFlag &&
      cinemaCategoryErr.cinemaCategoryErrPremiumFlag &&
      cinemaCategoryErr.cinemaCategoryErrTitanluxeFlag
    ) {
      return "Cinema category is unavailable";
    } else if (
      cinemaGoldlounge === 0 &&
      cinemaPremium === 0 &&
      cinemaStandard === 0 &&
      cinemaTitanluxe === 0 &&
      cinemaTitanxc === 0
    ) {
      return "Please enter the no of people";
    } else {
      return "";
    }
  }, [
    cinemaCategoryErr,
    cinemaGoldlounge,
    cinemaPremium,
    cinemaStandard,
    cinemaTitanluxe,
    cinemaTitanxc,
  ]);

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    validationSchema: createValidationSchema("groupBooking"),
    enableReinitialize: true,
    initialValues: {
      approximateTime: "",
      cinema: "",
      cinemaGoldlounge: 0,
      cinemaPremium: 0,
      cinemaStandard: 0,
      cinemaTitanluxe: 0,
      cinemaTitanxc: 0,
      comments: "",
      companyName: "",
      contactAltNumber: "",
      contactNumber: "",
      countryId: countryId,
      date: "",
      email: "",
      movie: "",
      movieTitle: "",
      name: "",
      otherMovie: "",
      standard: "",

      noofpeople: "",
      openCinema: false,
      isTermsChecked: false,
      captcha: false,
    },
    onSubmit: async (values) => {
      if (cinemaCatErr) {
        return;
      }

      const payload = {
        approximateTime: values.approximateTime,
        cinema: values.cinema,
        cinemaGoldlounge: cinemaGoldlounge,
        cinemaPremium: cinemaPremium,
        cinemaStandard: cinemaStandard,
        cinemaTitanluxe: cinemaTitanluxe,
        cinemaTitanxc: cinemaTitanxc,
        comments: values.comments,
        companyName: values.companyName,
        contactAltNumber: values.contactAltNumber,
        contactNumber: values.contactNumber,
        countryId: countryId,
        date: moment(values.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
        movie_date: moment(values.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
        email: values.email,
        movie: values.movie ? values.movie : values.movieTitle,
        name: values.name,
        standard: "",
      };

      const { data } = await groupBookingApi(payload);
      if (data) {
        toast.success("Group booking submitted successfully.");
        onCloseModal();
      } else {
        toast.error(
          "Could not connect to services at the moment. Please try again. Thanks.'"
        );
      }
    },
  });

  const onChangeCinema = async (value: string) => {
    getCinemaStandard(value);
    const {
      data: { data },
    } = await getMovieDetailsApi(countryId, value);
    if (data.length > 0) {
      const movieList = data.map((item: any) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
      movieList.push({ label: "Other Movie", value: "Other Movie" });
      setMovieList(movieList);
    } else {
      setMovieList([]);
    }
  };

  const onChangeMovie = (value: string) => {
    setFieldValue("movieTitle", "");
  };

  const openTerms = () => {
    dispatch(
      setContentModal({
        type: "Group Booking Terms",
        prevModal: ROUTES.GROUP_SALE,
      })
    );
    dispatch(toggleContentModal(true));
  };

  return (
    <div className="group-booking">
      <HelmetTitle title="Group Booking" description="Group Booking"/>
      <div className="modal-header">
        <div className="title title_append">
          <h3>Group Booking</h3>
        </div>
      </div>
      <div className="modal-body">
        <div className="login-form form_page">
          <div className="row">
            <div className="col-6 col-md-6 ">
              <label className="form-label">Name*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.name}
                  field={"name"}
                  handleChange={handleChange}
                  touched={touched.name!}
                  error={errors.name}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Company Name</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.companyName}
                  field={"companyName"}
                  handleChange={handleChange}
                  touched={touched.companyName!}
                  error={errors.companyName}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Contact Number 1</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.contactNumber}
                  field={"contactNumber"}
                  handleChange={handleChange}
                  touched={touched.contactNumber!}
                  error={errors.contactNumber}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Contact Number 2</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.contactAltNumber}
                  field={"contactAltNumber"}
                  handleChange={handleChange}
                  touched={touched.contactAltNumber!}
                  error={errors.contactAltNumber}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label">Email*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.email}
                  field={"email"}
                  handleChange={handleChange}
                  touched={touched.email!}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label empty">Cinema</label>
              <SelectDropdown
                // placeholder="Cinema"
                field={"cinema"}
                options={cinemaList}
                setFieldValue={setFieldValue}
                touched={touched.cinema!}
                error={errors.cinema}
                value={values.cinema}
                onChange={onChangeCinema}
              />
            </div>
            <div className="col-6 col-md-6 ">
              <label className="form-label empty">Movie</label>
              <SelectDropdown
                field={"movie"}
                options={movieList}
                setFieldValue={setFieldValue}
                touched={touched.movie!}
                error={errors.movie}
                value={values.movie}
                onChange={onChangeMovie}
              />
            </div>
            {values.movie === "Other Movie" ? (
              <div className="col-6 col-md-6 ">
                <label className="form-label">Movie Title:*</label>
                <div className="form-group pass_show">
                  <TextInput
                    value={values.movieTitle}
                    field={"movieTitle"}
                    handleChange={handleChange}
                    touched={touched.movieTitle!}
                    error={errors.movieTitle}
                  />
                </div>
              </div>
            ) : null}

            {values.cinema ? (
              <div className="col-12 col-md-12">
                <div className="mobile_scroll mt-3">
                  <ul className="title_row listing">
                    {cinemaType.map((list: any, i: number) => (
                      <li className="each-row std_div activerow">
                        <span className="title">{list.name}</span>
                        <span className="sub_title">{list.desc}</span>
                        {list.desc !== "CINEMA UNAVAILABLE" ? (
                          <div className="count_btn_wrap">
                            <button
                              onClick={() =>
                                decrement(
                                  list,
                                  list?.name.toLowerCase().split(" ").join("_")
                                )
                              }
                              className="btn_minus"
                            ></button>
                            <input
                              type="text"
                              className="count_no"
                              maxLength={3}
                              value={
                                formatMovieTypeName(list?.name) === "standard"
                                  ? cinemaStandard
                                  : formatMovieTypeName(list?.name) ===
                                    "premium"
                                  ? cinemaPremium
                                  : formatMovieTypeName(list?.name) ===
                                    "titan_luxe"
                                  ? cinemaTitanluxe
                                  : formatMovieTypeName(list?.name) ===
                                    "titan_xc"
                                  ? cinemaTitanxc
                                  : formatMovieTypeName(list?.name) ===
                                    "gold_lounge"
                                  ? cinemaGoldlounge
                                  : 0
                              }
                              onChange={(event) =>
                                formatMovieTypeName(list?.name) === "premium"
                                  ? setCinemaPremium(+event.target.value)
                                  : formatMovieTypeName(list?.name) ===
                                    "titan_luxe"
                                  ? setCinemaTitanluxe(+event.target.value)
                                  : formatMovieTypeName(list?.name) ===
                                    "titan_xc"
                                  ? setCinemaTitanxc(+event.target.value)
                                  : formatMovieTypeName(list?.name) ===
                                    "gold_lounge"
                                  ? setCinemaGoldlounge(+event.target.value)
                                  : setCinemaStandard(+event.target.value)
                              }
                              onBlur={(event) =>
                                changeCinemaCategory(
                                  list,
                                  event,
                                  list.name.toLowerCase().split(" ").join("_")
                                )
                              }
                            />
                            <button
                              onClick={() =>
                                increment(
                                  list,
                                  list.name.toLowerCase().split(" ").join("_")
                                )
                              }
                              className="btn_plus"
                            ></button>
                          </div>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
                {cinemaCatErr ? (
                  <div className="error_message text-center">
                    <span>{cinemaCatErr}</span>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="col-6 col-md-6 ">
              <label className="form-label">Date*</label>
              <div className="form-group form-group-date-field">
                <Calendar
                  minDate={new Date()}
                  field={"date"}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  touched={touched.date!}
                  error={errors.date}
                />
              </div>
            </div>
            <div className="col-6 col-md-6">
              <label className="form-label">Time of Day</label>
              <div className="form-group">
                <SelectDropdown
                  field={"approximateTime"}
                  options={TimeOfDayList}
                  value={values.approximateTime}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  touched={touched.approximateTime!}
                  error={errors.approximateTime}
                />
              </div>
            </div>
            <div className="col-12 col-md-12">
              <label className="form-label">Comments: </label>
              <div className="form-group">
                <textarea
                  title="Enter your Comments"
                  name="comments"
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  aria-label="Comments"
                />
              </div>
            </div>
            <div className="col-12  col-md-12">
              <label className="form-label">Verification Code* </label>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${CAPTCHA_SITE_KEY}`}
                  onChange={onChangeCaptcha}
                />
                {touched.captcha && errors.captcha ? (
                  <div className="error_message">{`${errors?.captcha}`}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="custom_checkbox option_link">
                <input
                  type="checkbox"
                  value="1"
                  checked={values.isTermsChecked}
                  onClick={onCheckTerms}
                />
                <div className="state p-primary">
                  <label className="form-label">
                    <div className="mx-2">
                      {countryId === COUNTRY.STA ? (
                        <>
                          <span>
                            I have read and understand the State Cinema Internet
                            Ticket Sales{" "}
                          </span>
                          <span className="non_atag_link_u" onClick={openTerms}>
                            Terms and conditions
                          </span>
                        </>
                      ) : countryId === COUNTRY.ANG ? (
                        <>
                          <span>
                            I have read and understand the Angelika Internet
                            Ticket Sales{" "}
                          </span>
                          <span className="non_atag_link_u" onClick={openTerms}>
                            Terms and conditions
                          </span>
                        </>
                      ) : brandId === BRANDS.US ? (
                        <>
                          <span>I have read & understand the{" "}</span>
                          <span className="non_atag_link_u" onClick={openTerms}>
                            Terms & Conditions
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            I have read and understand the Reading Internet
                            Ticket Sales{" "}
                          </span>
                          <span className="non_atag_link_u" onClick={openTerms}>
                            Terms and conditions
                          </span>
                        </>
                      )}

                      {informationStatus ? (
                        <span>
                          including vaccination requirements as outlined above
                        </span>
                      ) : null}
                    </div>
                  </label>
                </div>
              </div>
              {touched.isTermsChecked && errors.isTermsChecked ? (
                <div className="error_message">{`${errors?.isTermsChecked}`}</div>
              ) : null}
            </div>
            <div className="col-md-12 mt-4">
              <div className="button_wrap text-center div">
                <button
                  type="button"
                  className="btn gray_btn back-btn mx-1"
                  onClick={onCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn black_btn mx-2"
                  onClick={() => handleSubmit()}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupBookings;

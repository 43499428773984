
import axios from 'axios';
import { ENDPOINT } from '../endpoint';

export const getSettingsApi = (payload: { countryId: string }) => {
    return axios.get(`${ENDPOINT.SETTINGS}/${payload.countryId}`).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const getCinemasApi = (payload: { countryId: string }) => {
    return axios.get(`${ENDPOINT.GET_CINEMAS}`, { params: { countryId: payload.countryId } }).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const getSlidesApi = (payload: { countryId: string, pageName: string, location: string }) => {
    return axios.get(`${ENDPOINT.SLIDES}`, { params: { countryId: payload.countryId, pageName: payload.pageName, location: payload.location?payload.location:'' } }).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}
export const getPromoApi = (payload: { countryId: string , flag?:string}) => {
    return axios.get(`${ENDPOINT.PROMOAD}`, { params: { countryId: payload.countryId, flag: payload.flag} }).then(response => ({ status: true, response })).catch(error => ({ status: false, error }))
}

export const getContactListApi = (payload: { countryId: string}) => {
    return axios.post(`${ENDPOINT.CONTACT_LIST}`, payload);
}

export const getHomeContentApi = (payload: { countryId: string, cinemaId: string}) => {
    return axios.get(ENDPOINT.SPECIAL_VALUE, { params: { countryId: payload.countryId, cinemaId: payload.cinemaId, status:'homeContent' } }).then(response => response.data).catch(error => ({ status: false, error }))
}

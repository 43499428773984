import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { IPlf } from "../../../../models/cinema";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { plfApi } from "../../../../services/films";
import "./plfProgramming.scss";
import { COUNTRY, FLIM_TYPE } from "../../../../constants/app";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/path";

interface IplfProgrammingProps {}
const PlfProgramming: FC<IplfProgrammingProps> = () => {
  const { countryId, currentCinema, homeContent } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
    homeContent: state.applicationReducer.homeContent
  }));

  const navigate = useNavigate();
  const [plf, setPlf] = useState<IPlf[]>([]);

  useEffect(() => {
    setPlf(homeContent.plf?homeContent.plf:[]);
  }, [homeContent]);

  const showSession = (data: IPlf) => {
    const flagType = FLIM_TYPE.find((v) => v.id === +data.filmType)!;
    if (flagType && flagType.alias) {
      navigate(
        `/${currentCinema?.alias}/${ROUTES.MOVIES}/${
          flagType ? flagType.alias : ""
        }`
      );
    }
  };

  return (
    <>
      {plf.length > 0
        ? plf.map((v, index: number) => (
            <section
              key={index}
              className="titan-luxe-section"
              style={{
                background: v.image
                  ? `url(${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.image}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT})`
                  : "none",
              }}
            >
              <div className="container-content">
                <div className={`title` }>
                  <HtmlRender
                    content={v.Title?.replace(
                      /TITAN LUXE/g,
                      ` <img
                      src="${
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "titanluxe_white_new.svg"
                      }"
                    />`
                    ).replace(
                      /TITAN XC/g,
                      ` <img
                      src="${
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "titanxc_white.svg"
                      }"
                    />`
                    ).replace(
                      /IMAX/g,
                      ` <img
                      src="${
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "imax_white.svg"
                      }"
                    />`
                    )}
                  />
                </div>
                <div className={`titan-luxe-content ${countryId === COUNTRY.CON?'consolidated':''}`}>
                  <HtmlRender
                    content={v.Description?.replace(
                      /TITAN LUXE/g,
                      ` <img
                      margin: 0px 5px;
                      top: -3px;
                      position: relative;"
                      src="${
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "titanluxe_white_new.svg"
                      }"
                    />`
                    ).replace(
                      /TITAN XC/g,
                      ` <img
                      margin: 0px 5px;
                      top: -3px;
                      position: relative;"
                      src="${
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "titanxc_white.svg"
                      }"
                    />`
                    )}
                  />
                </div>
                <div className="d-flex justify-content-center my-3">
                  <button className="titan-btn" onClick={() => showSession(v)}>
                    {v.Button}
                  </button>
                </div>
              </div>
            </section>
          ))
        : null}
    </>
  );
};

export default PlfProgramming;

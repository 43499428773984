import { FC, useEffect, useState } from "react";
import "./signupBanner.scss";
import {
  setModal,
  toggleTicketModal,
} from "../../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import {
  showLogin,
  updateMembbershipPurchase,
} from "../../../../store/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { ILoyaltyBanner, ILoyaltyPackage } from "../../../../models/auth";
import { getMembershipPackageApi } from "../../../../services/auth";
import { RootState } from "../../../../store";
import { COUNTRY } from "../../../../constants/app";
import { LOYALTY_BANNER } from "../../../../constants/loyalty";

interface ISignupBannerProps {
  onSignup: () => void;
  onCancel: () => void;
}

const SignupBanner: FC<ISignupBannerProps> = ({ onSignup, onCancel }) => {
  const { countryId, currentCinema, settings } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId as COUNTRY,
    currentCinema: state.applicationReducer.currentCinema,
    settings: state.applicationReducer.settings,
  }));

  const dispatch = useDispatch();
  const [packages, setPackages] = useState<ILoyaltyPackage[]>([]);

  const getPackageContent = async () => {
    const {
      data: {
        data: { loyaltyMembershipPackages },
        status,
      },
    } = await getMembershipPackageApi({
      countryId,
      cinemaId: currentCinema?.slug,
    });

    if (loyaltyMembershipPackages && loyaltyMembershipPackages.length > 0) {
      setPackages(loyaltyMembershipPackages);
    }
  };

  useEffect(() => {
    if (countryId && currentCinema) {
      getPackageContent();
    }
  }, [countryId, currentCinema]);

  const onSignupGold = () => {
    const loyaltyPackage = packages.find((v: ILoyaltyPackage) => {
      return v.activationData && Number(v.levelId) === (settings.membership.membershipLevelId || 4);
    });
    dispatch(
      updateMembbershipPurchase({
        membershipType: "new",
        clubId: loyaltyPackage ? loyaltyPackage.clubId : 0,
        loyaltyPackage: loyaltyPackage ? loyaltyPackage.activationData : null,
      })
    );
    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
          MODAL_TYPE.MEMBERSHIP_AUTH
        ],
        type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
        data:'account'
      })
    );
    dispatch(toggleTicketModal(true));
    dispatch(showLogin(false));
  };

  return (
    <div>
      <div className="gold_club_signup">
        {LOYALTY_BANNER[countryId].map((v:ILoyaltyBanner)=> <div className="gold_module" style={{backgroundColor:v.bgColor}}>
          <div className="gold_title">{v.header}</div>
          <div className="fee_details">{v.fee}</div>
          <div>
            <ul>
              {v.desc1.map(d=>
                <li className="bold_text">• {d}</li>)}
            </ul>
            <div className="wrapper">
              <ul>
              {v.desc2.map(d=>
                <li className="text-center">• {d}</li>)}
              </ul>
            </div>
          </div>
          <button className="signup-black_btn" onClick={v.type === 'gold'?onSignupGold:onSignup}>
           {v.buttonLabel}
          </button>
          <div className="my-2">{v.footer}</div>
        </div>)}

        <button type="button" className="gray_btn mt-4" onClick={onCancel}>
          CANCEL
        </button>
      </div>
    </div>
  );
};

export default SignupBanner;

import { FC, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../../../../constants/app";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import TextInput from "../../../../components/UI/TextInput";
import { toast } from "react-toastify";

interface IEmailUpdateProps {
  onCloseModal: any;
}

const EmailUpdate: FC<IEmailUpdateProps> = ({ onCloseModal }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("employment"),
      initialValues: {
        givenName: "",
        surName: "",
        email: "",
        captcha: "",
      },
      onSubmit: async (values) => {
        // const { data } = await joinMailListApi(values);
        // if (data) {
        //   toast.success("Employment details submitted successfully.");
        //   onCloseModal();
        // } else {
        //   toast.error(
        //     "Could not connect to services at the moment. Please try again. Thanks."
        //   );
        // }
      },
    });

  return (
    <div className="modal-body">
      <div className="row">
        <div>
          <div className="login-form form_page">
            <div className="row">
              <div className="col-12 col-md-12">
                <p>
                  <strong>
                    Signup to receive updates about movies and promotions.
                  </strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <label className="form-label">First Name*</label>
                <div className="form-group pass_show">
                  <TextInput
                    field={"givenName"}
                    handleChange={handleChange}
                    touched={touched.givenName!}
                    error={errors.givenName}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <label className="form-label">Last Name</label>
                <div className="form-group pass_show">
                  <TextInput
                    field={"surName"}
                    handleChange={handleChange}
                    touched={touched.surName!}
                    error={errors.surName}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <label className="form-label">Email*</label>
                <div className="form-group pass_show">
                  <TextInput
                    field={"email"}
                    handleChange={handleChange}
                    touched={touched.email!}
                    error={errors.email}
                  />
                </div>
                <div className="col-12  col-md-12">
                  <label className="form-label">Verification Code* </label>
                  <div className="form-group">
                    <ReCAPTCHA
                      sitekey={`${CAPTCHA_SITE_KEY}`}
                      onChange={onChangeCaptcha}
                    />
                    {touched.captcha && errors.captcha ? (
                      <div className="error_message">{`${errors?.captcha}`}</div>
                    ) : null}
                  </div>
                </div>
                {errorMessage ? (
                  <div className="err-message">{errorMessage} </div>
                ) : null}
                <div className="movie_button_wrap">
                  <input
                    type="button" onClick={()=>handleSubmit()}
                    className="btn black_btn m-r-20"
                   
                  />
                  <button type="button" className="btn gray_btn" onClick={onCloseModal}>
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailUpdate;

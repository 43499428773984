import { IMovieData, IShows } from "../../models/cinema";

export interface IFilms {
    nowShowing: IShows[],
    comingSoon: IShows[],
    advanceTicket: IShows[],
    signatureProgram: IShows[],
    titanMovies: IMovieData[],
    quickFilters: {
        location: string | null,
        movie: string | null,
        format: string | null,
        session: string | null,
        date: string | null
    },
    searchFilter: string,
    nowshowingLoader: boolean,
    nowshowingFilterData: {
        format:[],
        session:[]
    },
    loaderNowshowing: boolean,
    loaderComingsoon: boolean,
    loaderAdvanceticket: boolean
}


export const ACTIONS = {
    GET_NOW_SHOWING_WATCHER: 'GET_NOW_SHOWING_WATCHER',
    GET_NOW_SHOWING_SUCCESS: 'GET_NOW_SHOWING_SUCCESS',
    GET_NOW_SHOWING_FAILURE: 'GET_NOW_SHOWING_FAILURE',
    SET_NOW_SHOWING_LOADER: 'SET_NOW_SHOWING_LOADER',
    SET_LOADER_NOWSHOWING: 'SET_LOADER_NOWSHOWING',
    SET_LOADER_COMINGSOON: 'SET_LOADER_COMINGSOON',
    SET_LOADER_ADVANCETICKET: 'SET_LOADER_ADVANCETICKET',

    GET_NOW_SHOWING_FILTER_DATA_SUCCESS: 'GET_NOW_SHOWING_FILTER_DATA_SUCCESS',
    GET_NOW_SHOWING_FILTER_DATA_FAILURE: 'GET_NOW_SHOWING_FILTER_DATA_FAILURE',

    GET_COMING_SOON_WATCHER: 'GET_COMING_SOON_WATCHER',
    GET_COMING_SOON_SUCCESS: 'GET_COMING_SOON_SUCCESS',
    GET_COMING_SOON_FAILURE: 'GET_COMING_SOON_FAILURE',

    GET_COMING_SOON_TICKET_WATCHER: 'GET_COMING_SOON_TICKET_WATCHER',
    GET_COMING_SOON_TICKET_SUCCESS: 'GET_COMING_SOON_TICKET_SUCCESS',
    GET_COMING_SOON_TICKET_FAILURE: 'GET_COMING_SOON_TICKET_FAILURE',

    GET_ADVANCE_TICKET_WATCHER: 'GET_ADVANCE_TICKET_WATCHER',
    GET_ADVANCE_TICKET_SUCCESS: 'GET_ADVANCE_TICKET_SUCCESS',
    GET_ADVANCE_TICKET_FAILURE: 'GET_ADVANCE_TICKET_FAILURE',

    SET_QUICK_FILTER: 'SET_QUICK_FILTER',

    GET_SIGNATURE_PROGRAM_WATCHER: 'GET_SIGNATURE_PROGRAM_WATCHER',
    GET_SIGNATURE_PROGRAM_SUCCESS: 'GET_SIGNATURE_PROGRAM_SUCCESS',
    GET_SIGNATURE_PROGRAM_FAILURE: 'GET_SIGNATURE_PROGRAM_FAILURE',

    GET_TITAN_MOVIES_WATCHER: 'GET_TITAN_MOVIES_WATCHER',
    GET_TITAN_MOVIES_SUCCESS: 'GET_TITAN_MOVIES_SUCCESS',
    GET_TITAN_MOVIES_FAILURE: 'GET_TITAN_MOVIES_FAILURE',

    SEARCH_MOVIE: 'SEARCH_MOVIE',
    SET_SHOWS: 'SET_SHOWS'
}
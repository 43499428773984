import { IShows } from "../../models/cinema";

export interface Giftcards {
    currentTab: string,
    currentTabId: string,
    currentAction: string,
    cards: ICard[] | IPhysicalCard[];
    cardCategory: ICardCategory[];
    cardDesigns: ICardDesign[];
    cart: ICartData[];
    banner: IGiftshopBanner;
    orderResponse: IOrderResponse | null;
    bookingId: string;
    loader: boolean;
    error: any;
    giftFormVisible: boolean,
    giftModalVisible: boolean,
    giftPromo: IGiftCardPromo[],
    promoPopupData: { card: ICartData, index: number } | null
}
export interface ICardCategory {
    isDelete: string
    category_tc: string
    category_name: string
    countryId: string
    sortorder: number
    id: string
    category_type: number
    status: string
}

export interface ICard {
    IsVariablePriceItem: boolean
    MaximumVariablePriceInCents: number
    MinimumVariablePriceInCents: number
    description: string
    id: string
    image: string
    price: string
    shortImage: string
    tax: number
    name?: string
}

export interface IPhysicalCard {
    archive: number
    countryId: number
    description: string
    expiry_date: any
    giftcard_category_Id: number
    id: string
    image: string
    name: string
    price: number
    sortorder: number
    type: number
    vista_Id: null | string
    minimum_amount: number
}

export interface IGiftshopBanner {
    mobileImageUrl: string
}

export interface ICardDesign {
    countryId: string
    design_name: string
    expiry_date: any
    archive: number,
    id: string
    images: IImages[]
    sortorder: number
    value: string
    subCategoryId: string
}

export interface IImages {
    id: number
    name: string
}

export interface ICardData {
    cardId: string,
    cardName: string,
    image: string,
    img: string,
    value: string,
    qty: number,
    amount: number,
    IsVariablePriceItem: boolean,
    MaximumVariablePriceInCents: number,
    MinimumVariablePriceInCents: number,
}

export interface ICardFormated {
    cardId: string
    cardName: string
    description: string
    image: string
    qty: number
    price: number,
    tax: number,
    isVariablePriceItem: boolean,
    maximumVariablePriceInCents: number,
    minimumVariablePriceInCents: number,
    imgId?: string | number,
    type?: number | string
}

export interface ICartData {
    deliveryDate: string
    recipientName: string,
    confirmRecipientEmail: string,
    recipientemail: string,
    senderName: string,
    address1: string,
    city: string,
    state: string,
    postCode: string,
    address2: string,
    giftMessage: string,
    quantity: number,
    giftamount: number,
    id: string,
    image: string,
    imgId: string,
    type: number | string,
    description: string,
    price: number,
    tax: number,
    giftcardType: number,
    index: string,
    itemTotal: number,
    isVariablePriceItem: boolean,
    minimumVariablePriceInCents: number,
    maximumVariablePriceInCents: number,
    name: string,
    carddesign: string,
    eGiftcardDesign: string,
    promoType?: string,
    promo?: any,
    parentIndex?: number
    shippingDetails?: {
        name: string,
        shippingFee: string,
        id: string
    } | null
}

export interface IOrderResponse {
    cinemaId: string
    countryId: number
    userSessionId: string
}

export interface IGiftCardPromo {
    minimumSpendFlag: number,
    minimumSpendAmount: number,
    cardTypeRestriction: string,
    online: number,
    designRestriction: string,
    scheduleDate: null | any,
    showhidetc: string,
    scheduleTime: null | any,
    name: string,
    archiveTime: string,
    limitAmount: number,
    archiveAutomatically: number,
    isArchive: number,
    promoLimitation: string,
    archiveDate: any,
    scheduleAutomatically: number,
    amount: number,
    promoTo: string,
    id: string,
    uniqueKey: string
}

export const ACTIONS = {
    GET_CARD_WATCHER: 'GET_CARD_WATCHER',
    GET_CARD_SUCCESS: 'GET_CARD_SUCCESS',
    GET_CARD_ERROR: 'GET_CARD_ERROR',
    SET_GIFT_SHOP_TAB: "SET_GIFT_SHOP_TAB",
    SET_GIFT_SHOP_TAB_ID: "SET_GIFT_SHOP_TAB_ID",
    GET_CARD_DESIGN_WATCHER: 'GET_CARD_DESIGN_WATCHER',
    GET_CARD_DESIGN_SUCCESS: 'GET_CARD_DESIGN_SUCCESS',
    GET_CARD_DESIGN_ERROR: 'GET_CARD_DESIGN_ERROR',
    GET_CARD_CATEGORY_WATCHER: 'GET_CARD_CATEGORY_WATCHER',
    GET_CARD_CATEGORY_SUCCESS: "GET_CARD_CATEGORY_SUCCESS",
    GET_CARD_CATEGORY_ERROR: "GET_CARD_CATEGORY_ERROR",
    ADD_GIFT_CART: 'ADD_GIFT_CART',
    SET_GIFT_SHOP_ACTION: 'SET_GIFT_SHOP_ACTION',
    SET_GIFT_FORM_VISIBLE: 'SET_GIFT_FORM_VISIBLE',
    SET_GIFT_MODAL_VISIBLE: 'SET_GIFT_MODAL_VISIBLE',
    UPDATE_GIFT_CARD_BOOKING_ID: 'UPDATE_GIFT_CARD_BOOKING_ID',
    CLEAR_GIFT_CARD_ORDERS: 'CLEAR_GIFT_CARD_ORDERS',
    SET_TRANSACTION_ERROR: 'SET_TRANSACTION_ERROR',
    GIFT_CARD_ORDER_SUCCESS: 'GIFT_CARD_ORDER_SUCCESS',
    GIFT_CARD_PROMO_WATCHER: 'GIFT_CARD_PROMO_WATCHER',
    GIFT_CARD_PROMO_SUCCESS: 'GIFT_CARD_PROMO_SUCCESS',
    GIFT_CARD_PROMO_FAILURE: 'GIFT_CARD_PROMO_FAILURE',
    SET_PROMO_DATA: 'SET_PROMO_DATA'
}

export interface IDeliverCharge {
    id: string,
    uniqueKey: string,
    name: string,
    orderBy: number,
    shippingFee: string,
}
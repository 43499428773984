import moment from "moment";
import { BRANDS, PREMIUM_SERCHRG_ATTR } from "../constants/app";
import { IFormatedTicketType, ISeatLayout } from "../models/tickets";
import { FoodBeverageItems } from "../store/foodAndBeverage/type";

export const ticketPriceCalculation = (tickets: IFormatedTicketType[]) => {

  const price = tickets.reduce((total, num) => {
    return total + num.total;
  }, 0);
  const bookingFees = tickets.reduce((bookingFee, num) => {
    return bookingFee + num.bookingFees;
  }, 0);

  return { price, bookingFees, total: price + bookingFees }

}


export const getRndInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const generateUsersessionId = (theatreId: string, type?: string) => {
  let rand = getRndInteger(1000000000, 9999999999);
  const d = new Date();
  const currentTime =
    d.getDate() +
    "" +
    d.getHours() +
    "" +
    d.getMinutes() +
    "" +
    d.getMilliseconds();
  let session = Math.floor(Math.random() * (999 - 100 + 1) + 100);
  const id = type === 'giftshop' ? theatreId : theatreId.substr(0, 3);
  return `${session}${id}${currentTime}${rand}`;
};

export const checkSeat = (seatrecord: any, seatLayout: ISeatLayout[][]) => {
  let error = 0;
  if (seatrecord && seatrecord.length) {
    seatrecord.forEach((val: any) => {
      const seatObjPrev = seatLayout[val.ParentIndex][val.ChildIndex - 1];
      const seatObjPrevPrev = seatLayout[val.ParentIndex][
        val.ChildIndex - 2
      ];
      const seatObjNext = seatLayout[val.ParentIndex][
        val.ChildIndex + 1
      ];
      const seatObjNextNext = seatLayout[val.ParentIndex][
        val.ChildIndex + 2
      ];

      if (
        seatObjPrev &&
        seatObjPrev['seatType'] !== 'Sold' &&
        seatObjPrev['isAvailable'] === true &&
        seatObjPrev['isBooked'] === false &&
        seatObjPrev['seatType'] !== 'Aisle'
        //   seatObjPrev['isAvailable'] !== false
      ) {
        if (
          seatObjPrevPrev &&
          seatObjPrevPrev['seatType'] !== 'Sold' &&
          (seatObjPrevPrev['isBooked'] === true ||
            seatObjPrevPrev['seatType'] === 'Aisle')
        ) {
          error = 1;
        } else if (!seatObjPrevPrev) {
          error = 1;
        }
      }

      if (
        seatObjNext &&
        seatObjNext['seatType'] !== 'Sold' &&
        seatObjNext['isAvailable'] === true &&
        seatObjNext['isBooked'] === false &&
        seatObjNext['seatType'] !== 'Aisle'
        //   seatObjNext['isAvailable'] !== false
      ) {
        if (
          seatObjNextNext &&
          seatObjNextNext['seatType'] !== 'Sold' &&
          (seatObjNextNext['isBooked'] === true ||
            seatObjNextNext['seatType'] === 'Aisle')
        ) {
          error = 1;
        } else if (!seatObjNextNext) {
          error = 1;
        }
      }
    });
  }
  return { error };
}

export const concessionPriceCalculation = (concession: FoodBeverageItems[]) => {
  return concession.reduce((acc: number, item: FoodBeverageItems) => {
    let itemPrice = concessionModifierPrice(item);
    acc += Number(itemPrice) * Number(item.quantity);
    return acc;
  }, 0)
}

export const concessionModifierPrice = (itm: any) => {
  const item = itm;
  let totalPrice = parseFloat(item?.price);
  if (item?.options) {
    item.options.forEach((option: any) => {
      if (option.selected) {
        option.selected.forEach((modifier: any) => {
          totalPrice += parseFloat(modifier.price);
        });
      }
    });
  }
  else {
    return item?.price;
  }
  return totalPrice;
};

export const openTicket = (dispatch: any, setTicketLessFb: any, clearFoodItemsAction: any, toggleTicketModal: any, setPopupInfo: any, getTicketBookingDetails: any, payload: any) => {
  dispatch(setTicketLessFb(false));
  dispatch(clearFoodItemsAction());
  dispatch(
    getTicketBookingDetails(payload)
  );
  dispatch(toggleTicketModal(true));
  dispatch(setPopupInfo({ open: false, type: "", data: {} }));
}

export const checkSeatValidation = (seatrecord: any, seatLayout: ISeatLayout[][]) => {
  let status = { status: true, message: "" };
  if (seatrecord && seatrecord.length) {
    for(let val of seatrecord){
      if (seatLayout[val.ParentIndex][val.ChildIndex]['seatType'] === 'Companion') {
        const seatObjPrev = seatLayout[val.ParentIndex][val.ChildIndex - 1];
        const seatObjNext = seatLayout[val.ParentIndex][val.ChildIndex + 1];
        let isbooked = false;
        if (seatObjPrev && seatObjPrev['seatType'] === 'Special' && seatObjPrev['isBooked'] === true) {
          isbooked = true;
        } else if (seatObjNext && seatObjNext['seatType'] === 'Special' && seatObjNext['isBooked'] === true) {
          isbooked = true;
        }
        if (!isbooked) {
          status = { status: false, message: "Companion seat can be chosen along with wheelchair seat" }
          break;
        }
      }
    };
  }
  return status;
}

export const calculateServiceCharge = (data: any )=>{
  const {hasGL, brandId, films, serviceCharge, isMember } = data;
  const isPremium = (hasGL === 1 || PREMIUM_SERCHRG_ATTR[brandId as BRANDS].includes(films.sessionAttributes));
  const showDate =  moment.parseZone(films?.showDateTime).format("dddd");
  return serviceCharge[showDate.toLowerCase()][isMember?'member':'nonMember'][isPremium?'premiumServiceChargeFee':'serviceChargeFee']
}
import React,{ FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COUNTRY } from "../../../constants/app";
import { RootState } from "../../../store";
import "./footer.scss";

interface IFooterProps {}

const Footer: FC<IFooterProps> = () => {
  const { countryId, footerType } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    footerType: state.applicationReducer.footerType,
  }));

  const [copyrightText, setCopyrightText] = useState<string>("");
  const [today, setToday] = useState<number>(new Date().getFullYear());
  const [footerClass, setFooterClass] = useState<string>("footer");

  useEffect(() => {
    if (countryId) {
      switch (countryId) {
        case COUNTRY.AUS:
          setCopyrightText("Reading Entertainment Australia Pty Ltd");
          break;
        case COUNTRY.NZ:
          setCopyrightText("Reading New Zealand Ltd");
          break;

        case COUNTRY.ANG:
          setCopyrightText("Angelika Cinemas Pty Ltd");
          break;
        case COUNTRY.STA:
          setCopyrightText("Angelika State Cinema Pty Ltd");
          break;
      }
    }
  }, [countryId]);

  useEffect(() => {
    if (footerType <= 1) {
      setFooterClass("staticfooter");
      document.body.classList.add("staticfooter_active");
      document.body.classList.remove("three_movielist");
    } else if (footerType <= 3) {
      setFooterClass("footer");
      document.body.classList.add("three_movielist");
      document.body.classList.remove("staticfooter_active");
    } else {
      setFooterClass("footer");
      document.body.classList.remove("staticfooter_active");
      document.body.classList.remove("three_movielist");
    }
  }, [footerType]);

  return (
    <footer className={`${footerClass} d-none d-md-block`}>
      <p>
        Copyright{' '}<span>&copy;</span>{' '}{today}{' '}{copyrightText}
      </p>
    </footer>
  );
};

export default Footer;

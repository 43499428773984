import { IMemberUpdateDetail, ISavedCards, ISavedGiftCards, IUserDetails } from "../../models/auth";

export interface IAuth {
    userDetails: IUserDetails | null;
    memberDetail: IMemberUpdateDetail | null;
    savedCards: ISavedCards[],
    savedGiftCards: ISavedGiftCards[],
    isLoggedIn: boolean;
    authError: any;
    openModal: boolean;
    openReelClubModal: boolean;
    openPhysicalCardModal: boolean;
    openUpdateProfileModal: boolean;
    openManageCardModal: boolean;
    openManageGiftCardModal: boolean;
    openUnsubscribeMemberModal: boolean;
    membershipPurchase: {
        membershipType: string,
        clubId: number,
        loyaltyPackage: any,
        userSessionId: string,
    } | null;
    membershipModal: boolean
}

export const ACTIONS = {
    SIGN_UP_AS_GUEST: "SIGN_UP_AS_GUEST",
    SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
    SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
    SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
    SIGN_UP_ERROR: "SIGN_UP_ERROR",
    SIGN_IN_WATCHER: 'SIGN_IN_WATCHER',
    MEMBER_VALIDATION_WATCHER: 'MEMBER_VALIDATION_WATCHER',
    MEMBER_VALIDATION_SUCCESS: 'MEMBER_VALIDATION_SUCCESS',
    MEMBER_VALIDATION_FAILURE: 'MEMBER_VALIDATION_FAILURE',
    SHOW_LOGIN: 'SHOW_LOGIN',
    FORGOT_PASSWORD_WATCHER: 'FORGOT_PASSWORD_WATCHER',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
    SET_USER_DETAILS: 'SET_USER_DETAILS',
    SET_LOGOUT: "SET_LOGOUT",
    GET_SAVED_CARDS_WATCHER: 'GET_SAVED_CARDS_WATCHER',
    GET_SAVED_CARDS_SUCCESS: 'GET_SAVED_CARDS_SUCCESS',
    GET_SAVED_CARDS_ERROR: 'GET_SAVED_CARDS_ERROR',
    SHOW_REEL_CLUB_MODAL: 'SHOW_REEL_CLUB_MODAL',
    SHOW_UPDATE_PROFILE_MODAL: 'SHOW_UPDATE_PROFILE_MODAL',
    SHOW_PHYSICAL_CARD_MODAL: 'SHOW_PHYSICAL_CARD_MODAL',
    SHOW_MANAGE_CARD_MODAL: 'SHOW_MANAGE_CARD_MODAL',
    SHOW_MANAGE_GIFT_CARD_MODAL: 'SHOW_MANAGE_GIFT_CARD_MODAL',
    MEMBERSHIP_PURCHASE: 'MEMBERSHIP_PURCHASE',
    GET_SAVED_GIFT_CARDS_WATCHER: 'GET_SAVED_GIFT_CARD',
    GET_SAVED_GIFT_CARDS_SUCCESS: 'GET_SAVED_GIFT_CARD_SUCCESS',
    GET_SAVED_GIFT_CARDS_ERROR: 'GET_SAVED_GIFT_CARD_ERROR',
    SHOW_UNSUBSCRIBE_MEMBERSHIP_MODAL: 'SHOW_UNSUBSCRIBE_MEMBERSHIP_MODAL',
    SET_MEMBERSHIP_MODAL:'SET_MEMBERSHIP_MODAL'
}
import React, { useMemo } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { IMovieData } from "../../../../models/cinema";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { LOCATION_MAPPER } from "../../../../constants/app";
import { COUNTRY_IMAGE_CONFIG, URLS } from "../../../../constants/url";
import { formatComingsoonDate } from "../../../../utils/formatter";
import HtmlRender from "../../../UI/HtmlRender";
import { setNowShowingLoader, setQuickFilter } from "../../../../store/films/action";
import { movieSessAttriImages } from "../../../../utils/helper";

interface IComingSoonProps {
  data: any;
}

const ComingSoon: React.FC<IComingSoonProps> = ({ data }) => {
  const { currentCinema, countryId, quickFilters } = useSelector((state: RootState) => ({
    currentCinema: state.applicationReducer.currentCinema,
    countryId: state.applicationReducer.countryId,
    quickFilters: state.filmsReducer.quickFilters
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetFilter = ()=>{
    dispatch(setNowShowingLoader(false));
    dispatch(setQuickFilter({
      ...quickFilters,
      tempDate: quickFilters.date,
      format: null,
      movie: null,
      session: null,
      date: null,
    }))
  }

  const onClickBuy = (data: IMovieData) => {
    resetFilter();
    navigate(
      `/${
        LOCATION_MAPPER[countryId][data.theater]
          ? LOCATION_MAPPER[countryId][data.theater].alias
          : data.theater
      }/movies/details/${data.movieSlug}`
    );
  };

  const learnMore = (data: IMovieData) => {
    navigate(`/${currentCinema?.alias}/movies/details/${data.movieSlug}`);
  };

  const handleImageError = (event: any) => {
    event.target.src = URLS.ERROR_IMAGE_THUMBNAIL;
  };

  const sessionImage = useMemo(()=>{
    if( data &&
      data.showdates &&
      data.showdates.length > 0 ){
        const [show] = data.showdates;
        if(show && show.showtypes && show.showtypes.length > 0){
          for(let sh of show.showtypes){
            if(movieSessAttriImages(sh?.type)){
              const image = movieSessAttriImages(sh?.type);
              return image.image?(COUNTRY_IMAGE_CONFIG[countryId]+image.image):image.image;
            }       
          }
         return '';
        }else{
          return '';
        }
    }else{
      return '';
    }
  },[data])

  const signatureTitle = useMemo(() => {
    if (data &&
      data.signaturePrograming) {
      return data.signaturePrograming;
    } else {
      return '';
    }
  }, [data])

  return (
    <div className="card__wrap--inner">
      <div className="card">
        <div
          className="img-container"
          onClick={resetFilter}
        >
          <Link to={Array.isArray(data.showdates) && data.showdates.length > 0?`/${
        LOCATION_MAPPER[countryId][data.theater]
          ? LOCATION_MAPPER[countryId][data.theater].alias
          : data.theater
      }/movies/details/${data.movieSlug}`:`/${currentCinema?.alias}/movies/details/${data.movieSlug}`}>
          <img
            className="align-self-start"
            src={data.film_image_original_size}
            alt={data.movie}
            title={data.movie}
            onError={handleImageError}
            loading="lazy" 
          />
          {sessionImage ? (
            <div className="experience_titanluxe">
              <img
                src={sessionImage}
                loading="lazy" 
              />
            </div>
            ) :signatureTitle ?
              <div className="experience_titanluxe">
                <span className="signature-pgm-title">{signatureTitle}</span>
              </div> : null}
          </Link>
        </div>
        <div className="card__item flexible">
          <h3>{data.name}</h3>
        </div>
        <div className="card__footer">
          <div className="action">
            <div className="action-left">
              {data.release_date ? (
                <HtmlRender
                  {...{
                    style: {
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      margin: 0,
                    },
                  }}
                  content={formatComingsoonDate(data.release_date)}
                />
              ) : (
                ""
              )}
            </div>
            <div className="action-right">
              {data.showdates &&
              Array.isArray(data.showdates) &&
              data.showdates.length > 0 ? (
                <a
                  className="btn-new btn-border-danger-new"
                  onClick={() => onClickBuy(data)}
                >
                  BUY TICKETS
                </a>
              ) : (
                <a
                  className="btn-new btn-border-danger-new"
                  onClick={() => learnMore(data)}
                >
                  LEARN MORE
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;

import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { COUNTRY } from "../../../../constants/app";
import { getMemberItemsApi } from "../../../../services/auth";
import {
  memberValidationWatcher,
  updateMembbershipPurchase,
} from "../../../../store/auth/action";
import {
  setModal,
  toggleTicketModal,
} from "../../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import { generateUsersessionId } from "../../../../utils/tickets";

interface IUpgradeGoldBtnProps {
  fromPage: string;
  upgradeData?: any;
}

const UpgradeGoldBtn: FC<IUpgradeGoldBtnProps> = ({
  fromPage,
  upgradeData,
}) => {
  const {
    isLoggedIn,
    countryId,
    settings,
    brandId,
    currentCinema,
    userDetails,
    memberDetail,
  } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    isLoggedIn: state.authReducer.isLoggedIn,
    settings: state.applicationReducer.settings,
    countryId: state.applicationReducer.countryId as COUNTRY,
    currentCinema: state.applicationReducer.currentCinema,
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
  }));

  const dispatch = useDispatch();

  const [showUpgrade, setShowUpgrade] = useState<any>(null);

  const getMemberItems = async () => {
    const {
      data: {
        data: {
          data: { ConcessionList },
        },
      },
    } = await getMemberItemsApi({
      countryId,
      MemberRefId: userDetails?.vista_memberid!,
      cardNumber: userDetails?.cardNumber!,
      isMemberPackage: true,
    });
    if (ConcessionList && ConcessionList.length > 0) {
      setShowUpgrade(
        ConcessionList.find(
          (v: any) =>
            Number(v.RecognitionID) ===
              settings?.membership?.upgrade?.recognitionId &&
            Number(v.VistaID) === settings?.membership?.upgrade.itemId
        )
      );
    }
  };

  useEffect(() => {
    if (upgradeData && fromPage !== "sideMenu") {
      setShowUpgrade(upgradeData);
    }
  }, [upgradeData]);

  useEffect(() => {
    if (countryId && currentCinema && isLoggedIn && fromPage === "sideMenu") {
      getMemberItems();
    }
  }, [countryId, currentCinema, isLoggedIn && memberDetail && fromPage]);

  const purchase = () => {
    const userSessionId = generateUsersessionId(currentCinema?.slug);
    dispatch(
      updateMembbershipPurchase({
        membershipType: "upgrade",
        userSessionId: userSessionId,
        loyaltyPackage: {
          description: showUpgrade?.Name,
          extendedDescription: showUpgrade?.Description,
          id: settings?.membership?.upgrade?.itemId,
          recognitionId: settings?.membership?.upgrade?.recognitionId,
          priceInCents: 2000,
        },
      })
    );
    const payload = {
      MemberRefId: userDetails?.vista_memberid,
      UserSessionId: userSessionId,
      cardNumber: userDetails?.result?.cardNumber,
      countryId: countryId,
    };
    dispatch(
      memberValidationWatcher(
        payload,
        (res: any) => {
          dispatch(
            setModal({
              ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
                MODAL_TYPE.MEMBERSHIP_PAYMENT
              ],
              type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
            })
          );
        },
        (err: any) => {}
      )
    );

    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
          MODAL_TYPE.MEMBERSHIP_AUTH
        ],
        type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
      })
    );
    dispatch(toggleTicketModal(true));
  };

  return (
    <button className="membership-button px-4" onClick={purchase}>
      {fromPage === "membership" ? "UPGRADE" : "UPGRADE TO GOLD"}
    </button>
  );
};

export default UpgradeGoldBtn;

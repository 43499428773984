import { COUNTRY } from "./app";

export const schemaMarkup: any = {
    [COUNTRY.US]: {
        "19": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://readingcinemas.com/grossmont",
            "name": "GROSSMONT WITH TITAN XC",
            "address": "5500 Grossmont Center Dr La Mesa, California 91942",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "32.778666",
                "longitude": "-117.01062"
            },
            "telephone": "(619) 465-3040",
            "email": "grossmont@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/reading_logo_us.png",
            "hasMap": "https://maps.google.com/?cid=4581011639230303143",
            "sameAs": ['https://www.facebook.com/readingcinemas',
                'https://www.instagram.com/readingcinemas_',
                'https://twitter.com/readingcinemas',
                'https://www.tiktok.com/@readingcinemas'
            ]
        },
        "20": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://readingcinemas.com/caloaks",
            "name": "CAL OAKS WITH TITAN LUXE",
            "address": "41090 California Oaks Rd Murrieta, California 92562",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "33.563739",
                "longitude": "-117.202219"
            },
            "telephone": "(951) 696-7045",
            "email": "cal.oaks@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/reading_logo_us.png",
            "hasMap": "https://maps.google.com/?cid=4581011639230303143",
            "sameAs": ['https://www.facebook.com/readingcaloaks',
                'https://www.instagram.com/readingcaloaks',
                'https://twitter.com/readingcaloaks',
                'https://www.tiktok.com/@readingcinemas'
            ]
        },
        "0000000001": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://readingcinemas.com/townsquare",
            "name": "TOWN SQUARE",
            "address": "4665 CLAIREMONT DR San Diego, California 92117",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "32.829357",
                "longitude": "-117.20542"
            },
            "telephone": "(858) 274-9994",
            "email": "town.square@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/reading_logo_us.png",
            "hasMap": "https://maps.google.com/?cid=2729864612056309423",
            "sameAs": ['https://www.facebook.com/readingcinemas',
                'https://www.instagram.com/readingcinemas_/',
                'https://twitter.com/readingcinemas',
                'https://www.tiktok.com/@readingcinemas'
            ]
        },
        "0000000003": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://readingcinemas.com/manville",
            "name": "MANVILLE WITH TITAN LUXE",
            "address": "180 N. Main St Manville, New Jersey 08835",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "40.550078",
                "longitude": "-74.584008"
            },
            "telephone": "(908) 707-4373",
            "email": "manville@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/reading_logo_us.png",
            "hasMap": "https://maps.google.com/?cid=6225235624906920015",
            "sameAs": ['https://www.facebook.com/manville12',
                'https://www.instagram.com/readingcinemasmanville',
                'https://twitter.com/manville12',
                'https://www.tiktok.com/@readingcinemas'
            ]
        },
        "17": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://readingcinemas.com/valleyplaza",
            "name": "VALLEY PLAZA WITH IMAX",
            "address": "2000 Wible Rd Bakersfield, California 93304",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "35.335397",
                "longitude": "-119.036782"
            },
            "telephone": "(661) 833-2230",
            "email": "manville@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/reading_logo_us.png",
            "hasMap": "https://maps.google.com/?cid=10772636220349431365",
            "sameAs": ['https://www.facebook.com/readingcinemasvp16',
                'https://www.instagram.com/readingcinemasvp16',
                'https://twitter.com/readingcinemas',
                'https://www.tiktok.com/@readingcinemas'
            ]
        }
    },
    [COUNTRY.CON]: {
        "22": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://consolidatedtheatres.com/kapolei",
            "name": "Consolidated Kapolei",
            "address": "890 Kamokila Blvd Kapolei, Hawaii 96707",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "21.333716",
                "longitude": "-158.083481"
            },
            "telephone": "(808) 628-4702",
            "email": "kapolei@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/con/consolidated_logo.png",
            "hasMap": "https://maps.google.com/?cid=5365927710968440150",
            "sameAs": ['https://www.facebook.com/ConsolidatedKapolei',
                'https://www.instagram.com/consolidatedHI',
                'https://twitter.com/consolidatedhi',
                'https://www.tiktok.com/@consolidatedtheatres'
            ]
        },
        "0000000011": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://consolidatedtheatres.com/olino",
            "name": "Olino by Consolidated Theatres with TITAN LUXE",
            "address": "91-5431 Kapolei Parkway Kapolei, Hawaii 96707",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "21.332886",
                "longitude": "-158.050224"
            },
            "telephone": "(808) 628-4835",
            "email": "olino@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/con/consolidated_logo.png",
            "hasMap": "https://maps.google.com/?cid=12767258039640501217",
            "sameAs": ['https://www.facebook.com/olinotheatres',
                'https://www.instagram.com/olinotheatres',
                'https://twitter.com/olinotheatres',
                'https://www.tiktok.com/@consolidatedtheatres'
            ]
        },
        "0000000002": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://consolidatedtheatres.com/mililani",
            "name": "Consolidated Mililani with TITAN LUXE",
            "address": "95-1249 Meheula Pkwy Mililani, Hawaii 96789",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "21.452413",
                "longitude": "-158.00596"
            },
            "telephone": "(808) 627-0200",
            "email": "miliani@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/con/consolidated_logo.png",
            "hasMap": "https://maps.google.com/?cid=15290856721115718898",
            "sameAs": ['https://www.facebook.com/ConsolidatedMililani',
                'https://www.instagram.com/consolidatedHI',
                'https://twitter.com/consolidatedhi',
                'https://www.tiktok.com/@consolidatedtheatres'
            ]
        },
        "13": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://consolidatedtheatres.com/pearlridge",
            "name": "Consolidated Pearlridge",
            "address": "98-1005 Moanalua Rd Aiea, Hawaii 96701",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "21.383307",
                "longitude": "-157.940695"
            },
            "telephone": "(808) 483-5339",
            "email": "pearlridge@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/con/consolidated_logo.png",
            "hasMap": "https://maps.google.com/?cid=9003895584528113714",
            "sameAs": ['https://www.facebook.com/ConsolidatedPearlridge',
                'https://www.instagram.com/consolidatedHI',
                'https://twitter.com/consolidatedhi',
                'https://www.tiktok.com/@consolidatedtheatres'
            ]
        },
        "12": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://consolidatedtheatres.com/ward",
            "name": "Consolidated Ward with TITAN LUXE",
            "address": "1044 Auahi St Honolulu, Hawaii 96814",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "21.294522",
                "longitude": "-157.853303"
            },
            "telephone": "(808) 594-7044",
            "email": "ward@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/con/consolidated_logo.png",
            "hasMap": "https://maps.google.com/?cid=8634027310783311764",
            "sameAs": ['https://www.facebook.com/consolidatedHI',
                'https://www.instagram.com/consolidatedhi',
                'https://twitter.com/consolidatedhi',
                'https://www.tiktok.com/@consolidatedtheatres'
            ]
        },
        "24": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://consolidatedtheatres.com/kahala",
            "name": "Consolidated Kahala Theatre",
            "address": "4211 Waialae Ave Honolulu, Hawaii 96816",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "21.277718",
                "longitude": "-157.787536"
            },
            "telephone": "(808) 733-6243",
            "email": "kahala@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/con/consolidated_logo.png",
            "hasMap": "https://maps.google.com/?cid=16067097624662511193",
            "sameAs": ['https://www.facebook.com/ConsolidatedKahala',
                'https://www.instagram.com/consolidatedHI',
                'https://twitter.com/consolidatedhi',
                'https://www.tiktok.com/@consolidatedtheatres'
            ]
        },
    },
    [COUNTRY.AFC]: {
        "0000000005": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/nyc",
            "name": "Angelika New York",
            "address": "18 W Houston St New York, NY 10012, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "40.725907",
                "longitude": "-73.997169"
            },
            "telephone": "(516) 703-4370",
            "email": "angelika.nyc@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=7794567259815053441",
            "sameAs": ['https://www.facebook.com/angelikafilmNY/',
                'https://www.instagram.com/angelikafilmny/',
                'https://twitter.com/angelikafilmny',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
        "0000000006": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/mosaic",
            "name": "Angelika Film Center & Cafe at Mosaic",
            "address": "2911 District Ave Fairfax, VA 22031, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "38.871797",
                "longitude": "-77.229703"
            },
            "telephone": "(571) 512-3301",
            "email": "angelika.mosaic@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=4208248618983430578",
            "sameAs": ['https://www.facebook.com/AngelikaMosaic/',
                'https://www.instagram.com/angelikamosaic/',
                'https://twitter.com/angelikamosaic',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
        "0000000007": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/dc",
            "name": "Angelika Pop-Up at Union Market",
            "address": "550 Penn St NE Washington, DC 20002, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "38.910074",
                "longitude": "-76.996021"
            },
            "telephone": "(571) 512-3311",
            "email": "angelika.popup@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=15140048077221725540",
            "sameAs": ['https://www.facebook.com/AngelikaFilmDC/',
                'https://www.instagram.com/angelikafilmdc/',
                'https://twitter.com/angelikafilmdc',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
        "0000000008": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/plano",
            "name": "Angelika Plano",
            "address": "7205 Bishop Rd Plano, TX 75024, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "33.076717",
                "longitude": "-96.821665"
            },
            "telephone": "(972) 943-1375",
            "email": "angelika.plano@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=12392871587256300099",
            "sameAs": ['https://www.facebook.com/AngelikaPlano/',
                'https://www.instagram.com/angelikaplano',
                'https://twitter.com/angelikatexas',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
        "0000000009": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/dallas",
            "name": "Angelika Film Center - Dallas",
            "address": "5321 E Mockingbird Ln Dallas, TX 75206, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "32.838078",
                "longitude": "-96.775009"
            },
            "telephone": "(214) 841-4712",
            "email": "angelika.dallas@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=8398487239377440526",
            "sameAs": ['https://www.facebook.com/AngelikaDallas/',
                'https://www.instagram.com/angelikafilmdallas',
                'https://twitter.com/angelikatexas',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
        "0000000004": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/villageeast",
            "name": "Village East by Angelika",
            "address": "181-189 2nd Ave New York, NY 10003, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "40.730956",
                "longitude": "-73.986212"
            },
            "telephone": "(212) 529-6998",
            "email": "village.east@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=15384945027930877356",
            "sameAs": ['https://www.facebook.com/villageeastbyangelika',
                'https://www.instagram.com/villageeastbyangelika/',
                'https://twitter.com/AngelikaFilm_NY',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
        "21": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/cinemas123",
            "name": "Cinema123 by Angelika",
            "address": "1001 3rd Ave New York, NY 10022, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "40.761863",
                "longitude": "-73.966085"
            },
            "telephone": "(212) 753-6022",
            "email": "cinemas123@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=1197862463925680092",
            "sameAs": ['https://www.facebook.com/cinemas123byangelika',
                'https://www.instagram.com/cinema123byangelika',
                'https://twitter.com/AngelikaFilm_NY',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
        "18": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/sandiego",
            "name": "Angelika Carmel Mountain",
            "address": "11620 Carmel Mountain Rd San Diego, CA 92128, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "32.981214",
                "longitude": "-117.082019"
            },
            "telephone": "(858) 207-2606",
            "email": "angelika.carmelmountain@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=1083400324458223896",
            "sameAs": ['https://www.facebook.com/angelikafilmSD/',
                'https://www.instagram.com/angelikafilmsd/',
                'https://twitter.com/AngelikaFilmSD',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
        "16": {
            "@context": "https://schema.org",
            "@type": "MovieTheater",
            "url": "https://angelikafilmcenter.com/tower",
            "name": "Tower Theatre by Angelika",
            "address": "2508 Land Park Dr Sacramento, CA 95818, USA",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "38.5615605",
                "longitude": "-121.4935153"
            },
            "telephone": "(916) 442-0985",
            "email": "tower@readingrdi.com",
            "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
            "hasMap": "https://maps.google.com/?cid=12957301406002349803",
            "sameAs": ['https://www.facebook.com/TowerTheatreCA',
                'https://www.instagram.com/towertheatreca_',
                'https://twitter.com/towertheatreca',
                'https://www.tiktok.com/@angelikafilmcenter',
                'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
            ]
        },
    }
}

export const defaultSchema: any = {
    [COUNTRY.US]: {
        "@context": "https://schema.org",
        "@type": "MovieTheater",
        "url": "https://readingcinemas.com",
        "name": "Reading Cinemas",
        "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/reading_logo_us.png",
        "sameAs": ['https://www.facebook.com/readingcinemas',
            'https://www.instagram.com/readingcinemas_',
            'https://twitter.com/readingcinemas',
            'https://www.tiktok.com/@readingcinemas'
        ]
    },
    [COUNTRY.CON]: {
        "@context": "https://schema.org",
        "@type": "MovieTheater",
        "url": "https://consolidatedtheatres.com",
        "name": "Consolidated Theatres",
        "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/con/consolidated_logo.png",
        "sameAs": ['https://www.facebook.com/ConsolidatedMililani',
            'https://www.instagram.com/consolidatedHI',
            'https://twitter.com/consolidatedhi',
            'https://www.tiktok.com/@consolidatedtheatres'
        ]
    },
    [COUNTRY.AFC]: {
        "@context": "https://schema.org",
        "@type": "MovieTheater",
        "url": "https://angelikafilmcenter.com",
        "name": "Angelika Film Center",
        "logo": "https://d35zcch9f9it10.cloudfront.net/assets/images/afc/angelika_logo.png",
        "sameAs": ['https://www.facebook.com/angelikafilmNY/',
            'https://www.instagram.com/angelikafilmny/',
            'https://twitter.com/angelikafilmny',
            'https://www.tiktok.com/@angelikafilmcenter',
            'https://www.youtube.com/channel/UCM39v6RUFgvyhiwFCtGrOaw'
        ]
    }
}
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { COUNTRY } from "../../../constants/app";

interface ILoaderProps {}

const Loader: FC<ILoaderProps> = () => {
  const { loader, countryId } = useSelector((state: RootState) => ({
    loader: state.applicationReducer.loader,
    countryId: state.applicationReducer.countryId,
  }));
  return loader ? (
    <div className="black-overlay">
      <div
        className={`loader ${
          countryId === COUNTRY.STA
            ? "sta_loader"
            : countryId === COUNTRY.ANG
            ? "ang_loader"
            : countryId === COUNTRY.CON
            ? "con_loader"
            : countryId === COUNTRY.AFC
            ? "afc_loader"
            : countryId === COUNTRY.US ||
              countryId === COUNTRY.AUS ||
              countryId === COUNTRY.NZ
            ? "reading_loader"
            : ""
        }`}
      >
        <span className="lodaer_logo"></span>
      </div>
    </div>
  ) : null;
};

export default Loader;

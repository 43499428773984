import React,{ FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  purchaseHistoryApi
} from "../../../../services/auth";
import moment from "moment";
import { ITransactionHistory } from "../../../../models/auth";
import { COUNTRY } from "../../../../constants/app";

interface IPurchaseHistoryProps {}

const PurchaseHistory: FC<IPurchaseHistoryProps> = () => {
  
  const { countryId, memberDetail, openSlider } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    memberDetail: state.authReducer.memberDetail,
    openSlider: state.applicationReducer.openSlider,
  }));

  const [purchaseHistory, setPurchaseHistory] = useState<ITransactionHistory[]>(
    []
  );

  const getPurchaseHistory = async () => {
    const payload = {
      countryId: countryId,
      MemberRefId: memberDetail?.LoyaltyMember?.MemberId,
      cardNumber: memberDetail?.LoyaltyMember?.CardNumber,
      UserSessionId: memberDetail?.LoyaltyMember?.CardNumber,
    };
    const {
      status,
      data:{
        data,
        statusCode
      }
    } = await purchaseHistoryApi(payload) as any;
    if (status === 200 && statusCode === 200 && data) {
      if (
        data.result &&
        data.result.MemberTransactions &&
        data.result.MemberTransactions.length > 0
      ) {
        setPurchaseHistory(data.result.MemberTransactions);
      }
    }
  };

  useEffect(() => {
    if (memberDetail && openSlider.open) {
      getPurchaseHistory();
    }
  }, [memberDetail, openSlider]);

  return (
    <div className="col-md-12">
      <div className="purchase-history-card section_divided">
        <div className="title">Purchase History</div>
        <div className="table-responsive">
          <table
            className="table table-borderless table-hover table-sm"
            aria-describedby="Purchase History"
          >
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">
                  {countryId === COUNTRY.STA ? "Film" : "Cinema"}
                </th>
                {countryId !== COUNTRY.STA ? (
                  <>
                    <th scope="col">Txn Number</th>
                    <th scope="col">Points</th>
                  </>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {purchaseHistory.map((item: any, index: number) => (
                <tr>
                  <td scope="row">
                    {moment(
                      new Date(parseFloat(item.TransactionDate.substr(6)))
                    ).format("DD MMM YYYY")}
                  </td>
                  <td>{item.CinemaName}</td>
                  {countryId !== COUNTRY.STA ? (
                    <>
                      <td>{item.TransactionId}</td>
                      <td>{item.PointsEarned}</td>
                    </>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {purchaseHistory.length === 0 ? (
          <div>
            <p className="text-center text-danger h6">
              No transaction available
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PurchaseHistory;

import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import Modal from "../../../../UI/Modal";
import { getSavedCardWatcher } from "../../../../../store/auth/action";
import { CardDetail } from "../../../../../models/common";
import {
  getMakeDefaultCardApi,
  removedCard,
} from "../../../../../services/auth";
import { toast } from "react-toastify";

interface IListProps {
  setSelectedCard: React.Dispatch<React.SetStateAction<any>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setTabType: React.Dispatch<React.SetStateAction<string>>;
}

const List: FC<IListProps> = ({ setSelectedCard, setIsEdit, setTabType }) => {
  const { countryId, savedCards, userDetails } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      savedCards: state.authReducer.savedCards,
      userDetails: state.authReducer.userDetails,
    })
  );

  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState<CardDetail[]>([]);
  const [deleteCards, setDeleteCards] = useState<CardDetail | null>();
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const getCards = ()=>{
    dispatch(
        getSavedCardWatcher({
          memberRefId: userDetails?.result?.memberRefId!,
          countryId: countryId,
          emailId: userDetails?.email!,
        })
      );
  }
  useEffect(() => {
    getCards();
  }, []);

  useEffect(() => {
    if (savedCards) {
      setCardDetails(
        savedCards.map((card: CardDetail) => {
          return {
            ...card,
            cardNumberEnding: card.cardNumber ? card.cardNumber.slice(-3) : "",
          };
        })
      );
    }
  }, [savedCards]);

  const setDefaultCardBtn = async (card: CardDetail) => {
    if (userDetails) {
      const {
        data: { statusCode, data },
      } = (await getMakeDefaultCardApi({
        memberRefId: userDetails?.result?.memberRefId!,
        token: card.token,
        countryId: countryId,
        emailId: userDetails?.email,
      })) as any;

      if (statusCode === 200) {
        toast.success("Default Payment Option successfully updated");
        dispatch(
          getSavedCardWatcher({
            memberRefId: userDetails?.result?.memberRefId!,
            countryId: countryId,
            emailId: userDetails?.email!,
          })
        );
      }
    }
  };

  const confirmationDeleteCard = (data: CardDetail) => {
    setOpenPopup(true);
    setDeleteCards(data);
  };

  const deleteCard = async () => {
    await removedCard({
      memberRefId: userDetails?.result?.memberRefId!,
      token: deleteCards?.token!,
      countryId: `${countryId}`,
      emailId: userDetails?.email!,
    }).then((res: any) => {
      const status = res?.data?.status;
      if (status === 200) {
        toast.success("Card deleted successfully");
        setOpenPopup(false);
        setDeleteCards(null);
        getCards();
      }
    });
  };

  const cancelConfirmation = () => {
    setOpenPopup(false);
    setDeleteCards(null);
  };

  const onEdit = (card: any) => {
    setSelectedCard(card);
    setIsEdit(true);
    setTabType("add");
  };

  return (
    <div className="row tab-content ">
      <div className="col-md-12">
        {cardDetails && cardDetails.length > 0 ? (
          <div>
            <div className="cardlist_wrap">
              <ul>
                {cardDetails &&
                  cardDetails.map((card: any) => (
                    <li>
                      <div className="card_header">
                        <h4>Card ending in {card.cardNumberEnding}</h4>
                        {card.defaultCard == 1 ? (
                          <div className="default_payment">
                            Default payment method
                          </div>
                        ) : (
                          <button
                            className="set_default_btn"
                            type="button"
                            onClick={() => {
                              setDefaultCardBtn(card);
                            }}
                          >
                            Set as Default
                          </button>
                        )}
                      </div>
                      <div className="card_details_wrap">
                        <div className="table-responsive">
                          <table
                            className="table table-borderless table-fit table-sm"
                            aria-describedby="card table"
                          >
                            <thead>
                              <tr>
                                <th scope="col">Name on card</th>
                                <th scope="col">Expires</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td scope="row">{card.cardName}</td>
                                <td>
                                  {card.expiryDate |
                                    card.expiryDate.slice(0, 2)}
                                  /{card.expiryDate | card.expiryDate.slice(-2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="action_wrap">
                            <button
                              type="button"
                              className="set_default_btn"
                              onClick={() => onEdit(card)}
                            >
                              Edit
                            </button>
                            <button
                              className="del_btn"
                              type="button"
                              onClick={() => {
                                confirmationDeleteCard(card);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ) : (
          <div className="m-5 text-center">Cards not yet added</div>
        )}
      </div>
      <Modal visible={openPopup} className="manage-cards-popup"  showCloseBtn={false}>
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-body p-4">
                <div className="conformation_wrap">
                  <h4>Are you sure want to delete</h4>
                  <div className="conformation_action">
                    <button
                      className="del_btn"
                      type="button"
                      onClick={deleteCard}
                    >
                      Yes Delete it
                    </button>
                    <button
                      className=""
                      type="button"
                      onClick={cancelConfirmation}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default List;

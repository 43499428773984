import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const [scrollPath, setScrollPath] = useState<string[]>([
    "/giftcards/my-cart",
    "/movies/details/",
    "/work-at-reading-cinemas",
    "/special-values",
    "/accessibility",
    "/signature-programming",
    "/movies",
    "/advertise-with-us",
  ]);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname && scrollPath.find((v) => pathname.includes(v))) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [pathname]);

  return null;
}

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../.././../../store";
import { useEffect, useState } from "react";
import React from "react";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import ReCAPTCHA from "react-google-recaptcha";
import {
  BRANDS,
  CAPTCHA_SITE_KEY,
  CONTACT_US_ETIX_ERROR,
  CONTACT_US_SEND_MAIL,
  COUNTRY,
} from "../../../../constants/app";
import { contactUsApi } from "../../../../services/sideMenus";
import "./contactUs.scss";
import TextInput from "../../../../components/UI/TextInput";
import SelectDropdown from "../../../../components/UI/DropDown/SelectDropDown";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import {
  getContactListApi,
  getSlidesApi,
} from "../../../../services/application";
import { toast } from "react-toastify";
import { IContactList, IDropDown } from "../../../../models/common";
import HelmetTitle from "../../../../components/Helmet";

interface IContactUsProps {
  onCloseModal: () => void;
}

const ContactUs: React.FC<IContactUsProps> = ({ onCloseModal }) => {
  const { countryId, cinemas, brandId, contentModal, currentCinema } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      cinemas: state.applicationReducer.cinemas,
      brandId: state.applicationReducer.brandId,
      contentModal: state.applicationReducer.contentModal,
      currentCinema: state.applicationReducer.currentCinema,
    }));

  const [cinemaList, setCinemaList] = useState([]);
  const [headerModileImage, setHeaderModileImage] = useState("");
  const [headerDesktopImage, setHeaderDesktopImage] = useState("");
  const [contactListDetails, setContactListDetails] = useState<IContactList[]>(
    []
  );
  const [contactList, setContactList] = useState<IDropDown[]>([]);

  const getSlides = async () => {
    const {
      status,
      response: { data },
    } = (await getSlidesApi({
      countryId,
      pageName: API_REQUEST_TYPE.CONTACT_US,
      location: "",
    })) as any;
    if (status && data && data.length > 0) {
      setHeaderModileImage(data[0]["mobileImageUrl"]);
      setHeaderDesktopImage(data[0]["imageUrl"]);
    }
  };

  const getContactList = async () => {
    const { data } = (await getContactListApi({
      countryId,
    })) as any;
    if (data && data.length > 0) {
      setContactListDetails(data);
      setContactList(
        data.map((v: IContactList) => ({ value: v.id, label: v.title }))
      );
    }
  };

  useEffect(() => {
    if (countryId) {
      getSlides();
      if (brandId === BRANDS.US) {
        getContactList();
      }
    }
  }, [countryId]);

  useEffect(() => {
    if (cinemas) {
      let tempCinemas: any = [];
      Object.keys(cinemas).forEach(function (key) {
        const arrList = cinemas[key];
        arrList.forEach((item: any) => {
          tempCinemas.push({
            label: item.name,
            value: brandId === BRANDS.US ? item.name:`${item.slug}`,
          });
        });
      });
      setCinemaList(tempCinemas);
    }
  }, [cinemas]);

  const onChangeCaptcha = (event: any) => {
    if (event) {
      setFieldValue("captcha", true);
    } else {
      setFieldValue("captcha", false);
    }
  };

  const handleDeviceChange = (event: any) => {
    setFieldValue("device", event.target.value);
  };

  const handleEtixChange = (event: any) => {
    setFieldValue("etixreelclub", event.target.value);
  };

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("contactUs"),
      initialValues: {
        name: "",
        email: "",
        contactNumber: "",
        sendemailto: "",
        cinema: "",
        comments: "",
        etixerror: "",
        etixreelclub: "",
        device: "",
        captcha: false,
        countryId: countryId,
        brandId: brandId,
        reelclubcard: "",
        contactusId: "",
      },
      onSubmit: (values: any) => {
        const payload: any = values;
        payload.countryId = countryId;
        payload.cinemaId = currentCinema?.slug;
        if (brandId === BRANDS.AU) {
          const filter = CONTACT_US_SEND_MAIL[countryId].find(
            (v: any) => v.value === values.sendemailto
          );
          payload.filter = filter.onSelect;
        } else if (brandId === BRANDS.US) {
          const contact = contactListDetails.find(
            (v: any) => v.id === values.contactusId
          );
          payload.contactusEmail = contact?.email;
        }
        contactUsApi(payload)
          .then((e) => {
            toast.success("Contact details submitted successfully.");
            onCloseModal();
          })
          .catch((err) => {
            // submit error
            toast.error(
              "Could not connect to services at the moment. Please try again. Thanks."
            );
          });
      },
    });

  useEffect(() => {
    if (contentModal && contentModal.category && countryId) {
      let value = "";
      switch (contentModal.category) {
        case "refunds":
          value = "refund";
          break;
        case "lost-and-found":
          value = "lostAndFound";
          break;
        case "advertising":
          value = "advertising";
          break;
      }
      setFieldValue("sendemailto", value);
    }
  }, [contentModal, countryId]);

  return (
    <div
      className={`contact-us-wrapper ${
        brandId === BRANDS.US ? "brand_us" : ""
      } ${
        countryId === COUNTRY.CON
          ? "consolidated"
          : countryId === COUNTRY.AFC
          ? "angelika-flim-center"
          : ""
      }`}
    >
      <HelmetTitle title="Contact us" description="Contact us"/>
      <div className="modal-header">
        <div>
          {headerDesktopImage || headerModileImage ? (
            <div className="full_banner_wrap">
              <img
                className="d-none d-md-block"
                src={headerDesktopImage}
                alt="Contact Us"
                title="Contact Us"
              />
              <img
                className="d-block d-md-none"
                src={headerModileImage}
                alt="Contact Us"
                title="Contact Us"
              />
            </div>
          ) : null}
          <div className="row mx-3">
            <div className="col-md-12 mt-4">
              <h3 className="title">Contact Us</h3>
              {countryId === COUNTRY.STA ? (
                <p>
                  The State Cinema welcomes your feedback. We want every trip to
                  the cinema to be a great one. Please let us know if there is
                  anything you think we could do better.
                </p>
              ) : countryId === COUNTRY.ANG ? (
                <p>
                  Angelika Film Centre welcomes your feedback. We want every
                  Angelika experience to be a great one! Please let us know if
                  there is anything we can do better.
                </p>
              ) : countryId === COUNTRY.US ? (
                <p>
                  Reading Cinemas welcomes your feedback! Please complete the
                  form below to get in touch with us.
                </p>
              ) : countryId === COUNTRY.CON ? (
                <p>
                  Consolidated Theatres welcomes your feedback! Please complete the
                  form below to get in touch with us.
                </p>
              ) : countryId === COUNTRY.AFC ? (
                <p>
                  Angelika Film Center welcomes your feedback! Please complete the
                  form below to get in touch with us.
                </p>
              ) : (
                <p>
                  Reading Cinemas welcomes your feedback. We want every Reading
                  Cinemas experience to be a great one! Please let us know if
                  there is anything we can do better?
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="contact-us-form form_page">
          <div className="row">
            <div className="col-12 col-md-6 ">
              <label className="form-label">Name*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.name}
                  field={"name"}
                  handleChange={handleChange}
                  touched={touched.name!}
                  error={errors.name}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label">Email*</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.email}
                  field={"email"}
                  handleChange={handleChange}
                  touched={touched.email!}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label">Contact Number</label>
              <div className="form-group pass_show">
                <TextInput
                  value={values.contactNumber}
                  field={"contactNumber"}
                  handleChange={handleChange}
                  touched={touched.contactNumber!}
                  error={errors.contactNumber}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-label">Send Message To*</label>
              <SelectDropdown
                // placeholder="Cinema"
                field={brandId === BRANDS.US?"contactusId":"sendemailto"}
                options={
                  brandId === BRANDS.US
                    ? contactList
                    : countryId && CONTACT_US_SEND_MAIL[countryId]
                    ? CONTACT_US_SEND_MAIL[countryId]
                    : []
                }
                setFieldValue={setFieldValue}
                touched={brandId === BRANDS.US?touched.contactusId!:touched.sendemailto!}
                error={brandId === BRANDS.US?errors.contactusId:errors.sendemailto}
                value={brandId === BRANDS.US?values.contactusId:values.sendemailto}
              />
            </div>
            {values.sendemailto != "" || brandId === BRANDS.US ? (
              <div className="col-12 col-md-6 mb-3 ">
                <label className="form-label empty">{brandId === BRANDS.US?'Select your cinema':'Cinema'}*</label>
                <SelectDropdown
                  // placeholder="Cinema"
                  field={"cinema"}
                  options={cinemaList}
                  setFieldValue={setFieldValue}
                  touched={touched.cinema!}
                  error={errors.cinema}
                  value={values.cinema}
                  // onChange={(value: any) => {
                  //   setFieldValue("cinema", value);
                  // }}
                />
              </div>
            ) : null}
            {values.sendemailto === "readingReelClub" && (
              <div className="col-12 col-md-6 ">
                <label className="form-label">Reel Club Card No*</label>
                <div className="form-group pass_show">
                  <TextInput
                    field={"reelclubcard"}
                    handleChange={handleChange}
                    touched={touched.reelclubcard!}
                    error={errors.reelclubcard}
                  />
                </div>
              </div>
            )}
            {values.sendemailto === "eTixSupport" && (
              <>
                <div className="col-12 col-md-6 ">
                  <label className="form-label empty">E-Tix Step Error*</label>
                  <SelectDropdown
                    field={"etixerror"}
                    options={CONTACT_US_ETIX_ERROR}
                    setFieldValue={setFieldValue}
                    touched={touched.etixerror!}
                    error={errors.etixerror}
                    value={values.etixerror}
                    // onChange={(value: any) => {
                    //   setFieldValue("etixerror", value);
                    // }}
                  />
                </div>
                <div className="col-12 col-md-12 ">
                  <label className="form-label">Device & Browser*</label>
                  <div className="custom-control">
                    <label className="form-label black">
                      Is this the same Device and Browser which error occurred
                      on?
                    </label>
                    <input
                      id="yes"
                      type="radio"
                      value="Yes"
                      name="device"
                      onChange={handleDeviceChange}
                      defaultChecked={values.device === "Yes"}
                    />
                    <label className="custom-control-label" htmlFor="yes">
                      Yes
                    </label>
                    <input
                      id="no"
                      type="radio"
                      value="No"
                      name="device"
                      onChange={handleDeviceChange}
                      checked={values.device === "No"}
                    />
                    <label className="custom-control-label" htmlFor="no">
                      No
                    </label>
                  </div>
                  {touched.device && errors?.device ? (
                    <div className="error_message">{`${errors?.device}`}</div>
                  ) : null}
                </div>
                {brandId === BRANDS.AU ? (
                  <div className="col-12 col-md-6 ">
                    <label className="form-label">
                      {countryId === COUNTRY.ANG
                        ? "Angelika Rewards"
                        : "Reel Club"}
                      *
                    </label>
                    <div className="custom-control">
                      <label className="form-label black">
                        {countryId === COUNTRY.ANG
                          ? "Did you log in to the Angelika Rewards?"
                          : "Did you log in to the Reel Club"}
                      </label>
                      <input
                        id="realclub-yes"
                        type="radio"
                        value="Yes"
                        name="real-club"
                        onChange={handleEtixChange}
                        checked={values.etixreelclub === "Yes"}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="realclub-yes"
                      >
                        Yes
                      </label>
                      <input
                        id="realclub-no"
                        type="radio"
                        value="No"
                        name="real-club"
                        onChange={handleEtixChange}
                        defaultChecked={values.etixreelclub === "No"}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="realclub-no"
                      >
                        No
                      </label>
                    </div>
                    {touched.etixreelclub && errors?.etixreelclub ? (
                      <div className="error_message">{`${errors?.etixreelclub}`}</div>
                    ) : null}
                  </div>
                ) : null}
              </>
            )}
            <div className="col-12 col-md-12">
              <label className="form-label">Comments: </label>
              <div className="form-group">
                <textarea
                  title="Enter your Comments"
                  name="comments"
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  aria-label="Comments"
                />
              </div>
            </div>
            <div className="col-12  col-md-12">
              <label className="form-label">Verification Code* </label>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${CAPTCHA_SITE_KEY}`}
                  onChange={onChangeCaptcha}
                />
                {touched.captcha && errors.captcha ? (
                  <div className="error_message">{`${errors?.captcha}`}</div>
                ) : null}
              </div>
            </div>
            {countryId === COUNTRY.ANG ? (
              <p className="note">
                * Please Note: 'Head Office’, ‘Angelika Rewards’ and ‘Gift Shop’
                enquiries are reviewed Monday to Friday only, excluding evenings
                and public holidays. If the matter is urgent please contact
                Angelika Film Centre directly on 07 3709 8080.
              </p>
            ) : brandId === BRANDS.US ? (
              <p className="note">
               <span style={{fontWeight:900}}>Refund Policy:</span> Refunds & Exchanges may be requested up to 1 hour prior to printed showtime. Service charges cannot be refunded with a full refund request. To issue an Exchange or Refund, please click the link found in your ticket email confirmation and follow the steps. If you have an account, you can also issue an Exchange or Refund from your Account Tickets page located under your Profile.
              </p>
            ) : (
              <p className="note">
                * Please Note: 'Head Office' and 'Reading Reel Club' feedback
                messages are reviewed Monday to Friday only, excluding evenings
                and public holidays. If the matter is urgent please contact your
                local cinema.
              </p>
            )}
            <div className="col-md-12 mt-3">
              <div className="button_wrap text-center div">
                <button
                  type="button"
                  className="btn gray_btn back-btn mx-2"
                  onClick={onCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn black_btn mx-1"
                  onClick={() => handleSubmit()}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

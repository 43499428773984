import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../../../../store/ticketBooking/action";
import "./main.scss";
import { setUpModalHeaderText } from "../../../../../../store/foodAndBeverage/action";
import { TICKET_FLOW } from "../../../../../../constants/ticket";
import {
  MODAL_TYPE,
  TICKET_FLOW_TYPES,
} from "../../../../../../models/tickets";
import EatAndDrinkDetails from "../../../../../../components/TicketBooking/EatAndDrink";
import { RootState } from "../../../../../../store";
import { BRANDS, COUNTRY } from "../../../../../../constants/app";
import { useSearchParams } from "react-router-dom";
import { eatAndDrinkApi } from "../../../../../../services/films";
import { IMAGE_URL_CONFIG, URLS } from "../../../../../../constants/url";
import { IEatAndDrinkBannerImg } from "../../../../../../models/foodbeverage";
import Slick from "react-slick";
import moment from "moment";
import HelmetTitle from "../../../../../../components/Helmet";

interface IDirectFbProps {}

const DirectFb: FC<IDirectFbProps> = () => {
  const { countryId, currentCinema, brandId, canBookFood } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      brandId: state.applicationReducer.brandId,
      canBookFood: state.ticketBookingReducer.canBookFood,
    })
  );
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const [slides, setSlides] = useState<any[]>([]);
  const [openingHours, setOpeningHours] = useState([]);
  let settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getData = async () => {
    const { data } = await eatAndDrinkApi({
      cinemaId: currentCinema.slug,
      countryId: countryId,
    });

    if (Array.isArray(data) && data.length > 0) {
      const [datas] = data;
      setOpeningHours(datas && datas?.OpeningHours ? datas?.OpeningHours : []);
      setSlides(
        datas?.bannerImages?.map((v: IEatAndDrinkBannerImg) => ({
          imageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.imageId}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
        }))
      );
    }
  };

  useEffect(() => {
    getData();
  }, [countryId, currentCinema]);

  useEffect(() => {
    dispatch(
      setUpModalHeaderText(
        brandId === BRANDS.US ? "Eat & Drink" : "Food & Beverage"
      )
    );
  }, []);

  const openOrderModal = (data?: any) => {
    const day = moment().format("dddd").toLowerCase();
    let isKitchenOpened = false;
    if (openingHours && openingHours.length > 0) {
      const openHour: any = openingHours.find(
        (v: any) => v.Day.toLowerCase() === day && v.OpenStatus === "1"
      );
      const currentTime = moment()
        .utcOffset(currentCinema?.timeZone)
        .format("HH:mm:ss");
      if (
        openHour &&
        currentTime >=
          moment(openHour.OpenHour, "HH:mm:ss").format("HH:mm:ss") &&
        currentTime <= moment(openHour.CloseHour, "HH:mm:ss").format("HH:mm:ss")
      ) {
        isKitchenOpened = true;
      }
    }

    if (
      currentCinema.CloseKitchenCounter ||
      (brandId === BRANDS.AU && canBookFood === 0) ||
      (brandId === BRANDS.US && !isKitchenOpened)
    ) {
      dispatch(
        setModal({
          ...TICKET_FLOW[TICKET_FLOW_TYPES.KITCHEN_CLOSED][
            MODAL_TYPE.KITCHEN_CLOSED
          ],
          type: TICKET_FLOW_TYPES.KITCHEN_CLOSED,
          data: openingHours
        })
      );
      return;
    }

    dispatch(
      setUpModalHeaderText(
        brandId === BRANDS.US ? "Eat & Drink" : "Food & Drink"
      )
    );
    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE][
          MODAL_TYPE.FOOD_AND_BEVERAGE
        ],
        type: TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE,
        data: data,
      })
    );
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab && openingHours.length > 0) {
      openOrderModal({ tab });
    }
  }, [searchParams, openingHours]);

  const orderNow = () => {
    openOrderModal();
  };

  const addToFuture = () => {
    dispatch(setUpModalHeaderText("Add Food & Drink"));
    dispatch(
      setModal({
        ...TICKET_FLOW[TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE][
          MODAL_TYPE.FOOD_AND_BEVERAGE_FUTURE
        ],
        type: TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE,
      })
    );
  };

  return (
    <div className="food_header_wrap">
      <HelmetTitle title="Eat & Drink" description="Eat & Drink"/>
      <div className="col-12 pd-0 sub_title my-lg-4">
        {/* {slides.length > 0 ? (
          <div className="slider_filter">
            <Slick {...settings}>
              {slides.map((v) => (
                <div className="image-wrapper">
                  <img src={v.imageUrl} />
                </div>
              ))}
            </Slick>
          </div>
        ) : null} */}
        <div className="text-center d-md-flex justify-content-center">
          <div className="my-sm-4">
            <button
              type="button"
              className="btn black_btn mx-2 px-3"
              onClick={orderNow}
            >
              ORDER FOR NOW
            </button>
          </div>
          <div className="my-4 d-flex justify-content-center">
            <button
              type="button"
              className="btn gray_btn px-3 py-1 mx-2 d-flex align-items-center"
              onClick={addToFuture}
            >
              <span className="btn_plus_white">&nbsp;</span>{" "}
              <span className="mx-2">ADD TO EXISTING BOOKING</span>
            </button>
          </div>
        </div>
        {brandId === BRANDS.US ? (
          <div>
            <EatAndDrinkDetails />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DirectFb;

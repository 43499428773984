import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ratingIndexApi } from "../../../../services/sideMenus";
import "./resetpassword.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { IRatingIndex } from "../../../../models/sidemenu";
import TextInput from "../../../../components/UI/TextInput";
import { signInWatcher } from "../../../../store/auth/action";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { resetPasswordApi } from "../../../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { toggleContentModal } from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";

interface IResetPasswordProps {
  code: string;
  onCloseModal: () => void;
}

const ResetPassword: FC<IResetPasswordProps> = ({ code, onCloseModal }) => {
  const { countryId, data, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      data: state.ticketBookingReducer.modal.data,
      currentCinema: state.applicationReducer.currentCinema,
    })
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("forgot"),
      enableReinitialize: true,
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      onSubmit: async (values) => {
        const { password, confirmPassword } = values;

        const {
          data: { data },
        } = await resetPasswordApi({
          code: code,
          password,
        });

        if (data && data.status === "true") {
          toast.success("Password changed successfully");
          dispatch(toggleContentModal(false));
          navigate(`/${currentCinema?.alias}`);
        } else {
          setErrorMessage(data.errorMessage);
        }
      },
    });

  return (
    <div className="privacy-policy">
      <div className="modal-header">
        <div className="title title_append">RESET PASSWORD</div>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 is-invalid">
            <label className="form-label">New Password*</label>
            <div className="form-group pass_show">
              <TextInput
                type="password"
                value={values.password}
                field={"password"}
                handleChange={handleChange}
                touched={touched.password!}
                error={errors.password}
              />

              <span className="hint">Ex:Johnpeter@123</span>
            </div>
          </div>

          <div className="col-md-12 is-invalid">
            <label className="form-label">Confirm Password*</label>
            <div className="form-group pass_show">
              <TextInput
                type="password"
                value={values.confirmPassword}
                field={"confirmPassword"}
                handleChange={handleChange}
                touched={touched.confirmPassword!}
                error={errors.confirmPassword}
              />
            </div>
          </div>

          {errorMessage ? (
            <div className="err-message">{errorMessage}</div>
          ) : null}
          <div className="movie_button_wrap text-center-div d-flex justify-content-center">
            <button
              type="button"
              className="btn black_btn m-r-20"
              onClick={() => handleSubmit()}
            >
              SUBMIT
            </button>
            <button
              type="button"
              className="btn gray_btn back-btn"
              onClick={onCloseModal}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
